import axios from 'axios';


const serverUrl = process.env.REACT_APP_SERVER_URL;

export const GetCategoryImagesAPI = async (category_menu) => {
    const url = `${serverUrl}/api/category/get_category_list`;
    const payload = { category_menu }

    try {
        const GetCategoryImagesApiResponse = await axios.post(url, payload);
        return GetCategoryImagesApiResponse;
    } catch (error) {
        return error;
    }
};


export const getCategoryMenuData = async (category_menu_option_id) => {
    const url = `${serverUrl}/api/category/get_category_menu_fields`;
    const payload = { category_menu_option_id };
    
    try {
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error('Error fetching category menu data:', error);
        throw error;
    }
};


export const loginUserAPI = async (email, password, category_menu_option_id) => {
    const url = `${serverUrl}/api/login_panel/login_user`;
    const payload = { email, password, category_menu_option_id };

    try {
        const loginResponse = await axios.post(url, payload);
        return loginResponse;
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};


export const registerUserAPI = async (formData) => {
    const url = `${serverUrl}/api/login_panel/register_user`;
    const payload = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        password: formData.password,
        phone_number: formData.phoneNumber,
        building_number: formData.flat,
        street: formData.street,
        zip_code: formData.zipCode,
        city: formData.city,
        state: formData.state,
        company_name: formData.companyName
    };

    try {
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error('Registration error:', error);
        throw error;
    }
};


export const getHeaderMenusAPI =  async () => {
    const url = `${serverUrl}/api/admin_panel/get_header_menus`
    const response = await axios.get(url);
    return response.data;
}


export const getCategorySubMenusAPI =  async (header_menu_id) => {
    const url = `${serverUrl}/api/admin_panel/get_header_menus`
    const payload = { header_menu_id };
    try {
        const categorySubMenusAPIResponse = await axios.post(url, payload);
        return categorySubMenusAPIResponse;
    } catch (error) {
        return error;
    }
}


export const updateCategorySubmenuPrice = async (category_submenu_id, category_submenu_price, category_option) => {
    const url = `${serverUrl}/api/admin_panel/update_category_submenu_price`
    const payload = { category_submenu_id, category_submenu_price, category_option };
    try {
        const updateCategorySubmenuPriceAPIResponse = await axios.put(url, payload);
        return updateCategorySubmenuPriceAPIResponse;
    } catch (error) {
        return error;
    }
}


export const getSubmenuFields = async (submenu_field_id) => {
    const url = `${serverUrl}/api/admin_panel/get_submenu_fields`
    const payload = { submenu_field_id };
    try {
        const updateCategorySubmenuPriceAPIResponse = await axios.post(url, payload);
        return updateCategorySubmenuPriceAPIResponse;
    } catch (error) {
        return error;
    }
}

