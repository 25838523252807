import bookmark3PDF from "../constant/bookmark3PDF";
import bookmark4PDF from "../constant/bookmark4PDF";
import bookmarkPDF from "../constant/bookmark1PDF";
import flyer2PDF from "../constant/flyer2PDF";
import flyer3PDF from "../constant/flyer3PDF";
import flyerPDF from "../constant/flyer1PDF";
import flyer4PDF from "../constant/flyer4PDF";
import flyer5PDF from "../constant/flyer5PDF";
import flyer6PDF from "../constant/flyer6PDF";

export const fontList = [
  "Arial",
  "Helvetica",
  "Times New Roman",
  "Times",
  "Courier New",
  "Courier",
  "Verdana",
  "Georgia",
  "Palatino",
  "Garamond",
  "Bookman",
  "Comic Sans MS",
  "Trebuchet MS",
  "Arial Black",
  "Impact"
];

export const generateRandom4DigitNumber = () => {
  return Math.floor(Math.random() * 9000) + 1000;
}

export const CONNECTION_REFUSED = "connection refused";

export const formatTimestamp = (timestamp) => {
  let date = timestamp.split('T')[0];
  let time = timestamp.split('T')[1].split('.')[0];
  return `${date} ${time}`;
};

export const getPdfPage = (index, JSX, isPreview = false) => {
  if (!JSX) return '';

  const tempContainer = document.createElement('div');
  // tempContainer.innerHTML = JSX;
  tempContainer.innerHTML = bookmarkPDF;

  const selectedPage = tempContainer.querySelector(`#pf${index}`);

  if (!selectedPage) {
    return '';
  }

  const styleElement = tempContainer.querySelector('style');

  const dummyImg = tempContainer.querySelectorAll('.dummyimg');

  if (dummyImg && dummyImg?.length) {
    dummyImg.forEach(element => element.remove());
  }

  if (styleElement) {
    const uniqueStyleId = 'pdf-page-styles';
    const existingStyle = document.getElementById(uniqueStyleId);

    if (existingStyle) {
      existingStyle.remove();
    }

    const clonedStyle = styleElement.cloneNode(true);
    clonedStyle.id = uniqueStyleId;

    document.head.appendChild(clonedStyle);
  }

  Object.assign(selectedPage.style, {
    overflow: 'hidden',
    userSelect: 'none',
    webkitUserSelect: 'none',
    mozUserSelect: 'none',
    msUserSelect: 'none',
    color: 'rgba(0,0,0,0.8)'
  });

  return selectedPage.outerHTML;
};
