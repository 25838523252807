import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import About from '../pages/About'
import Contact from '../pages/Contact'
import Login from '../pages/Login'
import Cart from '../pages/Cart'
import ShopDetails from '../pages/ShopDetails'
import DigitOffset from '../pages/Services/DigitOffset'
import DocumentScan from '../pages/Services/DocumentScan'
import Photography from '../pages/Services/Photography'
import PhotoRestoration from '../pages/Services/PhotoRestoration'
import ChurchDirectory from '../pages/Services/ChurchDirectory'
import Signs from '../pages/Services/Signs'
import WebDesigning from '../pages/Services/WebDesigning'
import Flyers from '../pages/Services/Flyers'
import EventJournal from '../pages/Services/EventJournal'
import FAQ from '../pages/FAQ'
import MemorialFuneral from '../pages/MemorialFuneral'
import Register from '../pages/Register'
import AdminLogin from '../pages/Admin/AdminLogin'
import Dashboard from '../pages/Admin/Dashboard'
import CategoryPricing from '../pages/Admin/CategoryPricing'
import FieldPricing from '../pages/Admin/FieldPricing'
import PaymentPage from '../pages/PaymentPage'
import PDFUploadForm from '../pages/PDFUploadForm'
import AdminData from '../pages/Admin/AdminData'
import UsersData from '../pages/Admin/UsersData'
import Transaction from '../pages/Admin/Transaction'
import Subscribers from '../pages/Admin/Subscribers'
import Orders from '../pages/Orders'
import CurrentOrders from '../pages/Admin/CurrentOrders'
import { getHeadersAndCategory } from '../serverApi/server'
import DispatchedOrders from '../pages/Admin/DispatchedOrders'
import ProductDescription from '../pages/Admin/ProductDescription'
import Main from '../pages/Main'
import HeaderDescription from '../pages/Admin/HeaderDescription'
import SvgPngUpload from '../pages/Admin/SvgPngUpload'
import CategoryImageUpload from '../pages/Admin/CategoryImageUpload'

function RoutesConfig () {
  
  const [headers, setHeaders] = useState([]);
  
  const fetchHeader = async () => {
    try {
      const getHeaderCategory = await getHeadersAndCategory();
      setHeaders(getHeaderCategory.all_header_menus_details);
    } catch (error) {
      console.error("Error Fetching Header:", error);
    }
  };
  
  useEffect(() => {
    fetchHeader();
  }, [])

  const headerRoute = headers?.flatMap((header) => header.categories || []) || [];
  
  return (
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/MemorialFuneral' element={<MemorialFuneral />} />
      <Route path='/shopdetails/:id?' element={<ShopDetails/>} />
      <Route path='/about' element={<About/>} />
      <Route path='/contact' element={<Contact/>} />
      <Route path='/login' element={<Login/>} />
      <Route path='/register' element={<Register/>} />
      <Route path='/cart' element={<Cart/>} />
      <Route path='/orders' element={<Orders/>} />
      <Route path='/digital_&_offset_printing' element={<DigitOffset/>} />
      <Route path='/document_scanning' element={<DocumentScan/>} />
      <Route path='/photography' element={<Photography/>} />
      <Route path='/photo_restoration' element={<PhotoRestoration/>} />
      <Route path='/church_directory' element={<ChurchDirectory/>} />
      <Route path='/signs' element={<Signs/>} />
      <Route path='/website_designing' element={<WebDesigning/>} />
      <Route path='/flyers' element={<Flyers/>} />
      <Route path='/event_journal' element={<EventJournal/>} />
      <Route path='/faq' element={<FAQ/>} />
      <Route path='/design' element={<Main/>} />
      <Route path='/payment-page' element={<PaymentPage/>} />
      <Route path='/pdf-upload-form' element={<PDFUploadForm/>} />
      <Route path='/template' element={<Main/>} />

      {headerRoute.map(item => (
        <Route
          key={item.id}
          path={item.additional_name}
          element={<MemorialFuneral title={item.category} additionalName={item.additional_name} />}
        />
      ))}

      {/* Admin Page Routing */}
      <Route path='/admin' element={<AdminLogin />} />
      <Route path='/admin/dashboard' element={<Dashboard />} />
      <Route path='/admin/sub-category-pricing' element={<CategoryPricing />} />
      <Route path='/admin/field-pricing' element={<FieldPricing />} />
      <Route path='/admin/admin_data' element={<AdminData />} />
      <Route path='/admin/users_data' element={<UsersData />} />
      <Route path='/admin/transaction' element={<Transaction />} />
      <Route path='/admin/subscribers' element={<Subscribers />} />
      <Route path='/admin/current_orders' element={<CurrentOrders />} />
      <Route path='/admin/dispatched_orders' element={<DispatchedOrders />} />
      <Route path='/admin/productdescription' element={<ProductDescription />} />
      <Route path='/admin/headerdescription' element={<HeaderDescription />} />
      <Route path='/admin/upload_designassets' element={<SvgPngUpload />} />
      <Route path='/admin/category_image_upload' element={<CategoryImageUpload />} />
    </Routes>
  )
}

export default RoutesConfig
