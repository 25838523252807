import React, { useState } from 'react';
import Frame from "../assets/CustomizeAsset/frame.webp";
import Element from "../assets/CustomizeAsset/editor.webp";
import Background from "../assets/CustomizeAsset/background.webp";

export default function Elements({ myImages, setAddElement }) {
    const [isOpen, setIsOpen] = useState('frames');

    const toggleAccordion = (section) => {
        setIsOpen((prevSection) => (prevSection === section ? '' : section));
    };

    return (
        <div className="w-full h-full space-y-4 overflow-y-auto">
            <div className="w-full border rounded-lg shadow-lg overflow-hidden">
                <button
                    className="w-full text-left py-[10px] px-3 font-semibold text-gray-800 flex justify-between items-center hover:bg-blue-100 transition-all duration-200 ease-in-out sticky top-0 bg-white z-10"
                    onClick={() => toggleAccordion('frames')}
                >
                    <span className="text-md text-[rgba(0,0,0,0.7)]">Frames</span>
                    <span className="text-[rgba(0,0,0,0.7)]">
                        <img src={Frame} alt="frame-icon" className='w-[30px]' />
                    </span>
                </button>
                {isOpen === 'frames' && (
                    <div className="p-1 bg-blue-50 text-gray-700 transition-all duration-300 ease-in-out grid grid-cols-2 gap-2">
                        <div className="relative bg-white p-2 shadow rounded cursor-pointer">
                            <div className='w-32 h-32' onClick={() => setAddElement('frame', 'box-default')}>
                                <img src={Background} alt="Frame 1" className="object-cover w-full h-full" loading='eager' />
                            </div>
                        </div>
                        <div className="relative bg-white p-2 shadow rounded cursor-pointer">
                            <div className='w-32 h-32' onClick={() => setAddElement('frame', 'box-corner')}>
                                <img src={Background} alt="Frame 2" className="object-cover w-full h-full rounded-md" loading='eager' />
                            </div>
                        </div>
                        <div className="relative bg-white p-2 shadow rounded cursor-pointer">
                            <div className='w-32 h-32' style={{ clipPath: 'circle(50% at 50% 50%)' }} onClick={() => setAddElement('frame', 'circle(50% at 50% 50%)')}>
                                <img src={Background} alt="Frame 1" className="object-cover w-full h-full rounded-md" loading='eager' />
                            </div>
                        </div>
                        <div className="relative bg-white p-2 shadow rounded cursor-pointer">
                            <div className='w-32 h-32' style={{ clipPath: 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)' }} onClick={() => setAddElement('frame', 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)')}>
                                <img src={Background} alt="Frame 1" className="object-cover w-full h-full rounded-md" loading='eager' />
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Elements Accordion */}
            <div className="w-full border rounded-lg shadow-lg overflow-hidden">
                <button
                    className="w-full text-left py-[13px] px-3 font-semibold text-gray-800 flex justify-between items-center hover:bg-green-100 transition-all duration-200 ease-in-out sticky top-0 bg-white z-10"
                    onClick={() => toggleAccordion('elements')}
                >
                    <span className="text-md text-[rgba(0,0,0,0.7)]">Elements</span>
                    <span className="text-[rgba(0,0,0,0.7)]">
                        <img src={Element} alt="elements" className='w-[30px] h-[20px]' />
                    </span>
                </button>
                {isOpen === 'elements' && (
                    <div className="p-1 bg-[rgba(0,0,0,0.05)] text-gray-700 transition-all duration-300 ease-in-out grid grid-cols-2 gap-1">
                        {myImages.map((img, i) => {
                            const isSvg = img.file_base64.startsWith('PD94bWw');
                            return (
                                <div key={i} className="bg-white p-2 shadow rounded cursor-pointer">
                                    {isSvg ? (
                                        <div
                                            className="w-32 h-32 object-cover rounded flex justify-center items-center"
                                            dangerouslySetInnerHTML={{
                                                __html: atob(img.file_base64),
                                            }}
                                            onClick={() => setAddElement('svg', img.file_base64)}
                                        ></div>
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
