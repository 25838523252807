import React, { useState } from 'react'
import { CgProfile } from 'react-icons/cg';
import { FaRegStar, FaStar } from 'react-icons/fa';
import { FaRegStarHalfStroke } from 'react-icons/fa6';

const DescriReview = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  return (
    
    <div className="px-5 xl:px-20">
      <div>

        <div className="flex justify-center border-b mb-4">
          <button
            className={`hover:text-orange-400 duration-200 py-2 px-4 border-t border-l border-r ${activeTab === "tab1" ? "text-orange-400 border-neutral-200" : "border-transparent"}`}
            onClick={() => setActiveTab("tab1")}
          >
            Description
          </button>
          <button
            className={`hover:text-orange-400 duration-200 py-2 px-4 border-t border-l border-r ${activeTab === "tab2" ? "text-orange-400 border-neutral-200" : "border-transparent"}`}
            onClick={() => setActiveTab("tab2")}
          >
            Information
          </button>
          <button
            className={`hover:text-orange-400 duration-200 py-2 px-4 border-t border-l border-r ${activeTab === "tab3" ? "text-orange-400 border-neutral-200" : "border-transparent"}`}
            onClick={() => setActiveTab("tab3")}
          >
            Reviews (0)
          </button>
        </div>

        <div>
          {activeTab === "tab1" && (
            <div className='text-neutral-500'>
              <h4 className="text-2xl mb-3 text-black">Product Description</h4>
              <p>Honor and remember your loved ones with our custom funeral buttons. Designed to celebrate the life and memory of those who have passed, these buttons can be personalized with a photo, name, and dates to create a lasting tribute. Made from durable materials, they are a perfect keepsake for family and friends attending memorial services. Our funeral buttons come in various sizes and styles, offering options that suit your needs for both formal ceremonies and personal remembrances. Commemorate the life of a loved one with a thoughtful and meaningful tribute</p>
              <ul className='list-disc px-8 pt-3'>
                <li><span className='font-semibold'>Funeral Services:</span> Distribute these buttons at memorial services to honor the life of the deceased and give family and friends a token of remembrance.</li>
                <li><span className='font-semibold'>Memorial Keepsakes:</span> A wonderful keepsake for those who want a tangible reminder of their loved one.</li>
                <li><span className='font-semibold'>Family & Friends:</span> Custom buttons can help family members feel connected during the grieving process, and can be worn at funerals, wakes, or gatherings.</li>
                <li><span className='font-semibold'>Community Events:</span> In addition to funeral services, these buttons can be used in community memorials or tributes to someone who has passed.</li>
                <li><span className='font-semibold'>Funeral Services:</span> Distribute these buttons at memorial services to honor the life of the deceased and give family and friends a token of remembrance.</li>
                <li><span className='font-semibold'>Memorial Keepsakes:</span> A wonderful keepsake for those who want a tangible reminder of their loved one.</li>
                <li><span className='font-semibold'>Family & Friends:</span> Custom buttons can help family members feel connected during the grieving process, and can be worn at funerals, wakes, or gatherings.</li>
                <li><span className='font-semibold'>Community Events:</span> In addition to funeral services, these buttons can be used in community memorials or tributes to someone who has passed.</li>
                <li><span className='font-semibold'>High-Quality Printing:</span> Our funeral buttons are printed with high-quality, full-color digital printing, ensuring clear and vibrant images that capture the essence of your loved one.</li>
                <li><span className='font-semibold'>Personalized Design:</span> Customize your button with a photo, text, and dates of birth and death. You can even include a quote, symbol, or graphic that had special meaning to the deceased.</li>
                <li><span className='font-semibold'>Durable Construction:</span> Made with sturdy materials, these buttons are designed to withstand wear while remaining a lasting memento.</li>
                <li><span className='font-semibold'>Multiple Size Options:</span> Available in various sizes ranging from 1.25" to 3" in diameter, allowing you to choose the button that fits your preferences and needs.</li>
                <li><span className='font-semibold'>Pin or Magnet Options:</span> Choose between a traditional pin back or a magnet back, allowing family members to wear them or keep them as keepsakes.</li>
                <li><span className='font-semibold'>Bulk Orders:</span> We understand that large families and groups of friends may wish to have buttons to remember the deceased, so we offer bulk ordering options.</li>
                <li><span className='font-semibold'>Affordable Pricing:</span> Funeral buttons are an affordable way to create meaningful mementos for a group without sacrificing quality.</li>
              </ul>
            </div>
          )}

          {activeTab === "tab2" && (
            <div className="text-neutral-500">
              <h4 className="text-2xl mb-3 text-black">Additional Information</h4>
              <ul className="list-none md:text-lg">
                <li className="mb-2"><span className='font-semibold'>Durable Material:</span> Our bookmarks are made from high-quality cardstock, laminated PVC, or premium wood, ensuring longevity and resistance to wear and tear.</li><hr className='pb-2' />
                <li className="mb-2"><span className='font-semibold'>Customization Options:</span> You can personalize your bookmarks with unique designs, photos, logos, or text. Full-color printing is available for vibrant, detailed images.</li><hr className='pb-2' />
                <li className="mb-2"><span className='font-semibold'>Versatile Uses:</span> Perfect for giveaways, educational tools, events (weddings, corporate gifts, book clubs), and as a branding tool.</li><hr className='pb-2' />
                <li className="mb-2"><span className='font-semibold'>Size Options:</span> Available in a variety of sizes, including traditional and custom shapes such as rectangular, square, or even custom die-cut designs.</li><hr className='pb-2' />
                <li className="mb-2"><span className='font-semibold'>Eco-Friendly Options:</span> We offer eco-conscious materials like recycled paper or biodegradable plastic, allowing you to choose sustainable options.</li><hr className='pb-2' />
                <li className="mb-2"><span className='font-semibold'>Bulk Orders:</span> Whether you need just a few for personal use or hundreds for an event, we offer both small and large order quantities to suit your needs.</li><hr className='pb-2' />
              </ul>
            </div>
          )}

          {activeTab === "tab3" && (
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-neutral-500">
                <div>
                  <h4 className="text-black text-2xl mb-4">1 review for "Colorful Stylish Shirt"</h4>
                  <div className="flex mb-4">
                      <CgProfile className='w-12 h-12 rounded-full mr-3'/>
                    <div>
                      <h6>
                        John Doe
                        <small> - <i>01 Jan 2045</i></small>
                      </h6>
                      <div className="flex items-center text-orange-400 mb-2">
                        <FaStar /> <FaStar /> <FaStar /> <FaRegStarHalfStroke /> <FaRegStar />
                      </div>
                      <p>
                        Diam amet duo labore stet elitr ea clita ipsum, tempor
                        labore accusam ipsum et no at.
                      </p>
                    </div>
                  </div>
                </div>

                <div className='text-neutral-500'>
                  <h4 className="text-2xl text-black mb-4">Leave a review</h4>
                  <small>
                    Your email address will not be published. Required fields
                    are marked *
                  </small>
                  <div className="flex my-3">
                    <p className="mr-2">Your Rating * :</p>
                    <div className="flex items-center text-orange-500">
                      <FaRegStar />
                      <FaRegStar />
                      <FaRegStar />
                      <FaRegStar />
                      <FaRegStar />
                    </div>
                  </div>
                  <form>
                    <div className="flex items-center justify-center mb-3">
                      <label htmlFor="message" className='whitespace-nowrap w-36'>Your Review *</label>
                      <textarea
                        id="message"
                        className="w-full p-2 border border-gray-300 rounded-md"
                        rows="5"
                      ></textarea>
                    </div>
                    <div className="flex items-center justify-center mb-3">
                      <label htmlFor="name" className='whitespace-nowrap w-36'>Your Name *</label>
                      <input
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded-md"
                        id="name"
                      />
                    </div>
                    <div className="flex items-center justify-center mb-3">
                      <label htmlFor="email" className='whitespace-nowrap w-36'>Your Email *</label>
                      <input
                        type="email"
                        className="w-full p-2 border border-gray-300 rounded-md"
                        id="email"
                      />
                    </div>
                    <div className="flex items-center mb-0">
                      <button
                        type="submit"
                        className="bg-orange-300 text-black px-5 py-2 duration-200 hover:bg-orange-400"
                      >
                        Leave Your Review
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>

      </div>
    </div>
  )
}

export default DescriReview
