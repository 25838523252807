import React, { useState, useEffect } from 'react';
import { UploadImageAPI, GetImages } from '../serverApi/server';
import { CONNECTION_REFUSED } from '../helper/Helpers';
import SuccessPopup from '../components/SuccessPopupMessage';
import RefusedPopup from '../components/RefusedPopupMessage';
import { FaCloudUploadAlt } from "react-icons/fa";
import { AiOutlineClose } from 'react-icons/ai';
import { cardPlaceHolder } from '../helper/PlaceHolder';
import _ from 'lodash';

export default function MyImages({ setAddElement, handleAlert }) {
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopupRefused, setShowPopupRefused] = useState(false);
    const [popupRefusedMessage, setPopupRefusedMessage] = useState('');
    const [myImages, setMyImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        source: '',
        name: '',
        size: ''
    });
    const [tags, setTags] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const fetchImages = async () => {
        try {
            const getImagesApiResponse = await GetImages('upload', localStorage.getItem('userId'));
            if (getImagesApiResponse.status === 200) {
                setMyImages(getImagesApiResponse.data.design_assets);
            } else {
                setPopupMessage(getImagesApiResponse.response.data.message);
                setShowPopup(true);
                setTimeout(() => setShowPopup(false), 2000);
            }
            setIsLoaded(true);
        } catch (error) {
            setPopupRefusedMessage(CONNECTION_REFUSED);
            setShowPopupRefused(true);
            setTimeout(() => setShowPopupRefused(false), 2000);
        }
    };

    useEffect(() => {
        fetchImages();
    }, []);

    const handleImageUpload = async (e) => {
        const files = e.target.files;
        if (files.length) {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('upload_files', files[i]);
            }
            formData.append('user_id', localStorage.getItem('userId'));
            formData.append('asset_type', 'upload');
            setFormData({
                source: formData,
                name: formData.getAll('upload_files')[0].name,
                size: formData.getAll('upload_files')[0].size
            });
        }
    };

    const handleAddTag = (e) => {
        if (e.key === 'Enter' && e.target.value.trim()) {
            setTags([...tags, e.target.value.trim()]);
            e.target.value = '';
        }
    };

    const handleRemoveTag = (indexToRemove) => {
        setTags(tags.filter((_, index) => index !== indexToRemove));
    };

    const uploadImage = async () => {
        try {
            const formPayload = formData.source;
            formPayload.append('fileName', `${formData.name}`);
            const totalSize = (Array.from(formPayload.getAll('upload_files')).reduce((acc, file) => acc + file.size, 0) / (1024 * 1024)).toFixed(2);
            formPayload.append('size', `${totalSize}MB`);
            formPayload.append('tags', JSON.stringify(tags));
            const uploadImageFileResponse = await UploadImageAPI(formPayload);
            if (uploadImageFileResponse.status === 200) {
                fetchImages();
                handleAlert({ alertStatus: true, message: 'Image uploaded successfully!', apiStatus: 'success' });
                setFormData({});
            } else {
                setPopupMessage(uploadImageFileResponse.response.data.message);
                setShowPopup(true);
                setTimeout(() => setShowPopup(false), 2000);
            }
        } catch (error) {
            setPopupRefusedMessage(CONNECTION_REFUSED);
            setShowPopupRefused(true);
            setTimeout(() => setShowPopupRefused(false), 2000);
        }
    }

    const deleteImage = () => {
        try {
            handleAlert({ alertStatus: true, message: 'Image deleted successfully!', apiStatus: 'success' });
        } catch (error) {
            setPopupRefusedMessage(CONNECTION_REFUSED);
            setShowPopupRefused(true);
            setTimeout(() => setShowPopupRefused(false), 2000);
        }
    }

    return (
        <div className='w-full'>
            <div className='w-full h-[45px] flex justify-center items-center bg-[#7731d8] rounded-md hover:bg-[#612dae] text-white mb-3'>
                <label className='text-[15px] font-[500] cursor-pointer w-full h-full flex justify-center items-center gap-2 text-white mt-2' htmlFor="fileUpload" onClick={() => setIsModalOpen(true)}>
                    <FaCloudUploadAlt className='text-[19px] font-[500]' /> upload image
                </label>
            </div>

            {isModalOpen && (
                <div className="fixed top-[6%] left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-scroll">
                    <div className="relative w-[90%] h-[85%] bg-white rounded-md shadow-lg p-4 overflow-y-scroll">
                        <div className='flex justify-between border-b-[1.5px] border-solid py-1'>
                            <span className='text-[rgba(0,0,0,0.8)] font-[700] text-[18px]'>Browse and Upload Images</span>
                            <button
                                className="text-gray-500 hover:text-gray-800"
                                onClick={() => { setIsModalOpen(false); setFormData({}); }}
                            >
                                <AiOutlineClose size={24} />
                            </button>
                        </div>
                        <div className='px-0 pt-4'>
                            <span
                                className="h-auto p-3 text-[14px] bg-gradient-to-r from-[#00c4cc] to-[#7d2ae8] rounded-md text-white cursor-pointer transition-all duration-300 hover:from-[#7d2ae8] hover:to-[#00c4cc]"
                                onClick={() => document.getElementById('customFileInput').click()}
                            >
                                Upload Images
                                <input
                                    id="customFileInput"
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    className="hidden"
                                    onChange={handleImageUpload}
                                />
                            </span>

                            {formData && formData.source && formData.size && (
                                <div className="overflow-x-auto mt-4">
                                    <div className="overflow-x-auto">
                                        <table className="min-w-full border-collapse border border-gray-300">
                                            <thead>
                                                <tr className="bg-gray-100 text-gray-700">
                                                    <th className="border border-gray-300 px-4 py-2 text-left w-[200px]">File Name</th>
                                                    <th className="border border-gray-300 px-4 py-2 text-left w-[100px]">Size</th>
                                                    <th className="border border-gray-300 px-4 py-2 text-left w-[300px]">Tags</th>
                                                    <th className="border border-gray-300 px-4 py-2 text-left w-[150px]">Upload</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="hover:bg-gray-50">
                                                    <td className="border border-gray-300 px-4 py-2 w-[200px]">
                                                        <input
                                                            type="text"
                                                            defaultValue={formData.name}
                                                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                                            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                                                        />
                                                    </td>

                                                    <td className="border border-gray-300 px-4 py-2 w-[100px]">{(Array.from(formData.source.getAll('upload_files')).reduce((acc, file) => acc + file.size, 0) / (1024 * 1024)).toFixed(2)} MB</td>
                                                    <td className="border border-gray-300 px-4 py-2 w-[300px]">
                                                        <div className="flex flex-wrap items-center gap-2">
                                                            {tags.map((tag, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="flex items-center bg-gray-200 text-[rgba(0,0,0,0.6)] text-[15px] px-2 py-[3px] rounded-sm"
                                                                >
                                                                    {tag}
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => handleRemoveTag(index)}
                                                                        className="ml-2 text-[#4c66dd] text-[18px] mt-1"
                                                                    >
                                                                        &times;
                                                                    </button>
                                                                </div>
                                                            ))}
                                                            <input
                                                                type="text"
                                                                placeholder="Add tags..."
                                                                className="flex-1 p-2 border rounded-md focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                                                                onKeyDown={handleAddTag}
                                                            />
                                                        </div>
                                                    </td>

                                                    <td className="border border-gray-300 px-4 py-2 w-[150px]">
                                                        <button
                                                            className="w-full bg-[#4c66dd] text-white py-2 rounded-md hover:bg-indigo-500 transition duration-300"
                                                            onClick={() => uploadImage()}
                                                        >
                                                            Upload
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                            {myImages?.length ? (
                                <>
                                    <div className='flex justify-between border-b-[1.5px] border-solid py-1 mt-4'>
                                        <span className='text-[rgba(0,0,0,0.8)] font-[700] text-[18px]'>Media Library</span>
                                    </div>
                                    <div className="overflow-x-auto mt-4">
                                        <div className="overflow-x-auto">
                                            <table className="min-w-full border-collapse border border-gray-300">
                                                <thead>
                                                    <tr className="bg-gray-100 text-gray-700">
                                                        <th className="border border-gray-300 px-4 py-2 text-left w-[200px]">S.No</th>
                                                        <th className="border border-gray-300 px-4 py-2 text-left w-[200px]">Preview</th>
                                                        <th className="border border-gray-300 px-4 py-2 text-left w-[200px]">File Name</th>
                                                        <th className="border border-gray-300 px-4 py-2 text-left w-[100px]">Size</th>
                                                        <th className="border border-gray-300 px-4 py-2 text-left w-[300px]">Tags</th>
                                                        <th className="border border-gray-300 px-4 py-2 text-left w-[150px]">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {_.map(myImages, (item, index) => {
                                                        return (
                                                            <tr className="hover:bg-gray-50">
                                                                <td className="border border-gray-300 px-4 py-2 w-[200px]">{index + 1}</td>
                                                                <td className="border border-gray-300 px-4 py-2 w-[200px]">
                                                                    <img
                                                                        key={index}
                                                                        className='w-[90%] h-[90px] object-contain rounded-md cursor-pointer bg-white p-2'
                                                                        src={`data:image/png;base64,${item.file_base64}`}
                                                                        alt={item.name}
                                                                    />
                                                                </td>
                                                                <td className="border border-gray-300 px-4 py-2 w-[200px]">
                                                                    <input
                                                                        type="text"
                                                                        defaultValue={item?.name}
                                                                        className="w-full p-2 border rounded-md  focus:outline-none"
                                                                        readOnly
                                                                    />
                                                                </td>

                                                                <td className="border border-gray-300 px-4 py-2 w-[100px]">{item?.size}</td>
                                                                <td className="border border-gray-300 px-4 py-2 w-[300px]">
                                                                    <div className="flex flex-wrap items-center gap-2">
                                                                        {JSON.parse(item?.tags)?.length && JSON.parse(item.tags).map((tag, index) => (
                                                                            <div
                                                                                key={index}
                                                                                className="flex items-center bg-gray-200 text-[rgba(0,0,0,0.6)] text-[15px] px-2 py-[3px] rounded-sm"
                                                                            >
                                                                                {tag}
                                                                                <button
                                                                                    type="button"
                                                                                    className="ml-2 text-[#4c66dd] text-[18px] mt-1"
                                                                                    disabled
                                                                                >
                                                                                    &times;
                                                                                </button>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </td>

                                                                <td className="border border-gray-300 px-4 py-2 w-[150px]">
                                                                    <button
                                                                        className="w-full bg-red-500 text-white py-2 rounded-md hover:bg-red-600 transition duration-300"
                                                                        onClick={() => deleteImage()}
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            ) : <></>}
                        </div>
                    </div>
                </div>
            )}

            <div className='w-full'>
                <div
                    className='w-full h-auto flex gap-x-1 gap-y-4 flex-wrap justify-between bg-[rgba(0,0,0,0.05)] p-1 rounded-md'
                >
                    {myImages.map((img, i) => {
                        return (
                            <img
                                key={i}
                                className='w-[48%] h-[130px] object-cover rounded-md cursor-pointer bg-white p-2'
                                src={`data:image/png;base64,${img.file_base64}`}
                                onClick={() => setAddElement('image', `data:image/png;base64,${img.file_base64}`)}
                                alt={img.name}
                            />
                        );
                    })}
                    {!isLoaded && (
                        <div className="bg-white w-full p-2 shadow rounded cursor-pointer">
                            {cardPlaceHolder(true)}
                        </div>
                    )}
                </div>
            </div>

            {showPopup && (
                <SuccessPopup popupMessage={popupMessage} />
            )}
            {showPopupRefused && (
                <RefusedPopup popupRefusedMessage={popupRefusedMessage} />
            )}
        </div>
    );
}
