import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../assets/Stalbanslogo.png'
import { FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa'
import { IoIosMail } from 'react-icons/io'
import { HiPrinter } from 'react-icons/hi'
import Skeleton from '../../pages/skeleton/Skeleton'

const ContactDetails = () => {
  const naviagte = useNavigate();
  const homePage = () => {
    naviagte('/');
  }

  /* const [loadingTime, setLoadingTime] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingTime(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []) */

  return (
    <div className='sm:mb-5 text-gray-700'>
        <div onClick={homePage} className='w-32 sm:w-36 flex'>
          {/* {loadingTime ? (
            <div className='w-16 sm:w-20 h-10'>
              <Skeleton width='100%' height='100%' marginBottom='10px'/>
            </div>
          ) : (
            <img
              src={logo}
              alt="brand-logo"
              className="w-16 sm:w-20 mb-5 cursor-pointer"
            />
          )} */}
          <img
              src={logo}
              alt="brand-logo"
              className="w-16 sm:w-20 mb-5 cursor-pointer"
            />
        </div>
        <p className='mb-2 w-full md:w-1/2 lg:w-full'>
        St. Albans Digital Printing Inc. – Your trusted designers and printers for memorial collages, funeral buttons, T-shirts, bookmarks, and more.
        </p>

        <div className='mb-2 flex items-center gap-4'> 
          <FaMapMarkerAlt className='text-black'/> 
          <p>St. Albans Digital Printing Inc. 
          <br/>199-12 Linden Blvd.<br/>
          St. Albans, NY 11412
          </p> 
        </div>

        <div className='mb-2 flex items-center gap-4'> 
          <IoIosMail className='text-black'/>
          <p>orders@stalbansprinting.com</p> 
        </div>

        <div className='mb-2 flex items-center gap-4'> 
          <FaPhoneAlt className='text-black'/>
          <p>718-528-5100,  718-528-6363</p> 
        </div>

        <div className='flex items-center gap-4'  >
          <HiPrinter className='text-black' />
          <p>718-528-5151</p>
        </div>
    </div>
  )
}

export default ContactDetails
