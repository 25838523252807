import React, { useState } from "react";
import tickImg from "../assets/paymentSuccess.jpg";
import { IoMdClose } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { uploadFormImagesDetails } from "../serverApi/server";

const PDFUploadForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { userId, orderId, paymentId } = location.state || {};
  const [multiFile, setMultiFile] = useState(false);
  const [previewMulti, setPreviewMulti] = useState(null);
  const [name, setName] = useState("");
  const [thoughts, setThoughts] = useState("");
  const [files, setFiles] = useState([]);
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleThoughtsChange = (e) => {
    setThoughts(e.target.value);
  };
  const handleFileChangeMulti = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    setFiles(uploadedFiles);
    if (uploadedFiles.length) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewMulti(reader.result);
      };
      reader.readAsDataURL(uploadedFiles[0]);
    }
  };
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("thoughts", thoughts);
    formData.append("user_id", userId);
    formData.append("order_id", orderId);
    formData.append("payment_id", paymentId);
    files.forEach((file, index) => {
      formData.append(`file_${index}`, file);
    });
    const uploadFormImagesDetailsAPIResponse = await uploadFormImagesDetails(
      formData
    );
    setLoading(false);
    navigate("/");
  };

  return (
    <div className="bg-[#F9F9F9] flex items-center py-6 md:py-8 justify-center">
      <div className="w-11/12 sm:w-3/5 md:w-3/5 min-[840px]:w-1/2 xl:w-2/5 px-4 bg-white shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)] rounded-lg animate-slide-down">
        <div className="flex flex-col items-center gap-2 sm:gap-4 pt-4 pb-2 sm:py-4 border-b border-neutral-500">
          <div className="w-14 sm:w-16 md:w-20">
            <img src={tickImg} alt="tick-image" className="w-full" />
          </div>
          <h1 className="text-[#4CAF50] text-center text-lg sm:text-xl">
            Transaction Complete! Thank you for your purchase.
          </h1>
        </div>
        <div className="flex flex-col sm:items-center gap-3 md:gap-4 pt-2 pb-5 md:py-4 px-2 md:px-10">
          <h1 className="text-lg md:text-xl font-semibold text-center">
            Please Upload the images for your products
          </h1>
          <form
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            className="w-full rounded-md border flex flex-col gap-2 p-2 sm:p-4 bg-[#F9F9F9] shadow-[0px_0px_7px_1px_rgba(76,175,80,0.2)]"
          >
            <div className="flex flex-col gap-[6px]">
              <label htmlFor="name" className="font-medium">
                Name
              </label>
              <input
                type="text"
                placeholder="Enter your full name"
                className="px-3 py-[6px] border rounded focus:border-[#4CAF50] focus:outline-none focus:shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)] duration-200"
                value={name}
                onChange={handleNameChange}
                required
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label htmlFor="share-your-thoughts" className="font-medium">
                Share Your Thoughts
              </label>
              <textarea
                type="textarea"
                placeholder="Share your thoughts"
                required
                className="px-3 py-[6px] border rounded focus:border-[#4CAF50] focus:outline-none focus:shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)] duration-200"
                value={thoughts}
                onChange={handleThoughtsChange}
              />
            </div>
            <div>
              <label htmlFor="upload-file">Upload File*</label>
              <div className="flex justify-center items-center gap-10 mt-1 mb-4">
                <label htmlFor="single-file">
                  <input
                    type="radio"
                    id="single-file"
                    name="file_option"
                    value="singel-file"
                    required
                    onClick={() => setMultiFile(true)}
                  />{" "}
                  Single File
                </label>
                <label htmlFor="multi-file">
                  <input
                    type="radio"
                    id="multi-file"
                    name="file_option"
                    value="multi-file"
                    onClick={() => setMultiFile(true)}
                  />{" "}
                  Multiple Files
                </label>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <button
                type="submit"
                className="bg-[#57c25b] hover:bg-[#43A047] duration-200 px-2 py-1 rounded text-white inline-block"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      {multiFile && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center z-[150]">
          <div className="w-11/12 sm:w-3/5 md:w-1/2 lg:w-2/5 xl:w-2/5 relative bg-white rounded-lg overflow-hidden animate-slide-down">
            <button
              className="absolute top-2 right-2 p-2 hover:bg-neutral-200 rounded-full"
              onClick={() => setMultiFile(false)}
            >
              <IoMdClose />
            </button>
            <div className="py-6 px-4 flex flex-col gap-3">
              <h1 className="text-center font-semibold">Upload Your Artwork</h1>
              <div className="flex justify-center gap-2 w-full">
                <form onSubmit={() => setMultiFile(false)} className="space-y-4 border border-neutral-300 rounded-md p-2 flex flex-col items-center">
                  <label className="block text-gray-700 font-medium">
                    Image/PDF
                  </label>
                  <input
                    type="file"
                    multiple
                    required
                    onChange={handleFileChangeMulti}
                    className="w-full mt-2"
                  />
                  {previewMulti && (
                    <div className="mt-4">
                      <img
                        src={previewMulti}
                        alt="File Preview"
                        className="mt-2 w-40 h-40 object-cover border border-gray-300"
                      />
                    </div>
                  )}
                  <button
                    type="submit"
                    className="px-4 py-2 w-full bg-neutral-100 rounded hover:bg-neutral-200 duration-200"
                  >
                    Upload
                  </button>
                </form>
              </div>
              <p className="text-center">
                *Upload your files to each location above.
              </p>
            </div>
          </div>
        </div>
      )}

      <div>
        {loading && (
          <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-90 flex flex-col justify-center items-center z-50">
            <div className="w-12 h-12 border-4 border-gray-300 border-t-4 border-t-orange-500 rounded-full animate-spin"></div>
            <p className="mt-4 text-sm sm:text-base md:text-lg text-gray-600">
              Loading, please wait...
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PDFUploadForm;
