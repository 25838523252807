import React, { useEffect, useState } from 'react'
import SideBar from './SideBar'
import Header from './Header'
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { TiPlus } from "react-icons/ti";
import { HiMiniPencilSquare } from "react-icons/hi2";
import { deleteOption, userDataDetails } from '../../serverApi/server';

const UsersData = () => {
  const [userDataArray, setUserDataArray] = useState([]);

  const fetchUserData = async () => {
    try {
      const getUserData = await userDataDetails('user');
      setUserDataArray(getUserData.data.user_details_serializer);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchUserData();
  }, [])

  const delete_type = 'user';

  const handleUserDelete = async (user_id) => {
    try {
      const deleteUserResponse = await deleteOption(user_id, delete_type);
      if (deleteUserResponse.data.client_status_code === 200) {
        fetchUserData();
      }

    } catch (error) {
      console.error("Error deleting User", error);
    }
  }

  return (
    <div className='flex'>
        <SideBar />

      <div className='w-full h-screen'>
        <Header />
        <section className="px-5 pt-5 pb-16 bg-[#F5F7FD] h-screen">
          <div className="text-blue-950 flex items-center gap-6">
            <h1 className="pr-6 text-2xl font-medium border-r">
              Admin Users
            </h1>
            <div className="flex text-sm items-center gap-3">
              <button>
                <SlHome />
              </button>
              <SlArrowRight />
              <button>
                Users
              </button>
              <SlArrowRight />
              <button>
                Users Data
              </button>
            </div>
          </div>

          <section className='bg-white mt-10 py-4 rounded-lg shadow-[0px_0px_15px_0px_rgba(0,0,0,0.1)]'>

            <div className="overflow-x-auto sm:px-5 md:px-7 overflow-y-auto">
              <table className="min-w-full bg-white mt-4" style={{ borderCollapse: 'separate', borderSpacing: '2px' }}>
                <thead>
                  <tr>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">FIRST NAME</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">LAST NAME</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">EMAIL</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">PHONE NUMBER</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">FULL <br />ADDRESS</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">ACTION</th>
                  </tr>
                </thead>
                <tbody className='text-neutral-800 font-light'>
                  { userDataArray && userDataArray.map((user) => (
                    <tr key={user.id}>
                      <td className="px-4 py-6 font-normal border border-[#48ABF7]">{user.first_name}</td>
                      <td className="px-4 py-2 border border-[#48ABF7]">{user.last_name}</td>
                      <td className="px-4 py-2 border border-[#48ABF7]">{user.email}</td>
                      <td className="px-4 py-2 border border-[#48ABF7]">{user.phone_number}</td>
                      <td className="px-4 py-2 border border-[#48ABF7]">{user.building_number}, {user.street}, {user.city}, {user.state}, {user.zip_code}</td>
                      <td className="px-4 py-2 border border-[#48ABF7]">
                        <div className='text-xl flex gap-3 items-center justify-around'>
                          <button className='p-1 rounded-full hover:bg-neutral-200 duration-200'>
                            <HiMiniPencilSquare className='text-blue-600'/>
                          </button>
                          <button onClick={() => handleUserDelete(user.user)} className='p-1 rounded-full hover:bg-neutral-200 duration-200'>
                            <TiPlus className='rotate-45 text-red-500'/>
                          </button>
                        </div>
                      </td>
                    </tr>
                  )) }
                </tbody>
              </table>
            </div>

            <div className='px-5 md:px-7 pt-6 pb-3 text-neutral-700 text-sm font-light flex items-center justify-between gap-2'>
              <p>Showing 1 to 10</p>
              <div className='flex items-center gap-2'>
                <button className='px-3 py-1 rounded-full bg-neutral-200'>Previous</button>
                <p className='px-3 py-1 rounded-full bg-blue-500 text-white font-normal'>1</p>
                <button className='px-3 py-1 rounded-full bg-neutral-200'>Next</button>
              </div>
            </div>

          </section>

        </section>
      </div>
    </div>
  )
}

export default UsersData