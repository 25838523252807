import React, { useState } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { uploadSvgPng } from "../../serverApi/server";

const SvgPngUpload = () => {

  const [assetName, setAssetName] = useState(null);
  const handleNameChange = (e) => {
    const valuee = e.target.value;
    setAssetName(() => (
      valuee.replace(/\s{2,}/g, ' ')
    ));
  }

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles(files);
  };

  const user_id = 4;

  const [assetType, setAssetType] = useState('');
  const handleTypeChange = (e) => {
    const TypeOfAsset = e.target.value
    setAssetType(TypeOfAsset);
  }

  const [loading, setLoading] = useState(false);
  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoading(true);
    try{
      const response = await uploadSvgPng(uploadedFiles, user_id, assetType, assetName);
      console.log('responseofSVG:',response)
      setLoading(false);
      if(response.data.client_status_code === 200) {
        setAssetName(null);
        setAssetType('');
        setUploadedFiles([]);
      }
    } catch(error) {
      console.error(error);
    }
  } 

  return (
    <div className="flex w-full h-screen">
      <div className="fixed h-full">
        <SideBar />
      </div>

      <div className="ml-64 flex-1 overflow-y-auto">
        <Header />
        <section className="bg-[#F5F7FD] px-7 py-6">
          <div className="text-blue-950 flex items-center gap-6">
            <h1 className="pr-6 text-2xl font-medium border-r">Admin Users</h1>
            <div className="flex text-sm items-center gap-3">
              <button>
                <SlHome />
              </button>
              <SlArrowRight />
              <button>Upload Design Assets</button>
            </div>
          </div>

          <section className="bg-white mt-10 py-4 rounded-lg shadow-[0px_0px_15px_0px_rgba(0,0,0,0.1)]">
            <div
              className="overflow-y-auto h-full flex items-center pl-10"
            >
              <div className="bg-white rounded-lg w-11/12 border max-w-md max-h-[90vh] overflow-y-auto">
                <div className="flex items-center p-4 border-b">
                  <h5 className="text-lg font-medium">Upload Design Assets</h5>
                </div>
                <div className="p-4">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label
                        htmlFor="name"
                        className="block text-sm text-gray-700"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter the asset Name"
                        className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                        value={assetName}
                        onChange={handleNameChange}
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="imageinput"
                        className="block text-sm text-gray-700"
                      >
                        File
                      </label>
                      <input
                        type="file"
                        multiple
                        name="imageinput"
                        required
                        className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                        onChange={handleFileChange}
                      />
                      {uploadedFiles.length !== 0 && (
                        <div>
                          <h3>Uploaded Files:</h3>
                          <ul>
                            {uploadedFiles.map((file, index) => (
                              <li key={index}>{file.name}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="subCategory"
                        className="block text-sm text-gray-700"
                      >
                        Asset Type
                      </label>
                      <select 
                        className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                        required
                        onChange={handleTypeChange}
                        value={assetType}
                      >
                        <option value="" disabled>
                          Choose an option
                        </option>
                        <option value="png">PNG Images</option>
                        <option value="svg">SVG Images</option>
                        <option value="upload">Background Images</option>
                      </select>
                    </div>
                    <div className="flex items-center p-4 border-t">
                      <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-400 duration-200 px-3 py-2 text-white rounded"
                      >
                        Upload
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div>
              {loading && (
                <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-90 flex flex-col justify-center items-center z-50">
                  <div className="w-12 h-12 border-4 border-gray-300 border-t-4 border-t-orange-500 rounded-full animate-spin"></div>
                  <p className="mt-4 text-sm sm:text-base md:text-lg text-gray-600">
                    Loading, please wait...
                  </p>
                </div>
              )}
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

export default SvgPngUpload;
