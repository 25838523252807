import React from 'react'
import './skeleton.css'

const Skeleton = ({width, height, marginBottom, variant}) => {
    const style = {
        width,
        height,
        marginBottom,
    };
  return (
    <span className={`skeleton ${variant}`} style={style}></span>
  )
}

export default Skeleton
