import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { getPaymentDetailsAPI } from "../../serverApi/server";

const Transaction = () => {
  const [paymentDtails, setPaymentDtails] = useState([]);
  const fetchPaymentDetails = async() => {
    try {
      const response = await getPaymentDetailsAPI();
      setPaymentDtails(response.data.payment_serializer)
    } catch(error) {
      console.error('Error Fetching Payment Details:',error);
    }
  }

  useEffect(() => {
    fetchPaymentDetails();
  }, [])

  return (
    <div className="flex w-full h-screen">
      <div className="fixed h-full">
        <SideBar />
      </div>

      <div className="ml-64 flex-1 overflow-y-auto">
        <Header />
        <section className="bg-[#F5F7FD] px-7 py-6">
          <div className="text-blue-950 flex items-center gap-6">
            <h1 className="pr-6 text-2xl font-medium border-r">Admin Users</h1>
            <div className="flex text-sm items-center gap-3">
              <button>
                <SlHome />
              </button>
              <SlArrowRight />
              <button>Orders</button>
              <SlArrowRight />
              <button>Transaction</button>
            </div>
          </div>

          <section className="bg-white mt-10 py-4 rounded-lg shadow-[0px_0px_15px_0px_rgba(0,0,0,0.1)]">
            <div className="px-5 md:px-7 pt-4 flex items-center justify-between gap-2">
              <div className="flex items-center gap-1 font-light">
                <p className="text-neutral-700">show</p>
                <select
                  name="entries"
                  className="border text-sm rounded-md focus:outline-none"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <p className="text-neutral-700">entries</p>
              </div>
              <form className="flex items-center gap-1 font-light">
                <label htmlFor="entries-search" className="text-neutral-700">
                  Search:
                </label>
                <input
                  type="text"
                  className="border-2 w-auto sm:w-40 text-sm px-2 py-[2px] rounded focus:outline-none"
                />
              </form>
            </div>

            <div className="overflow-x-auto sm:px-5 md:px-7">
              <table
                className="min-w-full bg-white mt-4"
                style={{ borderCollapse: "separate", borderSpacing: "2px" }}
              >
                <thead>
                  <tr>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">
                      CARD
                      <br />
                      HOLDER
                      <br />
                      NAME
                    </th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">
                      AMOUNT
                    </th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">
                      INVOICE<br />
                      NUMBER
                    </th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">
                      TRANSACTION<br />
                      STATUS
                    </th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">
                      TRANSACTION<br />
                      ID
                    </th>
                    {/* <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">
                      BILLING<br />
                      ADDRESS
                    </th> */}
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">
                      SHIPPING<br />
                      ADDRESS
                    </th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">
                      PHONE<br />
                      NUMBER
                    </th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">
                      EMAIL
                    </th>
                  </tr>
                </thead>
                <tbody className="text-neutral-800">
                  { paymentDtails && paymentDtails.map((payment) => (
                    <tr>
                      <td className="px-4 py-6 font-normal border border-[#48ABF7]">
                        {payment.ssl_first_name} {payment.ssl_last_name}
                      </td>
                      <td className="px-4 py-2 border border-[#48ABF7]">{payment.ssl_amount}</td>
                      <td className="px-4 py-2 border border-[#48ABF7]">
                        {payment.ssl_invoice_number}
                      </td>
                      <td className="px-4 py-2 border border-[#48ABF7]">
                        {payment.payment_status}
                      </td>
                      <td className="px-4 py-2 border border-[#48ABF7]">{payment.ssl_txn_id}</td>
                      {/* <td className="px-4 py-2 border border-[#48ABF7]">
                        {payment.ssl_avs_address},
                        {payment.ssl_city},
                        {payment.ssl_state},
                        {payment.ssl_avs_zip},
                      </td> */}
                      <td className="px-4 py-2 border border-[#48ABF7]">
                        {payment.ssl_avs_address},
                        {payment.ssl_city},
                        {payment.ssl_state},
                        {payment.ssl_avs_zip},
                      </td>
                      <td className="px-4 py-2 border border-[#48ABF7]">{payment.ssl_phone}</td>
                      <td className="px-4 py-2 border border-[#48ABF7]">{payment.ssl_email}</td>
                    </tr>
                  )) }
                </tbody>
              </table>
            </div>

            <div className="px-5 md:px-7 pt-6 pb-3 text-neutral-700 text-sm font-light flex items-center justify-between gap-2">
              <p>Showing 1 to 10 of 10 entries</p>
              <div className="flex items-center gap-2">
                <button className="px-3 py-1 rounded-full bg-neutral-200">
                  Previous
                </button>
                <p className="px-3 py-1 rounded-full bg-blue-500 text-white font-normal">
                  1
                </p>
                <button className="px-3 py-1 rounded-full bg-neutral-200">
                  Next
                </button>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

export default Transaction;
