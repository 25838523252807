// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton {
    border-radius: 6px;
    display: inline-block;
    line-height: 100%;
    width: 100%;
    background-color: #fff;
    background: linear-gradient(
        to right,
        #f6f7f8 0%,
        #edeef1 10%,
        #f6f7f8 20%,
        #f6f7f8 100%
      );
    background-size: 200% 100%;
    animation: placeholderShimmer 1.5s linear infinite;
}

@keyframes placeholderShimmer {
    0% {
        background-position: 50% 0;
    }
    100% { 
        background-position: -150% 0;
    }
}

.skeleton.circle {
    border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/pages/skeleton/skeleton.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,iBAAiB;IACjB,WAAW;IACX,sBAAsB;IACtB;;;;;;OAMG;IACH,0BAA0B;IAC1B,kDAAkD;AACtD;;AAEA;IACI;QACI,0BAA0B;IAC9B;IACA;QACI,4BAA4B;IAChC;AACJ;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".skeleton {\r\n    border-radius: 6px;\r\n    display: inline-block;\r\n    line-height: 100%;\r\n    width: 100%;\r\n    background-color: #fff;\r\n    background: linear-gradient(\r\n        to right,\r\n        #f6f7f8 0%,\r\n        #edeef1 10%,\r\n        #f6f7f8 20%,\r\n        #f6f7f8 100%\r\n      );\r\n    background-size: 200% 100%;\r\n    animation: placeholderShimmer 1.5s linear infinite;\r\n}\r\n\r\n@keyframes placeholderShimmer {\r\n    0% {\r\n        background-position: 50% 0;\r\n    }\r\n    100% { \r\n        background-position: -150% 0;\r\n    }\r\n}\r\n\r\n.skeleton.circle {\r\n    border-radius: 50%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
