import React from 'react'
import SideBar from './SideBar'
import Header from './Header'
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";

const DispatchedOrders = () => {
  return (
    <div className='flex'>
      <SideBar />

      <div className='w-full'>
        <Header />
        <section className="px-5 pt-5 pb-16 bg-[#F5F7FD] h-screen overflow-y-auto">
          <div className="text-blue-950 flex items-center gap-6">
            <h1 className="pr-6 text-2xl font-medium border-r">
              Admin Users
            </h1>
            <div className="flex text-sm items-center gap-3">
              <button>
                <SlHome />
              </button>
              <SlArrowRight />
              <button>
                Orders
              </button>
              <SlArrowRight />
              <button>
                Dispatched Orders
              </button>
            </div>
          </div>

          <section className='bg-white mt-10 py-4 rounded-lg shadow-[0px_0px_15px_0px_rgba(0,0,0,0.1)]'>
            <div className='px-5 md:px-7 pt-4 flex items-center justify-between gap-2'>
              <div className='flex items-center gap-1 font-light'>
                <p className='text-neutral-700'>show</p>
                <select name="entries" className='border text-sm rounded-md focus:outline-none'>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <p className='text-neutral-700'>entries</p>
              </div>
              <form className='flex items-center gap-1 font-light'>
                <label htmlFor="entries-search" className='text-neutral-700'>Search:</label>
                <input type="text" className='border-2 w-auto sm:w-40 text-sm px-2 py-[2px] rounded focus:outline-none'/>
              </form>
            </div>

            <div className="overflow-x-auto sm:px-5 md:px-7">
              <table className="min-w-full bg-white mt-4" style={{ borderCollapse: 'separate', borderSpacing: '2px' }}>
                <thead>
                  <tr>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">PRODUCT <br />NAME</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">ORDER <br />ID</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">DATE</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">AMOUNT</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">DELIVERY <br />STATUS</th>
                  </tr>
                </thead>
                <tbody className='text-neutral-800'>
                  <tr>
                    <td className="px-4 py-6 font-normal border border-[#48ABF7]">Bookmarks</td>
                    <td className="px-4 py-2 border border-[#48ABF7]">5672</td>
                    <td className="px-4 py-2 border border-[#48ABF7]">10-11-2024</td>
                    <td className="px-4 py-2 border border-[#48ABF7]">11.30 Am</td>
                    <td className="px-4 py-2 border border-[#48ABF7]"><p className='px-3 py-2 rounded bg-[#AF793F] text-white inline'>Dispatched</p></td>
                  </tr>
                  <tr>
                    <td className="px-4 py-6 font-normal border border-[#48ABF7]">Bookmarks</td>
                    <td className="px-4 py-2 border border-[#48ABF7]">5672</td>
                    <td className="px-4 py-2 border border-[#48ABF7]">10-11-2024</td>
                    <td className="px-4 py-2 border border-[#48ABF7]">11.30 Am</td>
                    <td className="px-4 py-2 border border-[#48ABF7]"><p className='px-3 py-2 rounded bg-green-600 text-white inline'>Delivered</p></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='px-5 md:px-7 pt-6 pb-3 text-neutral-700 text-sm font-light flex items-center justify-between gap-2'>
              <p>Showing 1 to 10 of 10 entries</p>
              <div className='flex items-center gap-2'>
                <button className='px-3 py-1 rounded-full bg-neutral-200'>Previous</button>
                <p className='px-3 py-1 rounded-full bg-blue-500 text-white font-normal'>1</p>
                <button className='px-3 py-1 rounded-full bg-neutral-200'>Next</button>
              </div>
            </div>

          </section>

        </section>
      </div>
    </div>
  )
}

export default DispatchedOrders