import React, { useState, useEffect } from 'react'
import SideBar from './SideBar'
import Header from './Header'
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { useLocation } from 'react-router-dom';
import { FaEdit } from "react-icons/fa";
import { createFieldandOptions, deleteField, deleteOption, getCategorySubMenusAPI, getFields, getSubmenuFields, updateCategorySubmenuPrice, paperTypeSelectedOption, editFieldName } from '../../serverApi/server'
import { FaMinusCircle } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import Select from 'react-select';


const FieldPricing = () => {
  const location = useLocation();
  const headerMenus = location.state || [];
  const [categoryList, setCategoryList] = useState([])
  const [loadingButton, setLoadingButton] = useState(null);
  const [totalFieldList, setTotalFieldList] = useState([])
  const [prices, setPrices] = useState({});
  const [paperType, setPaperType] = useState();
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedSizeFieldOption, setSelectedSizeFieldOption] = useState('');
  const [currentcategoryId, setCurrentCategoryId] = useState('');
  

  const [permissionFields, setPermissionFields] = useState({});
  const [ispermissionField, setIsPermissionField] = useState(false);
  const [allPriceFieldOptions, setAllPriceFieldOptions] = useState({});
  const [enableQuantity, setEnableQuantity] = useState(false);
  const [fieldIds, setFieldIds] = useState([]);


  const [isChecked, setIsChecked] = useState(false);


  const [fieldValidation, setFieldValidation] = useState(false);
  const [sizeField, setSizeField] = useState();

  const [selectedCategory, setSelectedCategory] = useState(
    headerMenus.find((menu) => menu.additional_name === 'memorial_funeral')?.id || ''
  );

  const [selectedCategoryAdditionalName, setSelectedCategoryAdditionalName] = useState('');
  
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };
  
  const [subMenuList, setSubMenuList] = useState('') 
  const handleSubMenuListChange = (e) => {
    setSubMenuList(e.target.value);
  }

  const fetchCategorySubMenus = async () => {
    try {
      const getCategorySubMenusAPIResponse = await getCategorySubMenusAPI(selectedCategory);
      setCategoryList(getCategorySubMenusAPIResponse.data.categories_serializer)
      setSubMenuList(getCategorySubMenusAPIResponse.data.categories_serializer.find((menu) => menu.additional_name === 'bookmarks')?.id || '')
    } catch (error) {
      console.error('Error fetching sub-menus:', error);
    }
  };

  const paperTypeSelectedOptionAPI = async (allPriceFieldOptions) => {
    try {
      const paperTypeSelectedOptionAPIResponse = await paperTypeSelectedOption(allPriceFieldOptions);
      const newPrices = { ...prices };
      const serializer = paperTypeSelectedOptionAPIResponse.data.paper_type_field_amount_serializer;
      serializer.forEach(({ option, amount }) => {
        if (newPrices.hasOwnProperty(option)) {
          newPrices[option] = amount;
        }
      });
      setPrices(newPrices);
    } catch (error) {
      console.error('Error fetching sub-menus:', error);
    }
  };


  // useEffect(() => {

  //   console.log('selectedOption', selectedOption)
  //   console.log('selectedSizeFieldOption', selectedSizeFieldOption)

  //   if (selectedOption || selectedSizeFieldOption) {
  //     paperTypeSelectedOptionAPI()
  //   }
  // }, [selectedOption, selectedSizeFieldOption]);


  const fetchGetSubmenuFields = async () => {
    setSelectedOption('')
    setSelectedSizeFieldOption('')
    setPermissionFields({})
    setIsPermissionField(false)
    setAllPriceFieldOptions({})
    setEnableQuantity(false)
    setFieldIds([])

    try {
      const getGetSubmenuFieldsAPIResponse = await getSubmenuFields(subMenuList);
      const paperTypeField = getGetSubmenuFieldsAPIResponse.data.form_fields_serializer.find(field => field.additional_name === "paper_type");
      const sizeFieldOptions = getGetSubmenuFieldsAPIResponse.data.form_fields_serializer.find(field => field.additional_name === "size");
      setSizeField(sizeFieldOptions)
      setPaperType(paperTypeField)
      setTotalFieldList(getGetSubmenuFieldsAPIResponse.data.form_fields_serializer)
      setSelectedCategoryAdditionalName(getGetSubmenuFieldsAPIResponse.data.category_additional_name)
      setCurrentCategoryId(getGetSubmenuFieldsAPIResponse.data.category_id)
    } catch (error) {
      console.error('Error fetching sub-menus:', error);
    }
  };

  useEffect(()=>{
    if (subMenuList) {
      fetchGetSubmenuFields();

    }
  }, [subMenuList])

  useEffect(() => {
    const initialPrices = {};
    totalFieldList.forEach(category => {
        category.options.forEach(option => {
        initialPrices[option.id] = option.amount || '';
      })
    });
    setPrices(initialPrices);
  }, [totalFieldList]);

  const handlePriceChange = (e, categoryId) => {
    setPrices(prevPrices => ({
      ...prevPrices,
      [categoryId]: e.target.value,
    }));

  };

  const updateCategoryPrice = async (categoryId, category_sub_menu, mainFieldId) => {
    setLoadingButton(categoryId);
    const price = prices[categoryId];
    setTotalFieldList(prevList =>
      prevList.map(field => {
        if (field.id === mainFieldId) {
          return {
            ...field,
            options: field.options.map(option =>
              option.id === categoryId ? { ...option, amount: price.toString() } : option
            ),
          };
        }
        return field;
      })
    );
    const category_update_type = 'optionPriceUpdate'
    const updateName = null
    const subMenuList = ''
    try {
      const updateCategorySubmenuPriceResponse = await updateCategorySubmenuPrice(categoryId, price, category_sub_menu, category_update_type, subMenuList, selectedOption, updateName, selectedSizeFieldOption, allPriceFieldOptions, currentcategoryId, fieldIds);
    } catch (error) {
      console.error('Error fetching sub-menus:', error);
    } finally {
      setTimeout(() => {
        setLoadingButton(null);
      }, 2000);
    }
  }

  const quentityUpdateCategoryPrice = async (categoryId, category_sub_menu, selected_category) => {
    setLoadingButton(categoryId);
    const price = prices[categoryId];
    const category_update_type = 'optionPriceUpdateList'
    // if (!selectedOption ) {
    //   setFieldValidation(true)
    //   setTimeout(() => {
    //     setLoadingButton(null);
    //   }, 2000);
    //   return;
    // }
    setFieldValidation(false)
    const updateName = null
    try {
      const updateCategorySubmenuPriceResponse = await updateCategorySubmenuPrice(categoryId, price, category_sub_menu, category_update_type, subMenuList, selectedOption, updateName, selectedSizeFieldOption, allPriceFieldOptions, currentcategoryId, fieldIds);
    } catch (error) {
      console.error('Error fetching sub-menus:', error);
    } finally {
      setTimeout(() => {
        setLoadingButton(null);
      }, 2000);
    }
  }

  const toggleForm = () => setFormOpen(!formOpen);
  const [formOpen, setFormOpen] = useState(false);
  const [createOrSelectField, setCreateOrSelectField] = useState('0');
  const [fieldType, setFieldType] = useState([]);
  const [newField, setNewField] = useState();
  const [newOption, setNewOption] = useState("");
  const [newInput, setNewInput] = useState([]);
  const formheaderMenus = location.state || [];
  const [discounts, setDiscounts] = useState({});
  const [selectedFormHeader, setSelectedFormHeader] = useState(
    formheaderMenus.find((menu) => menu.additional_name === 'memorial_funeral')?.id || ''
  );
  const handleFormHeaderChange = (e) => {
    setSelectedFormHeader(e.target.value);
  };
  const [formCategoryList, setFormCategoryList] = useState([]);
  const [formSubMenuList, setFormSubMenuList] = useState('')
  const handleFormSubMenuListChange = (e) => {
    setFormSubMenuList(e.target.value);
  }

  useEffect(() => {
    const fetchCategorySubMenus = async () => {
      try {
        const getCategorySubMenusAPIResponse = await getCategorySubMenusAPI(selectedFormHeader);
        setFormCategoryList(getCategorySubMenusAPIResponse.data.categories_serializer)
        setFormSubMenuList(getCategorySubMenusAPIResponse.data.categories_serializer.find((menu) => menu.additional_name === 'bookmarks')?.id || '')
      } catch (error) {
        console.error('Error fetching FORM-sub-menus:', error);
      }
    };
    if (selectedFormHeader) {
      fetchCategorySubMenus();
    }
  }, [selectedFormHeader]);

  const fetchFields = async () => {  
    try {
      const getFieldsResponse = await getFields(formSubMenuList);
      setFieldType(getFieldsResponse.data.form_field_serializer);
    } catch (error) {
      console.error('Error fetching Fields:', error);
    }
  }

  useEffect(() => {
    if (formSubMenuList) {
      fetchFields();
    }
  }, [formSubMenuList])
  

  useEffect(() => {
    if (formOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [formOpen]);

  const addNewField = () => {
    setNewInput([
      ...newInput,
      { id: newInput.length + 1, value: "" },
    ]);
  };

  const saveCategory = async () => {
    const optionsArray = [
      newOption,
      ...newInput.map((field) => field.value).filter(Boolean),
    ].filter(Boolean);
    
    const fieldName = (createOrSelectField === "addnewfield") 
    ? newField 
    : fieldType.find((field) => field.id == createOrSelectField)?.field_name;

    try {
      const createNewFieldOptions = await createFieldandOptions(formSubMenuList, fieldName, createOrSelectField, optionsArray);
      if(createNewFieldOptions.data.client_status_code === 200) {
        fetchFields();
        fetchGetSubmenuFields();
        setNewField('');
        setNewOption('');
        setNewInput([]);
      } 
    } catch (error) {
      console.error('Error Creating fieldOptions:', error);
    }
    toggleForm();
 
  };

  const handleFieldDelete = async (delete_category_id) => {
    try {
      const deleteFieldResponse = await deleteField(delete_category_id)
      if (deleteFieldResponse.data.client_status_code === 200) {
        fetchGetSubmenuFields();
        fetchFields();
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const delete_type = "option";

  const handleOptionDelete = async (option_id) => {
    try {
      const deleteOptionResponse = await deleteOption(option_id, delete_type);
      if (deleteOptionResponse.data.client_status_code === 200) {
        fetchGetSubmenuFields();
      }
    } catch (error) {
      console.error("Error deleting Option", error);
    }
  }

  const handleUpdateAllFieldPrice = async (mainFieldId, updateName) => {
    const mainField = totalFieldList.find((field) => field.id === mainFieldId);
    if (mainField) {
      const optionData = mainField.options.map((option) => ({
        id: option.id,
        value: prices[option.id] || '',
      }));
      const optionIds = mainField.options.map((option) => option.id);
      const firstOptionValue = optionData[0]?.value;
  
      if (firstOptionValue) {
        const baseValue = parseFloat(firstOptionValue);
  
        if (!isNaN(baseValue)) {
          const updatedValues = optionIds.map((id, index) => {
            const multiplier = index + 1;
            return baseValue * multiplier;
          });
          const filteredPrices = Object.fromEntries(
            Object.entries(prices).filter(([key]) => optionIds.includes(parseInt(key)))
          );
          const newPrices = { ...prices };
          optionIds.forEach((id, index) => {
            newPrices[id] = updatedValues[index];
          });
          setPrices(newPrices);

          const quantityPriceList = Object.fromEntries(
            Object.entries(newPrices).filter(([key]) => optionIds.includes(parseInt(key)))
          );  
          const price = 0
          const category_sub_menu = 'update_field'
          const category_update_type = updateName === 'quantity' || updateName === 'Quantity' ? "bulkUpdateQuentity" : "bulkUpdate"


          if (updateName === 'photos' || updateName === 'Photos') {
            const updatedPrices = { ...newPrices };
            let firstIndexSet = false;
            Object.entries(discounts).forEach(([id, discountValue]) => {
              if (!firstIndexSet) {
                updatedPrices[id] = 0;
                firstIndexSet = true;
              } else if (quantityPriceList.hasOwnProperty(id)) {
                const priceValue = quantityPriceList[id];
                const discountAmount = (discountValue / 100) * priceValue;
                const discountedPrice = priceValue - discountAmount;
                updatedPrices[id] = discountedPrice;
              }
            });
            setPrices(updatedPrices);
            const matchingPrices = optionIds.reduce((acc, id) => {
              if (updatedPrices.hasOwnProperty(id)) {
                acc[id] = updatedPrices[id];
              }
              return acc;
            }, {});

            setTotalFieldList((prevList) =>
              prevList.map((field) =>
                field.id === mainFieldId
                  ? {
                      ...field,
                      options: field.options.map((option) =>
                        quantityPriceList.hasOwnProperty(option.id)
                          ? { ...option, amount: quantityPriceList[option.id].toString() }
                          : option
                      ),
                    }
                  : field
              )
            );

            const updateCategorySubmenuPriceResponse = await updateCategorySubmenuPrice(matchingPrices, price, category_sub_menu, category_update_type, subMenuList, selectedOption, updateName, selectedSizeFieldOption, allPriceFieldOptions, currentcategoryId, fieldIds);
            return;
          }
		  console.log('mainFieldId', mainFieldId)
          console.log('quantityPriceList', quantityPriceList)
          setTotalFieldList((prevList) =>
            prevList.map((field) =>
              field.id === mainFieldId
                ? {
                    ...field,
                    options: field.options.map((option) =>
                      quantityPriceList.hasOwnProperty(option.id)
                        ? { ...option, amount: quantityPriceList[option.id].toString() }
                        : option
                    ),
                  }
                : field
            )
          );

          const updateCategorySubmenuPriceResponse = await updateCategorySubmenuPrice(quantityPriceList, price, category_sub_menu, category_update_type, subMenuList, selectedOption, updateName, selectedSizeFieldOption, allPriceFieldOptions, currentcategoryId, fieldIds);
        
        } else {
          console.error('Invalid firstOptionValue:', firstOptionValue);
        }
      } else {
        console.error('First option value is missing or invalid!');
      }
    } else {
      console.error('MainField not found!');
    }
  };

  const handleDiscountChange = (e, optionId) => {
    const value = e.target.value;
    setDiscounts((prevDiscounts) => ({
      ...prevDiscounts,
      [optionId]: value,
    }));
  };

  useEffect(() => {
    if (selectedCategory) {
      fetchCategorySubMenus();
    }
  }, [selectedCategory]);

  const handleSelectChange = async (selectedOptions) => {
    const updatedFields = selectedOptions.reduce((acc, option) => {
      acc[option.value] = totalFieldList.find(
        (field) => field.field_name === option.value
      );
      return acc;
    }, {});
    await setPermissionFields(updatedFields);
    const idsList = Object.values(updatedFields).map((field) => field.id);
    setFieldIds(idsList)
  };

  const handlePermissionFields = () => {
    setIsPermissionField(true)
  }

  useEffect(() => {
    if ((Object.keys(allPriceFieldOptions).length === Object.keys(permissionFields).length) && (Object.keys(allPriceFieldOptions).length !== 0)) {
      paperTypeSelectedOptionAPI(allPriceFieldOptions)
      setEnableQuantity(true)
      
    }
  }, [allPriceFieldOptions])

  const [fieldEditInput, setFieldEditInput] = useState(null);
  const toggleFieldEdit = (id) => {
    setFieldEditInput((prevId) => (prevId === id ? null : id));
  };

  const [optionEditInput, setOptionEditInput] = useState(null);
  const toggleOptionEdit = (id) => {
    setOptionEditInput((prevId) => (prevId === id ? null : id));
  };

  const [fieldName, setFieldName] = useState(null);
  const updateFieldName = (e, FieldId) => {
    const { value } = e.target;
    setTotalFieldList((prevState) =>
      prevState.map((item) =>
        item.id === FieldId
          ? { ...item, field_name: value }
          : item
      )
    );
    setFieldName(value);
  }

  const [optionName, setOptionName] = useState(null);
  const updateOptionName = (e, FieldId, optionsId) => {
    const { value } = e.target;
  
    setTotalFieldList((prevState) =>
      prevState.map((item) =>
        item.id === FieldId
          ? {
              ...item,
              options: item.options
                ? item.options.map((option) =>
                    option.id === optionsId ? { ...option, name: value } : option
                  )
                : [],
            }
          : item
      )
    );
    setOptionName(value);
  };

  const APIUpdateFieldName = async(fieldId) => {
    const fieldType = 'field'
    try {
      const response = await editFieldName( fieldType , fieldId, fieldName);
      toggleFieldEdit(fieldId);
    }
    catch (error) {
      console.error(error);
    }
  }
  const APIUpdateOptionName = async(optionId) => {
    const optionType = 'option'
    try {
      const response = await editFieldName( optionType , optionId, optionName);
      toggleOptionEdit(optionId);
    }
    catch (error) {
      console.error(error);
    }
  }

  const handleCheckboxChange = async(checkBoxfieldId, event) => {
	console.log('totalFieldList', totalFieldList)
    const optionType = 'calculation_status'
    setTotalFieldList((prevList) =>
      prevList.map((field) =>
        field.id === checkBoxfieldId
          ? { ...field, calculation_status: event.target.checked }
          : field
      )
    );
    await editFieldName(optionType, checkBoxfieldId, event.target.checked);
  };

  return (
    <div className="flex w-full h-screen">
      <div className="fixed h-full">
        <SideBar />
      </div>
      <div className="ml-64 flex-1 overflow-y-auto">
        <Header />
        <div className="bg-[#F5F7FD] px-7 py-6">
          <div className='flex items-center justify-between'>
            <div className="text-blue-950 flex items-center gap-6">
              <h1 className="pr-6 text-2xl font-medium border-r">
                Admin Users
              </h1>
              <div className="flex text-sm items-center gap-3">
                <button>
                  <SlHome />
                </button>
                <SlArrowRight />
                <button>
                  Pricing
                </button>
                <SlArrowRight />
                <button>
                  Field Pricing
                </button>
              </div>
            </div>

            <button 
              className="bg-blue-500 hover:bg-blue-400 duration-200 px-4 py-2 rounded text-white mr-4 md:mr-8"
              onClick={toggleForm}
            >
              Add New Fields
            </button>
          </div>
          {formOpen && (
            <div
              className="overflow-y-auto fixed inset-0 h-full z-50 flex items-center justify-center bg-black bg-opacity-50"
              aria-hidden="true"
            >
              <div className="bg-white rounded-lg w-11/12 max-w-md max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center p-4 border-b">
                  <h5 className="text-lg font-medium">Add new Fields or Options</h5>
                  <button
                    type="button"
                    className="text-gray-600 text-3xl hover:text-gray-900"
                    onClick={toggleForm}
                  >
                    &times;
                  </button>
                </div>
                <div className="p-4">

                  <div className="mb-4">
                    <label
                      htmlFor="categoryType"
                      className="block text-sm text-gray-700"
                    >
                      Header Name
                    </label>
                    <select
                      className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                      onChange={handleFormHeaderChange}
                      value={selectedFormHeader}
                    >
                      {formheaderMenus.map((menu) => (
                        <option key={menu.id} value={menu.id}>
                          {menu.menu}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="subCategory"
                      className="block text-sm text-gray-700"
                    >
                      Category 
                    </label>
                    <select
                      id="subCategory"
                      name="subCategory"
                      required
                      className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                      onChange={handleFormSubMenuListChange}
                      value={formSubMenuList}
                    >
                      <option value="" disabled>
                        Choose an option
                      </option>
                      {formCategoryList && formCategoryList.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.category}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="fields"
                      className="block text-sm text-gray-700"
                    >
                      Create <span className='text-green-600'>(or)</span> Select Field 
                    </label>
                    <select
                      name="fields_or_options"
                      className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                      required
                      value={createOrSelectField}
                      onChange={(e) => setCreateOrSelectField(e.target.value)}
                    >
                      <option value="0" disabled>
                        Choose an option
                      </option>
                      <option id='0' value="addnewfield" className='text-green-600'>Add new field</option>
                      {fieldType.map((field) => (
                        <option key={field.id} value={field.id}>
                          {field.field_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {createOrSelectField === "addnewfield" && (
                    <div className="mb-4">
                      <label
                        htmlFor="newField"
                        className="block text-sm text-gray-700"
                      >
                        New Field
                      </label>
                      <input
                        type="text"
                        name="newField"
                        required
                        placeholder="Enter Field Name"
                        className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                        value={newField}
                        onChange={(e) => setNewField(e.target.value)}
                      />
                    </div>
                  )}
                  {createOrSelectField !== "0" && (
                    <div>
                      <label
                        htmlFor="addOptions"
                        className="block text-sm text-gray-700"
                      >
                        New Option
                      </label>
                      <input
                        type="text"
                        name="addOptions"
                        required
                        placeholder="Option 1"
                        className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                        value={newOption}
                        onChange={(e) => setNewOption(e.target.value)}
                      />
                    </div>
                  )}

                  <div className="mb-4">
                    {newInput.map((field) => (
                      <div className="flex items-center gap-1" key={field.id}>
                        <input
                          type="text"
                          placeholder={`Option ${field.id + 1}`}
                          className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                          value={field.value}
                          onChange={(e) =>
                            setNewInput((prev) =>
                              prev.map((f) =>
                                f.id === field.id ? { ...f, value: e.target.value } : f
                              )
                            )
                          }
                        />
                        <button
                          type="button"
                          className="text-red-500 hover:text-red-400 duration-200 text-xl"
                          onClick={() =>
                            setNewInput((prev) => prev.filter((f) => f.id !== field.id))
                          }
                        >
                          <FaMinusCircle />
                        </button>
                      </div>
                    ))}
                  </div>
                  <button
                    type="button"
                    className="bg-blue-500 hover:bg-blue-400 mb-4 duration-200 px-3 py-2 text-white rounded"
                    onClick={addNewField }
                  >
                    Add New Option
                  </button>
                  <div className="flex justify-end items-center pt-4 border-t">
                    <button
                      type="button"
                      className="bg-red-500 hover:bg-red-400 duration-200 px-3 py-2 mr-3 text-white rounded"
                      onClick={toggleForm}
                    >
                      Close
                    </button>
                    <button
                      onClick={saveCategory}
                      className="bg-blue-500 hover:bg-blue-400 duration-200 px-3 py-2 text-white rounded"
                    >
                      Save
                    </button>
                  </div>

                </div>
                
              </div>
            </div>
          )} 
          <div className='bg-white mt-10 px-3 py-4 rounded-lg'>
            <div className='flex items-center gap-3 text-sm'>
              <p className='text-neutral-500'>Filter by Role:</p>
              <div className='flex items-center gap-2'>
                <select className='focus:outline-none focus:border-blue-300 border rounded px-1 py-1'
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                  >
                    {headerMenus.map((menu) => (
                      <option key={menu.id} value={menu.id}>
                        {menu.menu}
                      </option>
                    ))}
                </select>
                <select className='focus:outline-none focus:border-blue-300 border rounded px-1 py-1'
                  onChange={handleSubMenuListChange}
                  value={subMenuList}
                >
                  {categoryList && categoryList.map((menu) => (
                    <option key={menu.id} value={menu.id}>
                      {menu.category}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white mt-8" style={{ borderCollapse: 'separate', borderSpacing: '2px' }}>
                <thead>
                  <tr>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">CATEGORY NAME</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">NEED CALCULATION</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">OPTIONS</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {totalFieldList.map((mainField) => (
                    <tr key={mainField.id}>
                     <td className="px-4 py-2 border border-[#48ABF7]">
                        {fieldEditInput === mainField.id ? (
                          <div className="flex items-center gap-2">
                            <input
                              type="text"
                              name='field'
                              value={mainField.field_name}
                              onChange={(e) => updateFieldName(e, mainField.id)}
                              placeholder="rename field"
                              className="px-2 py-1 w-48 rounded border shadow focus:outline-none"
                            />
                            <button
                              onClick={() => APIUpdateFieldName(mainField.id)}
                              className="bg-blue-500 hover:bg-blue-400 text-white px-2 rounded-full active:bg-blue-200 duration-200"
                            >
                              Update
                            </button>
                          </div>
                        ) : (
                          <div className="flex items-center gap-2">
                            <p>{mainField.field_name}</p>
                            <button
                              onClick={() => toggleFieldEdit(mainField.id)}
                              className="text-blue-500 hover:text-blue-600 hover:bg-black/15 p-2 rounded-full duration-200"
                            >
                              <FaEdit />
                            </button>
                          </div>
                        )}
                      </td>
                      <td className="px-4 py-2 border border-[#48ABF7] text-center">
                        <input 
                          type="checkbox" 
                          checked={mainField.calculation_status} 
                          onChange={(event) => handleCheckboxChange(mainField.id, event)} 
                        />
                      </td>
                      <td className="px-4 py-2 border border-[#48ABF7]">
                        <table className="w-full bg-neutral-100">
                          <tbody>
                          {((mainField.additional_name === 'quantity' || mainField.additional_name === 'Quantity')) && (
                            <div>
                              <p>Permissions to Fields</p>
                              <div style={{ display: 'flex' }}>
                                <div>
                                  <Select
                                    isMulti
                                    options={totalFieldList.map((field) => ({
                                      value: field.field_name,
                                      label: field.field_name,
                                    }))}
                                    onChange={handleSelectChange}
                                  />
                                </div>
                                  <div>
                                    <button onClick={handlePermissionFields} className="bg-blue-500 text-white py-1 px-3 rounded-full flex items-center">
                                      Submit
                                    </button>
                                  </div>
                              </div>
                            </div>
                          )}
                          {((mainField.additional_name === 'quantity' || mainField.additional_name === 'Quantity') && ispermissionField) && (
                            Object.entries(permissionFields).map(([key, permissionField]) => (
                              <div key={permissionField.id} className="mt-4">
                                <label className="block text-gray-700 font-medium mb-2">
                                  {permissionField.field_name}
                                </label>
                                <select
                                  className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                                  value={allPriceFieldOptions[permissionField.field_name]?.id || ""}
                                  onChange={(e) => {
                                    const selectedOption = permissionField.options.find(
                                      (menu) => menu.id === parseInt(e.target.value)
                                    );

                                    if (selectedOption) {
                                      setAllPriceFieldOptions((prev) => ({
                                        ...prev,
                                        [permissionField.field_name]: selectedOption,
                                      }));
                                    }
                                  }}
                                >
                                  <option value="" disabled>Choose an option</option>
                                  {permissionField.options.map((menu) => (
                                    <option key={menu.id} value={menu.id}>
                                      {menu.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ))
                          )}
                          {/* {((mainField.additional_name === 'quantity' || mainField.additional_name === 'Quantity') && ispermissionField ) && (
                            <div>
                              <select 
                                className="mt-2 block w-115% rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm" 
                                value={selectedOption} 
                                onChange={(e) => setSelectedOption(e.target.value)}
                              >
                                <option value="" disabled>Choose an option</option>
                                {paperType.options.map((menu) => (
                                  <option key={menu.id} value={menu.id}>
                                    {menu.name}
                                  </option>
                                ))}
                              </select>

                              {fieldValidation && selectedOption === "" && (
                                <div className="flex items-center mb-4">
                                  <p className="text-red-500 text-sm">Please choose an option.</p>
                                </div>
                              )}
                            </div>
                          )} */}
                            {mainField.options.map((options, index) => (
                              <tr key={index} className='hover:bg-neutral-200 duration-100'>
                                <td className='px-2 py-2 border border-[#48ABF7]'>
                                  
                                  {optionEditInput === options.id ? (
                                    <div className="flex items-center gap-2">
                                      <input
                                        type="text"
                                        value={options.name} 
                                        onChange={(e) => updateOptionName(e, mainField.id, options.id)}
                                        placeholder="rename option"
                                        className="px-2 py-1 w-48 rounded border shadow focus:outline-none"
                                      />
                                      <button
                                        onClick={() =>
                                          APIUpdateOptionName(options.id)
                                        }
                                        className="bg-blue-500 hover:bg-blue-400 text-white px-2 rounded-full active:bg-blue-200 duration-200"
                                      >
                                        Update
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="flex items-center gap-2">
                                      <p>{options.name}</p>
                                      <button
                                        onClick={() =>
                                          toggleOptionEdit(options.id)
                                        }
                                        className="text-blue-500 hover:text-blue-600 hover:bg-black/15 p-2 rounded-full duration-200"
                                      >
                                        <FaEdit />
                                      </button>
                                    </div>
                                  )}</td>
                                <td className='px-2 py-2 border border-[#48ABF7]'>
                                  <div style={{display: 'flex'}}>
                                    <span style={{ marginTop: '6px', fontSize: '20px', marginRight: '5px' }}>$</span>
                                    {(mainField.additional_name === 'quantity' ||
                                      mainField.additional_name === 'Quantity') &&
                                      (!enableQuantity) ? ('') : (
                                        <input
                                          type="text"
                                          className="form-input w-full border rounded-md p-2"
                                          placeholder="Enter value"
                                          value={prices[options.id] || ''}
                                          onChange={(e) => handlePriceChange(e, options.id)}
                                        />
                                      )}

                                  </div>
                                </td>
                                {mainField.additional_name === 'photos' && (
                                  <td className='px-2 py-2 border border-[#48ABF7]'>
                                    <div style={{ display: 'flex' }}>
                                      <input
                                        type="text"
                                        className="form-input w-full border rounded-md p-2"
                                        placeholder="Enter Discount"
                                        value={discounts[options.id] || ''} // Use the state to get the value
                                        onChange={(e) => handleDiscountChange(e, options.id)} // Call the handler
                                      />
                                      <span style={{ marginTop: '6px', fontSize: '20px', marginLeft: '5px' }}>%</span>
                                    </div>
                                  </td>
                                )}
                                <td className="px-4 py-2 border border-[#48ABF7]">
                                  <button className={`bg-blue-500 text-white py-1 px-3 mb-2 rounded-full flex items-center update-btn ${
                                    loadingButton === options.id ? 'loading' : ''
                                  }`} title="Update"
                                  onClick={() => (mainField.additional_name === 'quantity' || mainField.additional_name === 'Quantity') ? quentityUpdateCategoryPrice(options.id, 'sub_menu_field', selectedCategoryAdditionalName) : updateCategoryPrice(options.id, 'sub_menu_field', mainField.id)}
                                  disabled={loadingButton === options.id}
                                  >
                                  <FaEdit />
                                  {loadingButton === options.id ? 'Updating...' : 'Update'}
                                  </button>
                                  <button 
                                    className="bg-red-500 text-white py-1 px-3 rounded-full flex items-center" 
                                    onClick={() => handleOptionDelete(options.id)}
                                  >
                                    <MdDeleteOutline className="mr-[1px]"/>
                                    Delete
                                  </button>
                                </td>

                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                      <td className="px-4 py-2 border border-[#48ABF7]">
                        <button 
                          className="bg-red-500 text-white py-1 px-3 rounded-full flex items-center"
                          onClick={() => handleFieldDelete(mainField.id)}
                        >
                          <MdDeleteOutline className="mr-[1px]"/>
                          Delete
                        </button>
                        {(mainField.additional_name === 'quantity' || mainField.additional_name === 'Quantity' || mainField.additional_name === 'photos') && (
                          <button 
                            className="bg-blue-500 text-white py-1 px-3 rounded-full flex items-center"
                            onClick={() => handleUpdateAllFieldPrice(mainField.id, mainField.additional_name)}
                          >
                            <FaEdit className="mr-[1px]"/>
                            Update
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FieldPricing
