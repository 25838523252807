import React, { useEffect, useState } from 'react';
import { cardPlaceHolder } from '../../helper/PlaceHolder';
import _ from "lodash";

export default function TemplateDesign({ showPdfUrl }) {

    const [isOpen, setIsOpen] = useState('yearbook');
    const [yearBook, setYearBook] = useState([]);
    const [businessCard, setBusinessCard] = useState([]);
    const [frames, setFrames] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const fetchPdfs = async () => {
            try {
                const response = await fetch(`https://yearbook.3pstudio.us/api/accounts/list-pdfs/`);
                if (!response.ok) { 
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                if (data?.upload_file_details?.length) {
                    if (data?.upload_file_details[0]['yearbook']?.length) setYearBook(data?.upload_file_details[0]['yearbook']);
                    if (data?.upload_file_details[1]['business_cards']?.length) setBusinessCard(data?.upload_file_details[1]['business_cards']);
                    if (data?.upload_file_details[2]['frames']?.length) setFrames(data?.upload_file_details[2]['frames']);
                }
                setIsLoaded(true);
            } catch (error) {
                console.log('Failed to load PDFs. Please try again.');
            }
        };

        fetchPdfs();
    }, []);

    const toggleAccordion = (section) => {
        setIsOpen((prevSection) => (prevSection === section ? '' : section));
    };

    return (
        <React.Fragment>
            <div className="w-full h-full space-y-4 overflow-y-auto">
                <div className="w-full border rounded-lg shadow-lg overflow-hidden">
                    <button
                        className="w-full text-left py-[10px] px-3 font-semibold text-gray-800 flex justify-between items-center hover:bg-blue-100 transition-all duration-200 ease-in-out sticky top-0 bg-white z-10"
                        onClick={() => toggleAccordion('yearbook')}
                    >
                        <span className="text-md text-[rgba(0,0,0,0.7)]">Yearbook</span>
                    </button>
                    {isOpen === 'yearbook' && (
                        <div className="p-1 bg-[rgba(0,0,0,0.05)] text-gray-700 transition-all duration-300 ease-in-out grid grid-cols-2 gap-1">
                            {yearBook.map((item, i) => {
                                return (
                                    <div className="bg-white p-2 shadow rounded cursor-pointer">
                                        <img
                                            key={i}
                                            className="w-32 h-32 object-contain rounded"
                                            src={item.upload_image_path}
                                            onClick={() => {
                                                localStorage.removeItem('editId');
                                                showPdfUrl({
                                                    id: item.id,
                                                    title_name: item.name,
                                                    pdfType: item.category,
                                                    pages: decodeURIComponent(item.pages)
                                                });
                                            }}
                                            alt={item.name}
                                            loading='lazy'
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {!isLoaded && (
                        <div className="bg-white w-full p-2 shadow rounded cursor-pointer">
                            {cardPlaceHolder(true)}
                        </div>
                    )}
                </div>

                {businessCard?.length ? <div className="w-full border rounded-lg shadow-lg overflow-hidden">
                    <button
                        className="w-full text-left py-[10px] px-3 font-semibold text-gray-800 flex justify-between items-center hover:bg-blue-100 transition-all duration-200 ease-in-out sticky top-0 bg-white z-10"
                        onClick={() => toggleAccordion('businessCard')}
                    >
                        <span className="text-md text-[rgba(0,0,0,0.7)]">BusinessCard</span>
                    </button>
                    {isOpen === 'businessCard' && (
                        <div className="p-1 bg-[rgba(0,0,0,0.05)] text-gray-700 transition-all duration-300 ease-in-out grid grid-cols-2 gap-1">
                            {businessCard.map((item, i) => {
                                return (
                                    <div className="bg-white p-2 shadow rounded cursor-pointer">
                                        <img
                                            key={i}
                                            className="w-32 h-32 object-contain rounded"
                                            src={item.upload_image_path}
                                            onClick={() => {
                                                localStorage.removeItem('editId');
                                                showPdfUrl({
                                                    id: item.id,
                                                    title_name: item.name,
                                                    pdfType: item.category,
                                                    pages: decodeURIComponent(item.pages)
                                                });
                                            }}
                                            alt={item.name}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div> : <></>}

                {frames?.length ? <div className="w-full border rounded-lg shadow-lg overflow-hidden">
                    <button
                        className="w-full text-left py-[10px] px-3 font-semibold text-gray-800 flex justify-between items-center hover:bg-blue-100 transition-all duration-200 ease-in-out sticky top-0 bg-white z-10"
                        onClick={() => toggleAccordion('frames')}
                    >
                        <span className="text-md text-[rgba(0,0,0,0.7)]">Frames</span>
                    </button>
                    {isOpen === 'frames' && (
                        <div className="p-1 bg-[rgba(0,0,0,0.05)] text-gray-700 transition-all duration-300 ease-in-out grid grid-cols-2 gap-1">
                            {frames.map((item, i) => {
                                return (
                                    <div className="bg-white p-2 shadow rounded cursor-pointer">
                                        <img
                                            key={i}
                                            className="w-32 h-32 object-contain rounded"
                                            src={item.upload_image_path}
                                            onClick={() => showPdfUrl({ id: item.id, title_name: item.name, pdfType: item.category, pages: decodeURIComponent(item.pages) })}
                                            alt={item.name}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div> : <></>}
            </div>
        </React.Fragment>

    );
}
