export const quicklink = [
    {
        id: 1,
        title: 'Home',
        path: '/'
    },
    {
        id: 2,
        title: 'About Us',
        path: '/about'
    },
    {
        id: 3,
        title: 'Contact Us',
        path: '/contact'
    }
]