import React, { useState } from "react";
import { quicklink } from "./quickLinks";
import { Link } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { createSubscribersAPI } from "../../serverApi/server";

const LinkandMail = () => {
  const [subsData, setSubsData] = useState({
    name: "",
    mail: "",
  });

  const [subscribed, setSubscribed] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubsData((prevData) => ({
      ...prevData,
      [name]:
        name === "name"
          ? value.replace(/\s{2,}/g, " ")
          : value.replace(/\s+/g, ""),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createSubscribersAPI(
        subsData.name,
        subsData.mail
      );
      if (response.data.client_status_code === 200) {
        setSubscribed(true);
      }
    } catch (error) {
      console.error('Error creating Subscribers:',error);
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
      <div className="sm:mb-5">
        <h5 className="text-2xl font-bold mb-4">Quick Links</h5>
        <div className="flex flex-col space-y-1 sm:space-y-2">
          {quicklink.map((link) => {
            return (
              <div key={link.id} className="flex items-center hover:underline">
                <MdKeyboardArrowDown className="-rotate-90 mr-1 text-black" />
                <Link key={link.id} to={link.path} className="text-gray-700">
                  {link.title}
                </Link>
              </div>
            );
          })}
        </div>
      </div>

      <div className="mb-5">
        <h5 className="text-2xl mb-4 font-bold">Newsletter</h5>
        { !subscribed && (<form onSubmit={handleSubmit}>
          <input
            value={subsData.name}
            name="name"
            onChange={handleChange}
            type="text"
            className="border-0 py-4 px-3 mb-4 w-full"
            placeholder="Your Name"
            required
          />
          <input
            value={subsData.mail}
            name="mail"
            onChange={handleChange}
            type="email"
            className="border-0 py-4 px-3 mb-4 w-full"
            placeholder="Your Email"
            required
          />
          <button className="bg-white hover:bg-orange-300 transition-all duration-200 py-3 w-full">
            Subscribe Now
          </button>
        </form>
        )}
        { subscribed && (
          <p className="bg-orange-300 py-3 text-center w-full">
          Subscribed
        </p>
        )}
      </div>
    </div>
  );
};

export default LinkandMail;
