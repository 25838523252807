import React, { useState, useEffect, useRef } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { FaFacebookF, FaLinkedinIn, FaPinterest, FaRegStar, FaShoppingCart, FaStar } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import DescriReview from '../components/ShopDetails/DescriReview';
import ProductSlide from '../components/ShopDetails/ProductSlide';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { GetCategoryImagesAPI, getCategoryMenuData, addCartDetails, paperTypeSelectedOption } from '../serverApi/server';
import { memorialFuneralMenus } from '../components/Helper/Helpers'
import { FaEye } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import Skeleton from './skeleton/Skeleton';


const ShopDetails = () => {
  const { id } = useParams();
  const [slides, setSlides] = useState([]);
  const [subTitle, setSubTitle] = useState('');
  const [formFields, setFormFields] = useState([]);
  const [errorResponse, setErrorResponse] = useState('')
  const navigate = useNavigate();
  const [additionalName, setAdditionalName] = useState();
  const [fieldValidation, setFieldValidation] = useState(false);
  const [fieldSize, setFieldSize] = useState('');
  const [quantityId, setQuantityId] = useState('');
  const [photosDetails, setPhotosDetails] = useState('');
  const [sizePaperType, setSizePaperType] = useState({});
  const [categoryPermissionFields, setCategoryPermissionFields] = useState();
  const [selectedFormFieldsOptions, setSelectedFormFieldsOptions] = useState({});
  const [products, setProducts] = useState('');
  const GetOurProductsCategoryImages = async (additionalName) => {
    var category_menu = memorialFuneralMenus[additionalName];
    try {
      const GetCategoryImagesApiResponse = await GetCategoryImagesAPI(additionalName);
      if (GetCategoryImagesApiResponse.status === 200 && GetCategoryImagesApiResponse.data && GetCategoryImagesApiResponse.data.category_serializer.length !== 0) {
        setProducts(GetCategoryImagesApiResponse.data.category_serializer.slice(0, 8));
      }
    } catch (error) {
      console.error('Error fetching Category Slides:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCategoryMenuData(id);
        if (data?.category_additional_name) {
          setAdditionalName(data?.category_additional_name);
          await GetOurProductsCategoryImages(data?.category_additional_name)
        }
        if (data?.category_menu_serilizer?.image_file) {
          setSlides([data.category_menu_serilizer.image_file]);
        }
        if (data?.category_name) {
          setSubTitle(data.category_name);
        }
        if (data?.form_fields_serializer) {
          setFormFields(data.form_fields_serializer);
          const photosDataDetails = Object.values(data.form_fields_serializer).find(option => option.additional_name === "photos");
          if (photosDataDetails) {
            setPhotosDetails(photosDataDetails)
          }
        }
        if (data?.category_permission_fields) {
          setCategoryPermissionFields(data.category_permission_fields);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [id]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const [formData, setFormData] = useState({});
  const [totalPrice, setTotalPrice] = useState(0.00);
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [permissionFieldId, setPermissionFieldId] = useState('')
  const paperTypeSelectedOptionAPI = async (selectedOption) => {
    try {
      const paperTypeSelectedOptionAPIResponse = await paperTypeSelectedOption(selectedOption);
      const serializer = paperTypeSelectedOptionAPIResponse.data.paper_type_field_amount_serializer;
      const updatedFormFields = formFields.map((field) => {
        if (field.additional_name === "quantity" || field.additional_name === "Quantity") {
            const updatedOptions = field.options.map((option) => {
                const match = serializer.find((item) => item.option === option.id);
                if (match) {
                    if (match.option == quantityId) {
                      const quantityOption = Object.values(selectedOptions).find(option => option.id === match.option);
                      setSelectedOptions((prevOptions) => {
                          const updatedOptions = { ...prevOptions };
                          const matchKey = Object.keys(updatedOptions).find(
                              (key) => updatedOptions[key].id === match.option
                          );
                          if (matchKey) {
							  setPermissionFieldId(match.id)
                              updatedOptions[matchKey] = {
                                  ...updatedOptions[matchKey],
                                  amount: match.amount,
                              };
                          }
                          return updatedOptions;
                      });
                    }
                    return {
                        ...option,
                        amount: match.amount,
						permissionFieldId: match.id,
                    };
                }
                return option;
            });
            return {
                ...field,
                options: updatedOptions,
            };
        }
        return field;
    });
    setFormFields(updatedFormFields); 
    } catch (error) {
      console.error('Error fetching sub-menus:', error);
    }
  };
  const handleChange = (e, fieldId, fieldName) => {
    const { name, value } = e.target;
    const selectedId = e.target.options[e.target.selectedIndex].getAttribute('data-id');
    if (name === 'Size' || name === 'Paper Type') {
      setSizePaperType((prev) => ({
        ...prev,        
        [name]: selectedId,
      }));
    }
    const field = formFields.find(f => f.id === fieldId);
    const selectedOption = field?.options?.find(option => option.name === value);
    if (selectedOption) {
      selectedOption.calculation_status = field.calculation_status || false;
    }

    if (fieldName && fieldName.field_name === 'Quantity') {
	  console.log('selectedOption', selectedOption)
	  if (selectedOption && selectedOption.permissionFieldId) {
	  	setPermissionFieldId(selectedOption.permissionFieldId)
	  }
      setFieldSize(value)
      setQuantityId(selectedOption.id)
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    if (categoryPermissionFields.includes(fieldId)) {
      setSelectedFormFieldsOptions(prev => ({
        ...prev,                
        [name]: selectedOption,
      }));
    }
    setSelectedOptions(prevSelectedOptions => ({
      ...prevSelectedOptions,
      [fieldId]: selectedOption,
    }));
    setSelectedIds(prevIds => {
      if (!prevIds.includes(fieldId)) {
        return [...prevIds, fieldId];
      }
      return prevIds;
    });
  };

  useEffect(() => {
    let categoryPermissionFieldsList = [];
    if (typeof categoryPermissionFields === 'string') {
      try {
        categoryPermissionFieldsList = JSON.parse(categoryPermissionFields);
      } catch (e) {
        console.error('Error parsing categoryPermissionFields:', e);
      }
    } else if (Array.isArray(categoryPermissionFields)) {
      categoryPermissionFieldsList = categoryPermissionFields;
    }
    if (categoryPermissionFieldsList.length && Object.keys(selectedFormFieldsOptions).length === categoryPermissionFieldsList.length) {
      paperTypeSelectedOptionAPI(selectedFormFieldsOptions);
    }
  }, [selectedFormFieldsOptions, categoryPermissionFields]);

  useEffect(() => {
    if (selectedIds.length === formFields.length) {
      const totalAmount = Object.values(selectedOptions).reduce((total, option) => {
        var optionPrice = option.amount
        if (option.calculation_status === true) {
          optionPrice = option.amount * fieldSize 
        }
        return total + (parseFloat(optionPrice) || 0);
      }, 0);
      const formattedTotal = parseFloat(totalAmount).toFixed(2);
      setTotalPrice(formattedTotal)
    }
  }, [selectedIds, selectedOptions, fieldSize]);

  const addTemporaryCartDetails = async (id, selectedOptions, permissionFieldId) => {
    const addCartDetailsAPIResponse = await addCartDetails(id, selectedOptions, permissionFieldId);
    if (addCartDetailsAPIResponse.data.client_status_code === 200) {
      return addCartDetailsAPIResponse.data.new_cart_id
    } else {
      setErrorResponse(addCartDetailsAPIResponse.data.message || 'Unable to add the cart.');
    }
  }

  const addTocart = async () => {
    if (formFields.length !== Object.keys(selectedOptions).length) {
      setFieldValidation(true)
      return;
    }
    setFieldValidation(false)
    const newCartId = await addTemporaryCartDetails(id, selectedOptions, permissionFieldId)
    navigate('/cart', { state: { newCartId: newCartId } });
  };

  const [showPriceTable, setShowPriceTable] = useState(false);
  const [showPhotosPriceTable, setshowPhotosPriceTable] = useState(false);

  const dropdownRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowPriceTable(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPriceTable]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setshowPhotosPriceTable(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPhotosPriceTable]);


  const lensRef = useRef(null);
  const magnifiedImgRef = useRef(null);
  const productImgRef = useRef(null);
  const [lensStyles, setLensStyles] = useState({});
  const [magnifiedStyles, setMagnifiedStyles] = useState({});
  const [isActive, setIsActive] = useState(false);

  const moveLens = (e) => {
    const lens = lensRef.current;
    const productImg = productImgRef.current;
    const magnifiedImg = magnifiedImgRef.current;
    const productImgRect = productImg.getBoundingClientRect();
    const lensWidth = lens.offsetWidth;
    const lensHeight = lens.offsetHeight;
    let x = e.clientX - productImgRect.left - lensWidth / 2;
    let y = e.clientY - productImgRect.top - lensHeight / 2;
    const maxXPos = productImgRect.width - lensWidth;
    const maxYPos = productImgRect.height - lensHeight;
    if (x > maxXPos) x = maxXPos;
    if (x < 0) x = 0;
    if (y > maxYPos) y = maxYPos;
    if (y < 0) y = 0;

    setLensStyles({ top: `${y}px`, left: `${x}px` });

    const cx = magnifiedImg.offsetWidth / lensWidth;
    const cy = magnifiedImg.offsetHeight / lensHeight;

    setMagnifiedStyles({
      backgroundImage: `url('${slides[currentIndex]}')`,
      backgroundPosition: `-${x * cx}px -${y * cy}px`,
      backgroundSize: `${productImgRect.width * cx}px ${productImgRect.height * cy}px`,
    });
    setIsActive(true);
  };

  const leaveLens = () => {
    setIsActive(false);
  };

  const productID = (productId) => {
    navigate(`/shopdetails/${productId}`);
  }

  const [selectedDesign, setSelectedDesign] = useState('');

  const handleCustomize = (e) => {
    setSelectedDesign(e.target.value);
  };

  const CustomizeDesign = () => {
    navigate('/template')
  }

  const [loadingTime, setLoadingTime] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingTime(false);
    }, 8000);

    return () => clearTimeout(timer);
  }, [])

  return (
    <div>
      <section className='flex flex-col justify-start items-center md:flex-row md:items-start md:justify-center gap-5 md:gap-0 py-5 sm:py-8 md:py-10 w-full'>
        <div className='w-full md:w-1/2 px-5 flex flex-col items-center group'>
          <div className='p-3 lg:p-5 border'>
            {slides.length ? (
              <div
              className='relative w-52 sm:w-64 md:w-72 lg:w-80 h-auto overflow-hidden '
              ref={productImgRef}
              onMouseMove={moveLens}
              onMouseLeave={leaveLens}
            >
              <img
                src={slides[currentIndex]}
                alt="product"
                className='w-full h-auto duration-500 transition-transform'
              />
              <div
                className={`absolute top-0 left-0 hidden md:block border rounded w-[150px] h-[100px] bg-[#FF980030] ${isActive ? "opacity-100" : "opacity-0"}`}
                ref={lensRef}
                style={lensStyles}
              >

              </div>
            </div>
            ) : (
              <div className='w-52 sm:w-64 md:w-72 lg:w-80 h-48 sm:h-64 md:h-80 lg:h-96'>
                <Skeleton width='100%' height='100%'/>
              </div>
            )}
          </div>
          {slides.length > 1 && (
            <div
              onClick={prevSlide}
              className='absolute top-[50%] -translate-x-0 -translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'
            >
              <BsChevronCompactLeft size={30} />
            </div>
          )}
          {slides.length > 1 && (
            <div
              onClick={nextSlide}
              className='absolute top-[50%] -translate-x-0 -translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'
            >
              <BsChevronCompactRight size={30} />
            </div>
          )}
        </div>

        <div className='relative w-full md:w-1/2 lg:w-3/5 flex items-start justify-center md:justify-start md:mr-4 px-5 md:pb-16 sm:px-10 md:px-0'>
          <div className=' flex flex-col items-center justify-center sm:pl-[10%] md:pl-0 w-full'>
            <div className='flex w-full flex-col gap-3'>
              <h1 className='text-2xl sm:text-3xl font-semibold'>
                {subTitle ? (
                  subTitle
                ) : (
                  <Skeleton width='200px' height='40px'/>
                )}
              </h1>
              <div className='flex text-orange-400 text-[15px] gap-1'><FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar /></div>
            </div>
            <div className="w-full pt-4">
              <form>
                {formFields.length ? formFields && formFields.map((field) => (
                  <div className="flex items-center justify-start w-full mb-4" key={field.id}>
                    <label
                      htmlFor={field.field_name}
                      className="block w-40 sm:w-44 md:w-48 text-left text-gray-500 font-semibold"
                    >
                      {field.field_name}:
                    </label>
                    <div className='flex gap-3 items-center justify-start w-64'>
                      <select
                        name={field.field_name}
                        value={formData[field.field_name] || ''}
                        onChange={(e) => handleChange(e, field.id, field)}
                        className="block w-full p-2 border border-gray-300 rounded bg-white shadow-sm hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-400 duration-200"
                        style={{
                          maxHeight: '150px',
                          overflowY: 'auto',
                        }}
                      >
                        <option value="" disabled>
                          Choose an option
                        </option>
                        {field.options &&
                          (field.field_name === 'Quantity' || field.field_name === 'Photos'
                            ? field.options
                                .slice() 
                                .sort((a, b) => Number(a.name) - Number(b.name))
                                .map((option) => (
                                  <option
                                    key={option.id}
                                    className="py-2 px-4 hover:bg-orange-100"
                                    value={option.name}
                                    data-id={option.id}
                                  >
                                    {option.name}
                                  </option>
                                ))
                            : field.options.map((option) => (
                                <option
                                  key={option.id}
                                  className="py-2 px-4 hover:bg-orange-100"
                                  value={option.name}
                                  data-id={option.id}
                                >
                                  {option.name}
                                </option>
                        )))}
                      </select>
                      {field.field_name === 'Quantity' && (
                        <div className='relative' ref={dropdownRef}>
                          <span
                            onClick={() => setShowPriceTable(!showPriceTable)}
                            className="text-blue-600 hover:text-blue-900 cursor-pointer duration-200 underline-offset-1 underline whitespace-nowrap"
                          >
                            Price Table
                          </span>
                          {showPriceTable && (
                            <div className="mt-2 px-4 py-2 text-sm md:text-base bg-white w-56 sm:w-64 lg:w-80 h-40 overflow-y-auto border border-gray-300 absolute top-5 right-0 rounded-md shadow-lg"
                            style={{ zIndex: 11 }}>
                              {field.field_name === 'Quantity' && field.options
                                .slice()
                                .sort((a, b) => Number(a.name) - Number(b.name))
                                .map((option) => (
                                  <div key={option.id} className="flex justify-between gap-1">
                                    <span>{option.name} cards</span>
                                    <span className="text-red-500">${option.amount}</span>
                                  </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                      {field.field_name === 'Photos' && (
                        <div className='relative' ref={dropdownRef}>
                          <span
                            onClick={() => setshowPhotosPriceTable(!showPhotosPriceTable)}
                            className="text-blue-600 hover:text-blue-900 cursor-pointer duration-200 underline-offset-1 underline whitespace-nowrap"
                          >
                            Price Table
                          </span>

                          {showPhotosPriceTable && (
                            <div className="mt-2 px-4 py-2 text-sm md:text-base bg-white w-56 sm:w-64 lg:w-80 h-40 overflow-y-auto border border-gray-300 absolute top-5 right-0 rounded-md shadow-lg z-10">
                              {field.field_name === 'Photos' && field.options
                                .slice()
                                .sort((a, b) => Number(a.name) - Number(b.name))
                                .map((option) => (
                                  <div key={option.id} className="flex justify-between gap-1">
                                    <span>{option.name} Photos</span>
                                    <span className="text-red-500">${option.amount}</span>
                                  </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )) : (
                  <div className='flex flex-col gap-2'>

                    <div className="flex items-center justify-start gap-8 w-full mb-4">
                      <div
                        className="w-20 sm:w-28 md:w-36 text-left flex items-center text-gray-500 font-semibold"
                      >
                        <Skeleton width='80%' height='25px'/>
                      </div>
                      <div className='flex gap-3 items-center justify-start w-64'>
                        <Skeleton width='100%' height='35px'/>
                      </div>
                    </div>
                    <div className="flex items-center justify-start gap-8 w-full mb-4">
                      <div
                        className="w-20 sm:w-28 md:w-36 text-left flex items-center text-gray-500 font-semibold"
                      >
                        <Skeleton width='60%' height='25px'/>
                      </div>
                      <div className='flex gap-3 items-center justify-start w-64'>
                        <Skeleton width='100%' height='35px'/>
                      </div>
                    </div>
                    <div className="flex items-center justify-start gap-8 w-full mb-4">
                      <div
                        className="w-20 sm:w-28 md:w-36 text-left flex items-center text-gray-500 font-semibold"
                      >
                        <Skeleton width='100%' height='25px'/>
                      </div>
                      <div className='flex gap-3 items-center justify-start w-64'>
                        <Skeleton width='100%' height='35px'/>
                      </div>
                    </div>

                  </div>
                )}
                {fieldValidation && (
                  <div className="flex items-center mb-4">
                    <p className="text-red-500 text-sm">Please choose the all fields.</p>
                  </div>
                )}
                <p className="text-lg font-bold text-gray-700 mb-4">
                  Total Price: $<span>{totalPrice}</span>
                </p>
              </form>
              <button
                onClick={addTocart}
                className="inline-flex items-center gap-2 bg-orange-300 text-black px-5 py-2 duration-200 hover:bg-orange-400"
              >
                <FaShoppingCart /> Add To Cart
              </button>
              <div className='flex items-center pt-3 sm:pt-4'>
                <p className='font-light'>Share on:</p>
                <div className='flex pl-2 items-center gap-x-4'>
                  <a href="#"><FaFacebookF /></a>
                  <a href="#"><FaXTwitter /></a>
                  <a href="#"><FaLinkedinIn /></a>
                  <a href="#"><FaPinterest /></a>
                </div>
              </div>
              <div className='flex flex-col'>
                <h2 className='text-xl sm:text-2xl mt-4 mb-1 underline text-neutral-700 bebas-neue-regular'>Have a Question? We're Here to Help!</h2>
                <p className='text-neutral-500 text-sm sm:text-base w-full sm:w-11/12 xl:w-3/4'>
                  If you can't find what you're looking for, our team is available from 10:00 AM to 7:00 PM EST to assist you. Feel free to reach out via email at orders@stalbansprinting.com or give us a call at 718-528-5100.
                </p>
              </div>
            </div>
          </div>
          <div className={`absolute w-full h-full hidden top-0 left-0 ${isActive ? "border-2 bg-white md:block" : "border-none bg-transparent"}`}>
            <div
              className={`absolute w-full h-full top-0 left-0 ${isActive ? "opacity-100 scale-100 " : "opacity-0 scale-50"}`}
              ref={magnifiedImgRef}
              style={magnifiedStyles}
            >
            </div>
          </div>
        </div>
      </section>
      <DescriReview />
      <div className='px-5 md:px-10 mt-7 sm:mt-8 lg:mt-10 mb-8 sm:mb-10 md:mb-14'>
        <div className='mb-5 sm:mb-7 md:mb-8 flex gap-3 items-center justify-center'>
          <p className='w-6 sm:w-10 h-[1.5px] bg-black'></p>
          <h1 className='text-2xl md:text-[26px] lg:text-3xl xl:text-4xl text-center font-semibold'>You May Also Like</h1>
          <p className='w-6 sm:w-10 h-[1.5px] bg-black'></p>
        </div>
        <div>
          <Swiper
            spaceBetween={10}
            centeredSlides={false}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            slide
            navigation={false}
            modules={[Autoplay]}
            className="mySwiper"
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 6,
              },
            }}
          >
            {products && products.map((product) => (
              <SwiperSlide key={product.id}>
                <div onClick={() => productID(product.id)} className="relative flex flex-col items-center justify-center border cursor-pointer">
                  <div
                    className='w-auto h-40 md:h-52 border relative overflow-hidden bg-transparent'
                  >
                    <img
                      className='w-full h-full transition-transform duration-200 ease-in-out hover:scale-125'
                      src={product.image_file} alt={product.image_name}
                    />
                  </div>
                  <div className='mt-5 border-t-2 w-full'> 
                    <div className="flex py-3 px-1 sm:px-2 justify-center items-center text-gray-700 gap-1 bg-gray-100 border">
                      <FaEye />
                      <button>
                        View Detail
                      </button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default ShopDetails;