import React, { useState, useEffect } from 'react'
import SideBar from './SideBar'
import Header from './Header'
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { useLocation } from 'react-router-dom';
import { FaEdit } from "react-icons/fa";
import { getCategorySubMenusAPI, getSubmenuFields, updateCategorySubmenuPrice } from '../../serverApi/server'
import { option } from 'framer-motion/client';

const FieldPricing = () => {
  const location = useLocation();
  const headerMenus = location.state || [];
  const [categoryList, setCategoryList] = useState([])
  const [subMenuList, setSubMenuList] = useState('')
  const [loadingButton, setLoadingButton] = useState(null);
  const [totalFieldList, setTotalFieldList] = useState([])
  const [prices, setPrices] = useState({});

  const [selectedCategory, setSelectedCategory] = useState(
    headerMenus.find((menu) => menu.additional_name === 'memorial_funeral')?.id || ''
  );

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleSubMenuListChange = (e) => {
    setSubMenuList(e.target.value);
  }

  useEffect(() => {
    const fetchCategorySubMenus = async () => {
      try {
        const getCategorySubMenusAPIResponse = await getCategorySubMenusAPI(selectedCategory);
        setCategoryList(getCategorySubMenusAPIResponse.data.categories_serializer)
        setSubMenuList(getCategorySubMenusAPIResponse.data.categories_serializer.find((menu) => menu.additional_name === 'bookmarks')?.id || '')
      } catch (error) {
        console.error('Error fetching sub-menus:', error);
      }
    };
    if (selectedCategory) {
      fetchCategorySubMenus();
    }
  }, [selectedCategory]);

  useEffect(()=>{
    const fetchGetSubmenuFields = async () => {
      try {
        const getGetSubmenuFieldsAPIResponse = await getSubmenuFields(subMenuList);
        setTotalFieldList(getGetSubmenuFieldsAPIResponse.data.form_fields_serializer)
      } catch (error) {
        console.error('Error fetching sub-menus:', error);
      }
    };
    if (subMenuList) {
      fetchGetSubmenuFields();
    }
  }, [subMenuList])

  useEffect(() => {
    const initialPrices = {};
    totalFieldList.forEach(category => {
        category.options.forEach(option => {
        initialPrices[option.id] = option.amount || '';
      })
    });
    setPrices(initialPrices);
  }, [totalFieldList]);

  const handlePriceChange = (e, categoryId) => {
    setPrices(prevPrices => ({
      ...prevPrices,
      [categoryId]: e.target.value,
    }));
  };

  const updateCategoryPrice = async (categoryId, category_sub_menu) => {
    setLoadingButton(categoryId);
    const price = prices[categoryId];
    try {
      const updateCategorySubmenuPriceResponse = await updateCategorySubmenuPrice(categoryId, price, category_sub_menu);
    } catch (error) {
      console.error('Error fetching sub-menus:', error);
    } finally {
      setTimeout(() => {
        setLoadingButton(null);
      }, 2000);
    }
  }

  return (
    <div>
      <div className='flex' style={{ position: 'fixed' }}>
        <SideBar />
      </div>
      <div style={{marginLeft: '260px'}}>
        <Header />
        <div className="px-5 py-5 bg-[#F5F7FD]">
          <div className="text-blue-950 flex items-center gap-6">
            <h1 className="pr-6 text-2xl font-medium border-r">
              Admin Users
            </h1>
            <div className="flex text-sm items-center gap-3">
              <button>
                <SlHome />
              </button>
              <SlArrowRight />
              <button>
                Tables
              </button>
              <SlArrowRight />
              <button>
                Datatables
              </button>
            </div>
          </div>
          <div className='bg-white mt-10 px-3 py-4 rounded-lg'>
            <div className='flex items-center gap-3 text-sm'>
              <p className='text-neutral-500'>Filter by Role:</p>
              <div className='flex items-center gap-2'>
                <select className='focus:outline-none focus:border-blue-300 border rounded px-1 py-1'
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                  >
                    {headerMenus.map((menu) => (
                      <option key={menu.id} value={menu.id}>
                        {menu.menu}
                      </option>
                    ))}
                </select>
                <select className='focus:outline-none focus:border-blue-300 border rounded px-1 py-1'
                  onChange={handleSubMenuListChange}
                  value={subMenuList}
                >
                  {categoryList && categoryList.map((menu) => (
                    <option key={menu.id} value={menu.id}>
                      {menu.category}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white mt-8" style={{ borderCollapse: 'separate', borderSpacing: '2px' }}>
                <thead>
                  <tr>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">CATEGORY NAME</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">FIELDS</th>
                  </tr>
                </thead>
                <tbody>
                  {totalFieldList.map((mainField) => (
                    <tr>
                      <td className="px-4 py-2 border border-[#48ABF7]">{mainField.field_name}</td>
                      <td className="px-4 py-2 border border-[#48ABF7]">
                        <table className="w-full bg-neutral-100">
                          <tbody>
                            {mainField.options.map((options, index) => (
                              <tr key={index} className='hover:bg-neutral-200 duration-100'>
                                <td className='px-2 py-2 border border-[#48ABF7]'>{options.name}</td>
                                <td className='px-2 py-2 border border-[#48ABF7]'>
                                  <div style={{display: 'flex'}}>
                                    <span style={{ marginTop: '6px', fontSize: '20px', marginRight: '5px' }}>$</span>
                                    <input type="text" className="form-input w-full border rounded-md p-2" placeholder="Enter value" 
                                    value={prices[options.id] || ''}
                                    onChange={(e) => handlePriceChange(e, options.id)}/>
                                  </div>
                                </td>
                                <td className="px-4 py-2 border border-[#48ABF7]">
                                  <button className={`bg-blue-500 text-white py-1 px-3 rounded-full flex items-center update-btn ${
                                    loadingButton === options.id ? 'loading' : ''
                                  }`} title="Update"
                                  onClick={() => updateCategoryPrice(options.id, 'sub_menu_field')}
                                  disabled={loadingButton === options.id}
                                  >
                                  <FaEdit />
                                  {loadingButton === options.id ? 'Updating...' : 'Update'}
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FieldPricing
