import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { useLocation } from 'react-router-dom';
import { FaEdit } from "react-icons/fa";
import { getCategorySubMenusAPI, updateCategorySubmenuPrice } from '../../serverApi/server'


const CategoryPricing = () => {
  const location = useLocation();
  const headerMenus = location.state || [];
  const [prices, setPrices] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(null);
  const [categoryList, setCategoryList] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(
    headerMenus.find((menu) => menu.additional_name === 'memorial_funeral')?.id || ''
  );
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  useEffect(() => {
    const fetchCategorySubMenus = async () => {
      try {
        const getCategorySubMenusAPIResponse = await getCategorySubMenusAPI(selectedCategory);
        setCategoryList(getCategorySubMenusAPIResponse.data.categories_serializer)
      } catch (error) {
        console.error('Error fetching sub-menus:', error);
      }
    };
    if (selectedCategory) {
      fetchCategorySubMenus();
    }
  }, [selectedCategory]);

  useEffect(() => {
    const initialPrices = {};
    categoryList.forEach(category => {
      initialPrices[category.id] = category.amount || '';
    });
    setPrices(initialPrices);
  }, [categoryList]);


  const handlePriceChange = (e, categoryId) => {
    setPrices(prevPrices => ({
      ...prevPrices,
      [categoryId]: e.target.value,
    }));
  };

  const updateCategoryPrice = async (categoryId, category_sub_menu) => {
    setLoadingButton(categoryId);
    const price = prices[categoryId];
    try {
      const updateCategorySubmenuPriceResponse = await updateCategorySubmenuPrice(categoryId, price, category_sub_menu);
    } catch (error) {
      console.error('Error fetching sub-menus:', error);
    } finally {
     
      setTimeout(() => {
        setLoadingButton(null);
      }, 2000);
    }
  }

  return (
    <div>
      <div className="flex" style={{ position: 'fixed' }}>
        <SideBar />
      </div>
      <div style={{marginLeft: '260px'}}>
        <Header />
        <div className="px-5 py-5 bg-[#F5F7FD]">
          <div className="text-blue-950 flex items-center gap-6">
            <h1 className="pr-6 text-2xl font-medium border-r">
              Admin Users
            </h1>
            <div className="flex text-sm items-center gap-3">
              <button>
                <SlHome />
              </button>
              <SlArrowRight />
              <button>
                Tables
              </button>
              <SlArrowRight />
              <button>
                Datatables
              </button>
            </div>
          </div>

          <div className='bg-white mt-10 px-3 py-4 rounded-lg'>
            <div className='flex items-center gap-3 text-sm'>
              <p className='text-neutral-500'>Filter by Role:</p>
              <div className='flex items-center gap-2'>
                <select className='focus:outline-none focus:border-blue-300 border rounded px-1 py-1'
                  onChange={handleCategoryChange}
                  value={selectedCategory}
                >
                  {headerMenus.map((menu) => (
                    <option key={menu.id} value={menu.id}>
                      {menu.menu}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white mt-8" style={{ borderCollapse: 'separate', borderSpacing: '2px' }}>
                <thead>
                  <tr>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">SUB-CATEGORY NAME</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">PRICE</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {categoryList.map((category) => (
                    <tr key={category.id}>
                      <td className="px-4 py-2 font-normal border border-[#48ABF7]">{category.category}</td>
                      <td className="px-4 py-2 border border-[#48ABF7]">
                        <table className="w-full">
                          <tbody>
                            <tr>
                              <td className="px-2 py-2">
                                <div style={{display: 'flex'}}>
                                  <span style={{ marginTop: '6px', fontSize: '20px', marginRight: '5px' }}>$</span>
                                  <input
                                    type="text"
                                    className="form-input w-full border rounded-md p-2"
                                    placeholder="Enter price"
                                    value={prices[category.id] || ''}
                                    onChange={(e) => handlePriceChange(e, category.id)}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      {/* <td className="px-4 py-2 border border-[#48ABF7]">
                        <button className="bg-blue-500 text-white py-1 px-3 rounded-full flex items-center update-btn" title="Update"
                          onClick={() => updateCategoryPrice(category.id, 'category_sub_menu')}
                          style={{ pointerEvents: loading ? 'none' : 'auto' }}>
                            {loading ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> // Show a spinner when loading
                            ) : (
                              <FaEdit />
                            )}
                            {loading ? 'Updating...' : 'Update'}
                        </button>
                      </td> */}

                      <td className="px-4 py-2 border border-[#48ABF7]">
                        <button
                          className={`bg-blue-500 text-white py-1 px-3 rounded-full flex items-center update-btn ${
                            loadingButton === category.id ? 'loading' : ''
                          }`}
                          title="Update"
                          onClick={() => updateCategoryPrice(category.id, 'category_sub_menu')}
                          disabled={loadingButton === category.id} // Disable the button if it's loading
                        >
                          <FaEdit />
                          {loadingButton === category.id ? 'Updating...' : 'Update'}
                        </button>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryPricing;
