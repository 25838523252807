export const quicklink = [
    {
        id: 1,
        title: 'Home',
        path: '/'
    },
    {
        id: 2,
        title: 'Shop',
        path: '/shop'
    },
    {
        id: 3,
        title: 'Shop Details',
        path: '/shopdetails'
    },
    {
        id: 4,
        title: 'About Us',
        path: '/about'
    },
    {
        id: 5,
        title: 'Contact Us',
        path: '/contact'
    }
]