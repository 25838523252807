import React, { useEffect } from 'react';
import { getPdfPage } from '../helper/Helpers';
import _ from 'lodash';

export default function CreateComponent({ info, pdfPages, pageIndex, setElementStyles, layout, selectedElement, drawState, isSectionActivated, reset, updateDisableStatus, setIsElementUpdated, handlePdfPageController, setIsDrawActivated, handleMouseMove }) {

    var html = '';

    useEffect(() => {

        const renderPage = async () => {
            const pageElement = document.querySelector(`.pdfPage-${pageIndex || 0}`);
            if (pageElement) {
                const page = await getPdfPage(pageIndex, pdfPages);
                pageElement.innerHTML = page;
            }
        };

        if (pdfPages) {
            renderPage();
        }
    }, [pdfPages, pageIndex]);

    useEffect(() => {
        if (selectedElement && setElementStyles) {

            selectedElement.style.display = 'flex';
            if (setElementStyles?.rotate) {
                selectedElement.style.transform = `rotate(${setElementStyles?.rotate}deg)`;
            }
            selectedElement.style.opacity = setElementStyles?.opacity;

            if (setElementStyles?.fontSize) {
                // selectedElement.style.fontSize = `${setElementStyles?.fontSize}px`;
                const computedFontSize = window.getComputedStyle(selectedElement).fontSize.replace('px', '');
                const currentWidth = selectedElement?.offsetWidth;
                const fontSize = setElementStyles?.fontSize;
                const multiplier = currentWidth / computedFontSize;
                selectedElement.style.fontSize = `${fontSize}px`;

                const aspectRatio = selectedElement.offsetWidth / selectedElement.offsetHeight;
                selectedElement.style.width = `${fontSize * multiplier}px`;
                selectedElement.style.height = `${(fontSize * multiplier) / aspectRatio}px`;
            }
            selectedElement.style.fontFamily = setElementStyles.fontFamily;
            selectedElement.style.background = setElementStyles.backgroundColor;
            selectedElement.style.color = setElementStyles.color;
            selectedElement.style.fill = setElementStyles.color;
            selectedElement.style.fontWeight = setElementStyles.bold;
            selectedElement.style.fontStyle = setElementStyles.italic ? `italic` : 'normal';
            selectedElement.style.textDecoration = setElementStyles.underline ? `underline` : 'none';
            selectedElement.style.justifyContent = setElementStyles.left ? 'flex-start' : setElementStyles.right ? 'flex-end' : setElementStyles.middle ? 'center' : 'default';
            selectedElement.style.alignItems = setElementStyles.top ? 'flex-start' : setElementStyles.bottom ? 'flex-end' : setElementStyles.middleVertical ? 'center' : 'default';
            if (selectedElement.tagName === "IMG" || selectedElement.firstChild.tagName === "IMG") {
                selectedElement.style.filter =
                    ` 
                ${setElementStyles?.brightness ? `brightness(${setElementStyles.brightness}%)` : 'brightness(100%)'}
                ${setElementStyles?.contrast ? `contrast(${setElementStyles.contrast}%)` : 'contrast(100%)'}
                ${setElementStyles?.grayscale ? `grayscale(${setElementStyles.grayscale}%)` : `grayscale(0%)`}
                ${setElementStyles?.invert ? `invert(${setElementStyles.invert}%)` : `invert(0%)`}
                ${setElementStyles?.saturate ? `saturate(${setElementStyles.saturate})` : `saturate(1)`}
                ${setElementStyles?.sepia ? `sepia(${setElementStyles.sepia}%)` : `sepia(0%)`}
                ${setElementStyles?.blur ? `blur(${setElementStyles.blur}px)` : 'blur(0)'}
                `.trim();
            }
        }
    }, [selectedElement, setElementStyles]);

    useEffect(() => {
        const isDrawActive = sessionStorage.getItem('currentPreview');
        if (drawState && isDrawActive) {
            setIsDrawActivated(true);
        } else {
            setIsDrawActivated(false);
        }

        const handleClickEvent = (e) => {
            if (sessionStorage.getItem('currentPreview') === 'draw') {
                const currentPage = e.target.closest('.previewArea');
                if (currentPage) {
                    const screenWidth = window.innerWidth;
                    const screenHeight = window.innerHeight;
                    const canvas = document.createElement('canvas');
                    canvas.style.position = 'absolute';
                    canvas.style.top = '0';
                    canvas.style.left = '0';
                    canvas.width = screenWidth;
                    canvas.height = screenHeight;
                    canvas.style.zIndex = '100';
                    canvas.style.background = 'transparent';
                    currentPage.style.cursor = 'crosshair';
                    currentPage.appendChild(canvas);

                    const ctx = canvas.getContext('2d');
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = drawState?.selectedColor || 'red';

                    let isDrawing = false;
                    let startX = 0, startY = 0;
                    let minX = screenWidth, minY = screenHeight, maxX = 0, maxY = 0;

                    const updateBoundingBox = (x, y) => {
                        if (x < minX) minX = x;
                        if (y < minY) minY = y;
                        if (x > maxX) maxX = x;
                        if (y > maxY) maxY = y;
                    };

                    const startDrawing = (e) => {
                        isDrawing = true;
                        const rect = canvas.getBoundingClientRect();
                        startX = e.clientX - rect.left;
                        startY = e.clientY - rect.top;
                        ctx.beginPath();
                        ctx.moveTo(startX, startY);
                        updateBoundingBox(startX, startY);
                    };

                    const draw = (e) => {
                        if (!isDrawing) return;
                        const rect = canvas.getBoundingClientRect();
                        const x = e.clientX - rect.left;
                        const y = e.clientY - rect.top;
                        ctx.lineTo(x, y);
                        ctx.stroke();
                        updateBoundingBox(x, y);
                        startX = x;
                        startY = y;
                    };

                    const stopDrawing = (e) => {
                        if (isDrawing) {
                            isDrawing = false;
                            const rect = canvas.getBoundingClientRect();
                            const x = e.clientX - rect.left;
                            const y = e.clientY - rect.top;
                            ctx.lineTo(x, y);
                            ctx.stroke();
                            ctx.closePath();

                            updateBoundingBox(x, y);

                            const drawnWidth = maxX - minX;
                            const drawnHeight = maxY - minY;

                            if (drawnWidth > 0 && drawnHeight > 0) {
                                const resizedCanvas = document.createElement('canvas');
                                resizedCanvas.width = (drawnWidth + 15);
                                resizedCanvas.height = (drawnHeight + 15);
                                resizedCanvas.style.zIndex = '100';
                                const resizedCtx = resizedCanvas.getContext('2d');

                                resizedCtx.drawImage(canvas, minX, minY, drawnWidth, drawnHeight, 0, 0, drawnWidth, drawnHeight);

                                const imageDataURL = resizedCanvas.toDataURL('image/png');
                                const imgElement = document.createElement('img');
                                imgElement.src = imageDataURL;

                                imgElement.style.position = 'absolute';
                                imgElement.style.pointerEvents = 'none';
                                imgElement.style.width = '100%';
                                imgElement.style.height = '100%';

                                currentPage.removeChild(canvas);
                                resizedCanvas.style.position = 'absolute';
                                resizedCanvas.style.top = `${minY}px`;
                                resizedCanvas.style.left = `${minX}px`;
                                currentPage.style.cursor = 'default';

                                const divElement = document.createElement('div');
                                divElement.style.position = 'absolute';
                                divElement.style.top = `${minY}px`;
                                divElement.style.left = `${minX}px`;
                                divElement.style.width = `${resizedCanvas.width}px`;
                                divElement.style.height = `${resizedCanvas.height}px`;
                                divElement.style.zIndex = '101';
                                divElement.classList.add('box');
                                divElement.appendChild(imgElement);

                                currentPage.appendChild(divElement);
                                setIsElementUpdated();
                                reset();
                            }
                        }
                    };

                    canvas.addEventListener('mousedown', startDrawing);
                    canvas.addEventListener('mousemove', draw);
                    canvas.addEventListener('mouseup', stopDrawing);
                    canvas.addEventListener('mouseleave', stopDrawing);
                }
            }
        };

        window.addEventListener('click', handleClickEvent);

        return (() => {
            window.removeEventListener('click', handleClickEvent);
        });

    }, [drawState, isSectionActivated]);

    useEffect(() => {
        const updatePreviewDimensions = (layout) => {
            if (!layout) return;

            const elements = {
                rulerArea: document.querySelectorAll('.rulerArea'),
                previewArea: document.querySelectorAll('.previewArea')
            };

            const dimensions = {
                yearbook: {
                    ruler: { width: layout.yearbook.rx, height: layout.yearbook.ry },
                    preview: { width: layout.yearbook.px, height: layout.yearbook.py }
                },
                businessCard: {
                    ruler: { width: layout.businessCard.rx, height: layout.businessCard.ry },
                    preview: { width: layout.businessCard.px, height: layout.businessCard.py }
                }
            };

            const currentType = layout.currentPreview;
            if (!dimensions[currentType]) return;

            const applyDimensions = (elements, dimensions) => {
                _.forEach(elements, item => {
                    if (item?.className.includes('previewArea')) {
                        const parent = item.parentElement;
                        if (parent) {
                            if (dimensions.width === 371 && dimensions.height === 240) {
                                parent.classList.add('active');
                            } else if (dimensions.width === 369 && dimensions.height === 515) {
                                parent.classList.remove('active');
                            }
                        }
                    }
                    item.style.width = `${dimensions.width}px`;
                    item.style.height = `${dimensions.height}px`;
                });
            };

            applyDimensions(elements.rulerArea, dimensions[currentType].ruler);
            applyDimensions(elements.previewArea, dimensions[currentType].preview);
        };

        updatePreviewDimensions(layout);

    }, [layout]);

    useEffect(() => {
        const initializeRuler = () => {
            const areaElements = document.querySelectorAll('.previewArea');

            const existingScripts = document.querySelectorAll('#jquery-ruler-script');
            if (existingScripts?.length) {
                _.forEach(existingScripts, item => {
                    item.remove();
                })
            }

            if (areaElements?.length) {
                let scriptText = ``;

                _.forEach(areaElements, item => {
                    const parent = item.parentElement;
                    const classes = Array.from(parent.classList);
                    const pageClass = classes.find(className => className.startsWith('rulerArea-'));
                    if (pageClass) {
                        const selector = `.${pageClass}`;
                        scriptText += `jquery('${selector}').ruler();`;
                    }
                });

                if (scriptText) {
                    const script = document.createElement('script');
                    script.id = 'jquery-ruler-script';
                    script.type = 'text/javascript';
                    script.text = scriptText;
                    document.body.appendChild(script);
                }

            }
        };

        updateDisableStatus({ key: 'disable', status: true });

        initializeRuler();

    }, []);

    if (info.name === 'main_frame') {
        html = (
            <div className={`relative p-1 mt-[13px] rulerArea rulerArea-${pageIndex} overflow-hidden`} >
                <div
                    id='pdfContainer'
                    name={info.name}
                    className={`flex justify-center items-center previewArea m-[5px] pdfPage-${pageIndex} pdfPage border-[1.6px] border-dashed border-[#A4DC84] outline-[2px] outline-dashed outline-[#E72440] outline-offset-[12px] overflow-visible`}
                    style={{
                        position: 'relative',
                        background: info.color,
                        zIndex: info.z_index,
                    }}
                    onClick={handlePdfPageController}
                    onMouseOver={handlePdfPageController}
                    onMouseOut={handlePdfPageController}
                    onMouseDown={handlePdfPageController}
                    onMouseUp={handlePdfPageController}
                    onMouseMove={handleMouseMove}
                >
                </div >
            </div>
        );
    }
    return html;
}
