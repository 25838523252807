import React from "react";
import RoutesConfig from "./routes/RoutesConfig";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { useLocation } from "react-router-dom";

const App = () => {
  const location = useLocation();
  const excludedRoutes = [
    "/admin", "/design", "/admin/dashboard", "/admin/sub-category-pricing", "/admin/field-pricing", "/admin/users_data", "/admin/admin_data", "/admin/transaction", "/admin/subscribers", "/admin/current_orders", "/admin/dispatched_orders", "/admin/productdescription", "/template", "/admin/headerdescription", "/admin/upload_designassets", "/admin/category_image_upload",
    
    "/admin/", "/design/", "/admin/dashboard/", "/admin/sub-category-pricing/", "/admin/field-pricing/", "/admin/users_data/", "/admin/admin_data/", "/admin/transaction/", "/admin/subscribers/", "/admin/current_orders/", "/admin/dispatched_orders", "/admin/productdescription/", "/template/", "/admin/headerdescription/", "/admin/upload_designassets", "/admin/category_image_upload"];
  const shouldHideHeaderFooter = excludedRoutes.includes(location.pathname);

  return (
    <div>
      {!shouldHideHeaderFooter && <Header />}
      <RoutesConfig />
      {!shouldHideHeaderFooter && <Footer />}
    </div>
  );
};

export default App;