import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getCurrentOrderDetailsAPI } from '../serverApi/server';

const Orders = () => {
  const location = useLocation();
  const user_id = location.state
  const [ordersDetails, setOrdersDetails] = useState([]);

  useEffect(() => {
    const user_type = "user";
    const fetchOrdersDetails = async () => {
      try {
        const response = await getCurrentOrderDetailsAPI(user_type, user_id);
        if (response.data.client_status_code === 200) {
          setOrdersDetails(response.data.all_orders_details)
        }
      } catch (error) {
        console.error('Error fetching Order details:', error);
      }
    }
    fetchOrdersDetails();
  }, []);

  return (
    <div className='bg-[#F9F9F9]'>
      <div className='flex flex-col items-center justify-center gap-6 py-6'>
        { ordersDetails && ordersDetails.map((order, index) => {
          const date = order.order_date.split("T")[0];

        return (
          <div key={index} className='w-11/12 lg:w-4/5'>
            <h1 className='text-lg mb-1'>Order Summary Table {index + 1}</h1>
            <div className='bg-[#F4F4F4] px-3 py-2 flex flex-col justify-start items-start min-[685px]:flex-row min-[685px]:items-center min-[685px]:justify-between gap-2 shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)] rounded-md mb-4'>
              <p className='text-[15px] font-light'><span className='font-medium'>Order id:</span> INV67890</p>
              <p className='text-[15px] font-light'><span className='font-medium'>Date:</span> {date}</p>
              <p className='text-[15px] font-light'><span className='font-medium'>Status:</span> {order.payment_status.charAt(0).toUpperCase() + order.payment_status.slice(1).toLowerCase()}</p>
              <p className='text-[15px] font-light'><span className='font-medium'>Invoice Number:</span> {order.invoice_number}</p>
              <p className='text-[15px] font-light'><span className='font-medium'>Total:</span> $ {order.amount}</p>
            </div>
            <div className='overflow-x-auto border-2 border-[#CD6C37] flex flex-col gap-2 rounded-md p-4'>
              { order.all_cart_option_details && order.all_cart_option_details.map((product) => (
                <table className='w-full'>  
                  <thead>
                    <tr className='border border-[#DEE2E6]'>
                      <th className='text-start bg-[#CD6C37] text-white font-semibold border border-[#DEE2E6] px-[10px] py-2'>Image</th>
                      <th className='text-start bg-[#CD6C37] text-white font-semibold border border-[#DEE2E6] px-[10px] py-2'>Product Name</th>
                      { product.cart_option_serializer && product.cart_option_serializer.map((option) => (
                        <th key={option.id} className='text-start bg-[#CD6C37] text-white font-semibold border border-[#DEE2E6] px-[10px] py-2'>{option.field_name}</th>
                      )) }
                      {/* <th className='text-start bg-[#CD6C37] text-white font-semibold border border-[#DEE2E6] px-[10px] py-2'>Price</th> */}
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td className='border border-[#DEE2E6] px-[10px] py-2 text-center flex justify-center'>
                          <div className='w-28 h-auto'>
                            <img src={product.category_menu_option_image} alt="ordered-product" className='w-full h-full'/>
                          </div>
                        </td>
                        <td className='border border-[#DEE2E6] px-[10px] py-2 text-center'>{product.category}</td>
                        { product.cart_option_serializer && product.cart_option_serializer.map((option) => (
                          <td key={option.id} className='border border-[#DEE2E6] px-[10px] py-2 text-center'>{option.option_name}</td>
                        )) }
                        {/* <td className='border border-[#DEE2E6] px-[10px] py-2 text-center'>{product.cart_option_serializer}</td>
                        <td className='border border-[#DEE2E6] px-[10px] py-2 text-center'>24*36</td>
                        <td className='border border-[#DEE2E6] px-[10px] py-2 text-center'>Laminated</td>
                        <td className='border border-[#DEE2E6] px-[10px] py-2 text-center'>-</td> */}
                        {/* <td className='border border-[#DEE2E6] px-[10px] py-2 text-center'>$50.00</td> */}
                      </tr>

                  </tbody>
                </table>
              ))}
            </div>
          </div>

        )}) }

      </div>
    </div>
  )
}

export default Orders
