import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import Header from '../components/Header';
import { IoCloseSharp } from 'react-icons/io5';
import TemplateDesign from '../components/main/TemplateDesign';
import MyImages from '../components/MyImages';
import Image from '../components/Image';
import CreateComponent from '../components/CreateComponent';
import Draw from '../components/Draw';
import { generateRandom4DigitNumber } from '../helper/Helpers';
import { GetImages } from '../serverApi/server';
import { TbBackground } from "react-icons/tb";
import Background from '../components/Background';
import Pdf from '../components/Pdf';
import { PlaceHolder } from '../helper/PlaceHolder';
import { FaItalic, FaUnderline, FaAlignLeft, FaAlignCenter, FaAlignRight } from 'react-icons/fa';
import { MdVerticalAlignTop, MdOutlineVerticalAlignCenter, MdVerticalAlignBottom, MdBrightnessMedium, MdContrast, MdOutlineGradient, MdInvertColors, MdPhotoFilter, MdDelete } from "react-icons/md";
import { RiBlurOffFill } from "react-icons/ri";
import { TbLayoutSidebarRightCollapse } from "react-icons/tb";
import Elements from '../components/Elements';
import html2canvas from 'html2canvas';
import { saveEditedHTML } from '../serverApi/server';
import BackgroundImage from "../assets/CustomizeAsset/background.webp";
import Snackbar from '@mui/material/Snackbar';
import Alert from "@mui/material/Alert";
import { getSessionData } from '../serverApi/server';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

Modal.setAppElement('#root');


export default function Main() {

    const [currentComponent, setCurrentComponent] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();
    const [state, setState] = useState('');
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [currentView, setCurrentView] = useState('thumbnail');
    const [selectedElement, _setSelectedElement] = useState(null);
    const [drawState, setDrawState] = useState();
    const [isSectionActivated, setIsSectionActivated] = useState(false);
    const [layout, setLayout] = useState({
        // yearbook: { rx: 455, ry: 598, px: 369, py: 515 },
        yearbook: { rx: 216, ry: 854, px: 181, py: 721 }, //bookmark width and height
        businessCard: { rx: 425, ry: 295, px: 371, py: 240 },
        currentPreview: 'yearbook',
    });
    const [isLandscape, setIsLandsCape] = useState(true);
    const [isUnauthorizedModalVisible, setIsUnauthorizedModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [preventStatus, setPreventStatus] = useState('');
    const [undoRedoStatus, setUndoRedoStatus] = useState({});
    const [isElementAdded, setIsElementAdded] = useState(0);
    const [JSX, setJSX] = useState([]);
    const [currentPosition, setCurrentPosition] = useState(0);
    const [isElementUpdated,] = useState(1);
    const [isDrawActivated, setIsDrawActivated] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const existingEditId = localStorage.getItem('editId');

    const initialEditOptions = {
        width: 0,
        height: 0,
        rotate: 0,
        opacity: 1,
        fontSize: 0,
        fontFamily: '',
        color: '',
        backgroundColor: '',
        bold: 0,
        italic: false,
        underline: false,
        left: false,
        middle: false,
        right: false,
        top: false,
        bottom: false,
        middleVertical: false,
        blur: 1,
        brightness: 100,
        contrast: 0,
        grayscale: 0,
        invert: 0,
        sepia: 0
    };
    const [pdfPages, setPdfPages] = useState(null);
    const [pdfId, setPdfId] = useState(0);
    const [pdfTitle, setPdfTitle] = useState('');
    const [pdfCategory, setPdfCategory] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const pageRefs = useRef([]);
    const containerRef = useRef(null);
    const [position, setPosition] = useState({ top: 158, right: 50 });
    const [IsDragging, setIsDragging] = useState(false);
    const [startOffset, setStartOffset] = useState({ x: 0, y: 0 });
    const [editOptions, setEditOptions] = useState(initialEditOptions);

    const [show, setShow] = useState({
        status: true,
        name: ''
    });

    const [backgroundImages, setBackgroundImages] = useState([]);
    const [pngImages, setPngImages] = useState([]);
    const [elementImages, setElementImages] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const fetchImages = async (imageType) => {
        try {
            const getImagesApiResponse = await GetImages(imageType, null);
            if (getImagesApiResponse.status === 200) {
                if (imageType === 'background') {
                    setBackgroundImages(getImagesApiResponse.data.design_assets);
                } else if (imageType === 'png') {
                    setPngImages(getImagesApiResponse.data.design_assets);
                } else if (imageType === 'svg') {
                    setElementImages(getImagesApiResponse.data.design_assets);
                }
                setIsLoaded(true);
            }
        } catch (error) {
            console.log('Error while fetch the images -->', error.message);
        }
    };

    const setElements = async (name, imageType) => {
        if (imageType !== null) {
            fetchImages(imageType);
        }
        setShow({
            state: false,
            name
        });
        if (name !== "draw") {
            sessionStorage.removeItem('currentPreview');
        }
        setIsSectionActivated(!isSectionActivated);
    };

    const componentStyle = {
        name: 'main_frame',
        type: 'rect',
        id: generateRandom4DigitNumber(),
        pageCount: 0,
        height: 480,
        width: 240,
        z_index: 1,
        color: '#fff',
    };

    const [components, setComponents] = useState([componentStyle]);

    const newComponent = () => {
        const countMainFrames = components.filter(component => component.name === 'main_frame').length;
        const style = {
            name: 'main_frame',
            type: 'rect',
            id: generateRandom4DigitNumber(),
            pageCount: countMainFrames,
            height: 480,
            width: 240,
            z_index: 1,
            color: '#fff',
        }
        setComponents(prevState => ([...prevState, style]));
    }

    const showPdfUrl = (pdf) => {
        setPdfPages(pdf.pages);
        setPdfId(pdf.id);
        setPdfTitle(pdf.title_name);
        setPdfCategory(pdf.pdfType);
        setLayout((prevState) => ({ ...prevState, currentPreview: pdf.pdfType === 'business_cards' ? 'businessCard' : pdf.pdfType }))
    };


    useEffect(() => {
        const fullUrl = window.location.href;
        const urlParams = new URLSearchParams(window.location.search);
        const sessionLinkFromUrl = urlParams.get("session_link");
        const userId = localStorage.getItem("userId");

        if (sessionLinkFromUrl) {
            sessionStorage.setItem("sessionLink", fullUrl);
        }

        const sessionLink = sessionStorage.getItem("sessionLink");

        if (sessionLink && userId) {
            const fetchSessionData = async () => {
                try {
                    const data = await getSessionData(sessionLink, userId);
                    setPdfPages(decodeURIComponent(data.pdfPages));
                    setPdfId(data.pdfId);
                    setIsPageLoaded(true);
                } catch (error) {
                    if (error.response?.status === 403) {
                        setPopupOpen(true);
                    } else if (error.response?.status === 404) {
                        alert("Session not found.");
                    } else {
                        console.error("Error fetching session data:", error);
                        setPopupOpen(true);
                    }
                }
            };

            fetchSessionData();
            sessionStorage.removeItem('sessionLink')
        }
    }, []);

    const handleDialogClose = () => {

        localStorage.removeItem('userId')
        localStorage.removeItem('role')
        navigate('/') // Redirect to homepage
    };

    const handleMouseDown = (e) => {
        const targetTagName = e.target.tagName.toLowerCase();
        if (['input', 'textarea', 'select', 'button'].includes(targetTagName)) {
            return;
        }
        const container = containerRef.current;
        const rect = container.getBoundingClientRect();
        const right = window.innerWidth - rect.right;

        setStartOffset({
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
            right: right,
        });

        setIsDragging(true);
    };

    const handleContainerMouseMove = (e) => {
        if (!IsDragging) return;

        const newRight = window.innerWidth - e.clientX - (startOffset.x);

        setPosition({
            top: e.clientY - startOffset.y,
            right: newRight,
        });
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const getElementStyles = (data = {}) => {
        if (Object.keys(data).length) {
            setEditOptions(data);
        }
    };

    const setSelectedElement = (element) => {
        _setSelectedElement(element);
    };

    const storeDrawData = (data) => {
        setDrawState(data);
    }

    const reset = () => {
        setShow({ name: '', status: true });
        setState('');
        sessionStorage.removeItem('currentPreview');
        setIsSectionActivated(!isSectionActivated);
    }

    const setAddElement = (selector = "", element) => {
        if (!element) return;

        const page = document.querySelectorAll('.previewArea')?.[currentPage - 1];
        if (!page) return;

        const createElementWithStyles = (tagName, styles = {}, attributes = {}) => {
            const elem = document.createElement(tagName);
            Object.assign(elem.style, styles);
            Object.keys(attributes).forEach(attr => elem.setAttribute(attr, attributes[attr]));
            return elem;
        };

        const appendBoxWithContent = (contentElement, boxStyles = {}) => {
            const boxElement = createElementWithStyles('div', {
                position: 'absolute',
                top: '5%',
                left: '5%',
                zIndex: '100',
                ...boxStyles,
            });
            boxElement.classList.add('box');
            if (selector === 'frame') {
                boxElement.classList.add('frame-container');
            }
            boxElement.appendChild(contentElement);
            page.appendChild(boxElement);
        };

        switch (selector) {
            case "text": {
                const newText = createElementWithStyles(
                    element,
                    {
                        position: 'absolute',
                        top: '5%',
                        left: '5%',
                        zIndex: '100',
                    }
                );
                newText.innerHTML = "Click to edit...";
                page.appendChild(newText);
                break;
            }

            case "image": {
                if (selectedElement && selectedElement.className.includes('frame-container')) {
                    const imgElement = selectedElement.querySelector('img');
                    if (imgElement) {
                        imgElement.src = element;
                    }
                } else {
                    const imgElement = createElementWithStyles(
                        'img',
                        {
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            top: '0',
                            left: '0',
                            pointerEvents: 'none',
                        },
                        { src: element }
                    );
                    appendBoxWithContent(imgElement, { width: '150px', height: '150px' });
                }
                break;
            }

            case "svg": {
                const svgContainer = createElementWithStyles(
                    'div',
                    { pointerEvents: 'none' }
                );
                svgContainer.classList.add('svgContainer');
                svgContainer.innerHTML = atob(element);
                appendBoxWithContent(svgContainer);
                break;
            }

            case "frame": {
                const frameStyles = { width: '150px', height: '150px' };
                const imgStyles = {
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    pointerEvents: 'none',
                };

                const imgElement = createElementWithStyles(
                    'img',
                    imgStyles,
                    { src: BackgroundImage }
                );

                if (element === 'box-corner') {
                    imgElement.style.borderRadius = '8px';
                } else if (element !== 'box-default') {
                    imgElement.style.clipPath = element;
                }

                appendBoxWithContent(imgElement, frameStyles);
                break;
            }

            default:
                console.warn("Unknown selector:", selector);
                break;
        }

        setIsElementAdded(isElementAdded + 1);
    };

    const handlePDFElementDelete = () => {
        const focusedElement = document.querySelectorAll('[data-id="true"]');
        if (focusedElement?.length) {
            _.forEach(focusedElement, item => {
                item.remove();
            });
            setCurrentComponent('');
        }
    };

    let currentElement = null;
    let isDragging = false;
    let isClicked = true;
    let isElementActive = false;
    let clickOffset = { x: 0, y: 0 };

    const handlePdfPageController = (e) => {
        if (isDrawActivated) return;
        const element = e.target;
        const RootElement = document.getElementById('pdfContainer');

        if (element && (element.className.includes('previewArea') || element.id.includes("pdfContainer") || element.className.includes('resize') || element.tagName === "IMG" || element.className.includes('svgContainer') || element.className.includes('stage'))) {
            return;
        }

        if ((element && element.innerText && element.className.includes('fs')) || (element && element.firstChild && element.firstChild.tagName === "IMG") || element.tagName !== "DIV" || (element.firstChild && element.firstChild.className === "svgContainer")) {

            if (element && !element.id.includes("pdfContainer") && !element.className.includes('page')) {
                if (e.type === 'mouseover' && !isElementActive) {
                    element.style.position = 'absolute';
                    element.style.border = `2px solid rgb(139 61 255)`;

                    const getBoxElements = document.querySelectorAll('.box');
                    if (getBoxElements.length) {
                        getBoxElements.forEach(element => {
                            element.classList.remove('box');
                        });
                    }

                    const style = window.getComputedStyle(element);
                    const transformMatrix = style.transform || style.webkitTransform;

                    if (transformMatrix.startsWith("matrix")) {
                        const values = transformMatrix.match(/matrix\(([^)]+)\)/)[1].split(", ");
                        const scaleX = parseFloat(values[0]);
                        const scaleY = parseFloat(values[3]);

                        const uiFontSize = parseFloat(style.fontSize);
                        const uiWidth = parseFloat(style.width);
                        const uiHeight = parseFloat(style.height);
                        const uiLetterSpacing = parseFloat(style.letterSpacing || "0");
                        const uiWordSpacing = parseFloat(style.wordSpacing || "0");

                        const originalFontSize = uiFontSize / scaleX;
                        const originalWidth = uiWidth / scaleX;
                        const originalHeight = uiHeight / scaleY;
                        const originalLetterSpacing = uiLetterSpacing / scaleX;
                        const originalWordSpacing = uiWordSpacing / scaleX;

                        const displayedFontSize = originalFontSize * scaleX;
                        const displayedWidth = originalWidth * scaleX;
                        const displayedHeight = originalHeight * scaleY;
                        const displayedLetterSpacing = originalLetterSpacing * scaleX;
                        const displayedWordSpacing = originalWordSpacing * scaleX;

                        const valueFontSize = displayedFontSize * scaleX;
                        const valueWidth = displayedWidth * scaleX;
                        const valueHeight = displayedHeight * scaleY;
                        const valueLetterSpacing = displayedLetterSpacing * scaleX;
                        const valueWordSpacing = displayedWordSpacing * scaleX;

                        if (valueFontSize || valueHeight) {
                            element.classList.forEach(className => {
                                if (className.match(/^m[0-9]$/)) {
                                    element.classList.remove(className);
                                }
                            });
                            element.style.fontSize = `${valueFontSize}px`;
                            element.style.width = `${valueWidth}px`;
                            element.style.height = `${valueHeight}px`;
                            element.style.letterSpacing = `${valueLetterSpacing}px`;
                            element.style.wordSpacing = `${valueWordSpacing}px`;
                            element.style.display = 'flex';
                        }
                    }

                    element.classList.add('box');
                    if (window.boxModel) {
                        window.boxModel();
                    }

                    const findRootElements = RootElement.querySelectorAll('span, .vector, img, canvas, div');
                    const borderedSpans = Array.from(findRootElements).filter(span =>
                        span.style.border !== '' && !span.hasAttribute('data-id')
                    );

                    if (borderedSpans.length > 1) {
                        borderedSpans.forEach(span => {
                            span.style.border = '';
                        });
                    }
                }
            }

            if (e.type === "click" && !isElementActive) {
                const findRootElements = RootElement.querySelectorAll('span, .vector, img, canvas, p, h3, h5, div');
                const borderedSpans = Array.from(findRootElements).filter(span => span.style.border !== '');
                const editableElements = Array.from(findRootElements).filter(item => item.contentEditable === "true");

                if (borderedSpans.length > 1) {
                    borderedSpans.forEach(span => {
                        span.style.border = '';
                    });
                }

                if (editableElements.length > 1) {
                    editableElements.forEach(item => {
                        item.contentEditable = "false";
                    });
                }

                const dataAttribute = RootElement.querySelectorAll('[data-id="true"]');

                if (dataAttribute?.length) {
                    _.forEach(dataAttribute, element => {
                        element.removeAttribute('data-id');
                    })
                }

                isClicked = true;
                element.style.outline = 'none';
                element.style.borderRadius = '5px';
                element.style.padding = '5px';
                element.setAttribute('data-id', true);
                setCurrentComponent(true);

                setSelectedElement(null);
                setSelectedElement(element);

                // parse selected element styles
                const width = getComputedStyle(element, null).getPropertyValue('width').replace('px', '');
                const height = getComputedStyle(element, null).getPropertyValue('height').replace('px', '');
                const transform = getComputedStyle(element, null).getPropertyValue('transform');
                const opacity = element?.style?.opacity || 1;
                const fontSize = getComputedStyle(element, null).getPropertyValue('font-size').replace('px', '');
                const fontFamily = getComputedStyle(element, null).getPropertyValue('font-family');
                const backgroundColor = getComputedStyle(element, null).getPropertyValue('background-color').match(/\d+/g).map(x => parseInt(x).toString(16).padStart(2, '0')).join('');
                const color = getComputedStyle(element, null).getPropertyValue('color').match(/\d+/g).map(x => parseInt(x).toString(16).padStart(2, '0')).join('');
                const bold = getComputedStyle(element, null).getPropertyValue('font-weight');
                const blur = parseFloat(getComputedStyle(element).filter.match(/blur\((\d+(\.\d+)?)px\)/)?.[1]) || 0;
                const brightness = (parseFloat(getComputedStyle(element).filter.match(/brightness\((\d+(\.\d+)?)\)/)?.[1]) * 100) || 100;
                const contrast = (parseFloat(getComputedStyle(element).filter.match(/contrast\((\d+(\.\d+)?)\)/)?.[1]) * 100) || 100;
                const grayscale = (parseFloat(getComputedStyle(element).filter.match(/grayscale\((\d+(\.\d+)?)\)/)?.[1]) * 100) || 0;
                const invert = (parseFloat(getComputedStyle(element).filter.match(/invert\((\d+(\.\d+)?)\)/)?.[1]) * 100) || 0;
                const saturate = parseFloat(getComputedStyle(element).filter.match(/saturate\((\d+(\.\d+)?)\)/)?.[1]) || 1;
                const sepia = (parseFloat(getComputedStyle(element).filter.match(/sepia\((\d+(\.\d+)?)\)/)?.[1]) * 100) || 0;

                let rotate = 0;
                if (transform !== 'none') {
                    const values = transform.split('(')[1].split(')')[0].split(',');
                    const a = values[0];
                    const b = values[1];
                    rotate = Math.round(Math.atan2(b, a) * (180 / Math.PI));
                }

                const data = { width, height, rotate, opacity, fontSize, fontFamily, backgroundColor: `#${backgroundColor}`, color: `#${color}`, bold, blur, brightness, contrast, grayscale, invert, saturate, sepia };
                getElementStyles(data);

                if (element.innerText.trim() !== "") {
                    element.setAttribute("contentEditable", "true");
                    element.setAttribute("spellcheck", "false");
                    element.style.cursor = "text";
                    element.focus();
                }
            }

            if (e.type === 'mouseout') {
                if (!isClicked) {
                    if (element.hasAttribute('data-id')) {
                        setCurrentComponent('');
                        element.removeAttribute('data-id');
                    }
                } else {
                    isClicked = false;
                }
                const isFocused = element.getAttribute("data-id");
                if (!isFocused) element.style.border = '';
                element.style.padding = '0';
                element.style.borderRadius = '0';
            }

            if (e.type === 'mousedown') {
                e.preventDefault();
                isDragging = true;
                isElementActive = true;
                currentElement = element;
                element.style.cursor = 'grab';
                const rect = element?.getBoundingClientRect();
                clickOffset = {
                    x: e.clientX - rect.left,
                    y: e.clientY - rect.top,
                };
            }

            if (currentElement && e.type === 'mouseup') {
                currentElement = null;
                isDragging = false;
                isElementActive = false;
                element.style.cursor = 'default';
                IsElementUpdated();
            }
            document.addEventListener('click', () => {
                isDragging = false;
            })

        }
    };

    const handleMouseMove = (e) => {
        if (isDrawActivated) return;
        if (isDragging && currentElement) {

            const targetElement = e.currentTarget;
            const parentElement = targetElement.parentNode;
            const parentRect = parentElement.getBoundingClientRect();

            const mouseX = e.clientX - parentRect.left;
            const mouseY = e.clientY - parentRect.top;

            const newLeft = mouseX - clickOffset.x;
            let newTop = mouseY - clickOffset.y;

            currentElement.style.left = `${newLeft}px`;
            currentElement.style.top = `${newTop}px`;
        }
    };

    const saveWork = async () => {
        if (isSaving) {
            console.log('Save in progress. Please wait.');
            return;
        }

        setIsSaving(true);

        const workSpace = document.querySelectorAll('.previewArea');
        let pages = [];

        if (workSpace?.length) {
            workSpace.forEach(item => {
                const previewSpace = item;

                if (previewSpace) {
                    const tempHTML = previewSpace.cloneNode(true);

                    let outerHTML = previewSpace.cloneNode(true);
                    const elementsToRemove = outerHTML.querySelectorAll('[id*="pf"]');
                    elementsToRemove.forEach(element => element.remove());

                    const modifiedInnerHTML = outerHTML.innerHTML;

                    const pfContainer = tempHTML.querySelector('[id*="pf"]');
                    if (pfContainer) {
                        pfContainer.insertAdjacentHTML('beforeend', modifiedInnerHTML);
                    }

                    const width = tempHTML.style.width;
                    const height = tempHTML.style.height;

                    tempHTML.removeAttribute('style');
                    tempHTML.style.width = width;
                    tempHTML.style.height = height;

                    // reset the pdf focused element
                    const focusedElement = tempHTML.querySelectorAll('[data-id="true"]');
                    if (focusedElement?.length) {
                        focusedElement.forEach(item => {
                            item.style.border = '';
                            item.removeAttribute('data-id');
                        });
                    }

                    const elementsWithCursor = tempHTML.querySelectorAll('[style*="cursor"]');

                    elementsWithCursor.forEach(element => {
                        element.style.cursor = 'pointer';
                    });

                    const JSX = `${tempHTML.outerHTML}`;
                    pages.push(JSX);
                }
            });

            const pdfChanges = `
                      <!DOCTYPE html>
                      <html>
                      <head>
                          <meta http-equiv="content-type" content="text/html; charset=UTF-8"/>
                          <style>
                          ${document.getElementById('pdf-page-styles')?.innerHTML}
                          </style>
                          <script>
                           try{
                              pdf2htmlEX.defaultViewer = new pdf2htmlEX.Viewer({});
                           }catch(e){}
                          </script>
                      </head>
                      <body style="color:#000000;">
                          ${pages.join('')}
                      </body>
                      </html>
                    `;

            const user_id = localStorage.getItem('userId');
            const email = localStorage.getItem('email');
            if (pdfId && user_id && email && pdfChanges) {
                const generatePreview = document.querySelector('.pdfPage-1');
                let imgUrl = '';
                if (generatePreview) {
                    await html2canvas(generatePreview).then(canvas => {
                        imgUrl = canvas.toDataURL('image/png');
                    }).catch(error => {
                        console.error('Error generating preview:', error);
                    });
                }
                const payload = {
                    pdf_id: pdfId,
                    title_name: pdfTitle,
                    embedded_pages: encodeURIComponent(pdfChanges),
                    user_id,
                    email,
                    imgUrl,
                    pdfType: pdfCategory,
                    edit_id: existingEditId
                };
                const autosave = document.getElementById('autosave-icon');
                if (autosave) {
                    autosave.classList.add('animate-pulse', 'glow-animation');
                    setTimeout(() => {
                        autosave.classList.remove('animate-pulse', 'glow-animation');
                    }, 1000);
                }

                try {
                    const response = await saveEditedHTML(payload);
                    if (response.status === 201) {
                        console.log('Save successful!');
                        const newEditId = response.data?.edit_id;
                        if (newEditId) {
                            localStorage.setItem('editId', newEditId);
                        }
                    } else {
                        console.error('Save failed:', response.status);
                    }
                } catch (error) {
                    console.error('Save error:', error);
                }
            }
        }

        setIsSaving(false);
    };


    const updateDisableStatus = (props) => {
        setUndoRedoStatus(props);
    }

    useEffect(() => {
        const loadPdf = async () => {

            const tempContainer = document.createElement('div');
            tempContainer.innerHTML = pdfPages;
            const pagesCount = tempContainer.querySelectorAll('[id*="pf"]');

            if (pagesCount?.length) {
                for (let index = 1; index < pagesCount?.length; index++) {
                    if (components?.length <= index) {
                        newComponent();
                    }
                }

                if (components?.length > pagesCount?.length) {
                    document.querySelectorAll('[name="main_frame"]').forEach(function (element, index) {
                        if (index >= pagesCount?.length) {
                            setTimeout(() => {
                                element.remove();
                            }, 100);
                        }
                    });

                    setComponents(prevState => ([...prevState].slice(0, pagesCount?.length)));
                }
            }
        };
        if (pdfPages) {
            loadPdf();
        }
    }, [pdfPages]);

    useEffect(() => {

        const handleEventClick = async (event) => {
            if (event.target.id === "pdfContainer") {
                await setCurrentComponent('');
                const currentPreview = sessionStorage.getItem('currentPreview');
                if (currentPreview && currentPreview !== "draw") {
                    sessionStorage.removeItem('currentPreview');
                    setShow({ name: '', status: true });
                    setState('');
                }

                // reset the pdf focused element
                const focusedElement = document.querySelectorAll('[data-id="true"]');
                if (focusedElement?.length) {
                    _.forEach(focusedElement, item => {
                        item.style.border = '';
                        item.removeAttribute('data-id');
                    })
                }
            }
        }

        window.addEventListener('click', handleEventClick);
        window.addEventListener('beforeunload', () => {
            sessionStorage.removeItem('currentPreview');
        })

        return () => {
            window.removeEventListener('click', handleEventClick);
        };

    }, []);

    useEffect(() => {
        if (state) sessionStorage.setItem('currentPreview', state);
    }, [state])

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsPageLoaded(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, [window.location.pathname]);

    useEffect(() => {
        let isIndexZero = false;
        const handleScroll = () => {
            pageRefs.current.forEach((page, index) => {
                if (page) {
                    const rect = page.getBoundingClientRect();
                    const isInViewport = rect.top >= 0 && rect.top < window.innerHeight * 0.7;
                    if (isInViewport) {
                        if (index === 0 || isIndexZero) {
                            index++;
                            isIndexZero = true;
                        }
                        setCurrentPage(index);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [pageRefs.current]);

    useEffect(() => {
        const runPreventStatus = () => {
            if (preventStatus && JSX.length > 0) {
                const maxLength = JSX?.length;
                let newPosition = currentPosition;
                const history = JSX;

                if (preventStatus.includes('undo')) {
                    if (newPosition >= 1) {
                        newPosition -= 1;
                        if (newPosition >= 1) {
                            updateDisableStatus({ key: '', status: false });
                        } else {
                            updateDisableStatus({ key: 'undo', status: true });
                        }
                    } else {
                        updateDisableStatus({ key: 'undo', status: true });
                    }
                    if (history[newPosition]) {
                        const selectedJSX = history[newPosition];
                        const stages = document.querySelectorAll('.previewArea');
                        if (stages?.length) {
                            _.forEach(stages, (stage, index) => {
                                stage.innerHTML = selectedJSX[index] || '';
                                stage.addEventListener('click', handlePdfPageController);
                                stage.addEventListener('mouseover', handlePdfPageController);
                                stage.addEventListener('mouseout', handlePdfPageController);
                                stage.addEventListener('mousedown', handlePdfPageController);
                                stage.addEventListener('mouseup', handlePdfPageController);
                                stage.addEventListener('mousemove', handleMouseMove);
                            })
                        }
                    }
                } else if (preventStatus.includes('redo')) {
                    if (newPosition < maxLength) {
                        newPosition += 1;
                        updateDisableStatus({ key: '', status: false });
                    }
                    if (newPosition === maxLength) {
                        updateDisableStatus({ key: 'redo', status: true });
                    }
                    if (history[newPosition]) {
                        const selectedJSX = history[newPosition];
                        const stages = document.querySelectorAll('.previewArea');
                        if (stages?.length) {
                            _.forEach(stages, (stage, index) => {
                                stage.innerHTML = selectedJSX[index] || '';
                                stage.addEventListener('click', handlePdfPageController);
                                stage.addEventListener('mouseover', handlePdfPageController);
                                stage.addEventListener('mouseout', handlePdfPageController);
                                stage.addEventListener('mousedown', handlePdfPageController);
                                stage.addEventListener('mouseup', handlePdfPageController);
                                stage.addEventListener('mousemove', handleMouseMove);
                            })
                        }
                    }
                }

                if (window.boxModel) {
                    window.boxModel();
                }
                if (newPosition !== currentPosition) {
                    setCurrentPosition(newPosition);
                }
            }
        };

        runPreventStatus();
    }, [preventStatus]);

    const IsElementUpdated = () => {
        setIsElementAdded(isElementAdded + 1);
    }

    const updateStore = (key = '') => {
        const executeFunction = () => {
            const stages = document.querySelectorAll('.previewArea');
            if (stages.length) {
                let store = {};
                _.forEach(stages, (element, index) => {
                    store[index] = element.innerHTML;
                });
                if (JSX?.length > 10) {
                    let updatedJsx = [...JSX].slice(1);
                    updatedJsx.push(store);
                    setJSX(updatedJsx);
                } else {
                    let jsx = [...JSX];
                    jsx.push(store);
                    setJSX(jsx);
                }
                setCurrentPosition(currentPosition + 1);
                updateDisableStatus({ key: '', status: false });
            }
        };

        if (key === 'renderWithDelay') {
            setTimeout(executeFunction, 4000);
        } else {
            executeFunction();
        }
    };

    useEffect(() => {
        if (isElementUpdated || isElementAdded) {
            updateStore();
        }
    }, [isElementUpdated, isElementAdded]);

    useEffect(() => {
        updateStore('renderWithDelay');
    }, []);

    const handleAlert = (data) => {
        if (data.alertStatus) {
            setSnackbarOpen(true);
            setSnackbarMessage(data.message);
            setSnackbarSeverity(data.apiStatus)
        }
    };

    const setWorkspaceView = (workspaceView) => {
        setCurrentView(workspaceView);
    }

    return (

        <React.Fragment>
            <Dialog open={popupOpen} onClose={() => setPopupOpen(false)}>
                <DialogTitle>Access Denied</DialogTitle>
                <DialogContent>
                    <p>You do not have permission to access this link.</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            {/* <PlaceHolder /> */}
            {!isPageLoaded ? <PlaceHolder /> : (
                <div className='min-w-screen bg-[#f6f7f8]'>
                    <Header setSaveStatus={() => { saveWork() }} preventKey={(key) => { setPreventStatus(key) }} status={undoRedoStatus} pdfId={pdfId} setWorkspaceView={setWorkspaceView} />
                    <div className='flex h-[calc(100%-60px)]'>

                        {/* sidebar menu */}
                        <div className='fixed mt-[75px] w-[80px] h-[100%] bg-[#f6f7f8] text-[rgba(0,0,0,0.6)] overflow-y-auto z-30'>
                            <Tippy content={<span className='text-[12px]'>Design</span>} placement='right' offset={[2, 0]}>
                                <div onClick={() => { setElements('design'); setState('design') }} className="w-full h-[80px] cursor-pointer flex justify-center flex-col items-center gap-1 group" >
                                    <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                                        <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#13a3b5] ${state === 'design' && "text-[#13a3b5]"}`}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M3 11.325c0-3.59 0-5.385.966-6.61a4.5 4.5 0 0 1 .748-.749C5.94 3 7.734 3 11.325 3h1.35c3.59 0 5.386 0 6.61.966.279.22.53.47.749.748C21 5.94 21 7.734 21 11.325v1.35c0 3.59 0 5.386-.966 6.61-.22.279-.47.53-.748.749-1.226.966-3.02.966-6.611.966h-1.35c-3.59 0-5.385 0-6.61-.966a4.497 4.497 0 0 1-.749-.748C3 18.06 3 16.266 3 12.675v-1.35ZM11.325 4.5H13.5v15h-2.175c-1.83 0-3.076-.002-4.021-.111-.914-.105-1.356-.293-1.661-.533a3.004 3.004 0 0 1-.499-.499c-.24-.305-.428-.747-.533-1.661-.109-.945-.111-2.19-.111-4.021v-1.35c0-1.83.002-3.076.11-4.021.106-.914.293-1.356.534-1.661a3 3 0 0 1 .499-.499c.305-.24.747-.428 1.661-.533.945-.109 2.19-.111 4.021-.111ZM15 19.486c.666-.014 1.22-.042 1.696-.097.914-.105 1.356-.293 1.661-.533.186-.146.353-.314.499-.499.24-.305.428-.747.533-1.661.109-.945.111-2.19.111-4.021v-1.657H15v8.468Zm4.494-9.968c-.01-.904-.037-1.619-.105-2.214-.105-.914-.293-1.356-.533-1.661a3.004 3.004 0 0 0-.499-.499c-.305-.24-.747-.428-1.661-.533A18.58 18.58 0 0 0 15 4.514v5.004h4.494Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </span>
                                    <span className='text-xs font-medium'>Design</span>
                                </div>
                            </Tippy>

                            <Tippy content={<span className='text-[12px]'>Elements</span>} placement='right' offset={[2, 0]}>
                                <div onClick={() => { setElements('element', 'svg'); setState('element') }} className="w-full h-[80px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                                    <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                                        <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#992bff] ${state === 'element' && "text-[#992bff]"}`}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6.55 11.242a1.502 1.502 0 0 0 1.42 0l.002-.002.004-.002.01-.005.026-.015.084-.048a9.986 9.986 0 0 0 1.14-.787c.638-.51 1.478-1.312 2.026-2.375a2.991 2.991 0 0 0-4.003-4.16A2.991 2.991 0 0 0 3.2 7.912c.54 1.103 1.402 1.93 2.055 2.454a10.007 10.007 0 0 0 1.25.854l.028.015.01.005.003.002.002.001ZM4.53 7.217c.352.731.918 1.34 1.444 1.794a8.454 8.454 0 0 0 1.285.91s.086-.047.229-.136a8.452 8.452 0 0 0 1.054-.776c.51-.442 1.058-1.03 1.41-1.734a1.491 1.491 0 1 0-2.693-1.208 1.493 1.493 0 0 0-1.435-1.084A1.491 1.491 0 0 0 4.53 7.217ZM17.536 4.011a1.026 1.026 0 0 0-1.775 0l-3.307 5.694a1.026 1.026 0 0 0 .888 1.542h6.614c.79 0 1.285-.857.887-1.542l-3.307-5.694ZM16.65 5.47l-2.485 4.277h4.969L16.649 5.47ZM20.861 17.168a4.213 4.213 0 1 1-8.425 0 4.213 4.213 0 0 1 8.425 0Zm-1.5 0a2.713 2.713 0 1 1-5.425 0 2.713 2.713 0 0 1 5.425 0ZM3.406 14.086a.77.77 0 0 1 .77-.77h6.14a.77.77 0 0 1 .77.77v6.294c0 .34-.275.616-.615.616H4.176a.77.77 0 0 1-.77-.77v-6.14Zm1.5 5.41v-4.68h4.68v4.68h-4.68Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </span>
                                    <span className='text-xs font-medium'>Elements</span>
                                </div>
                            </Tippy>

                            <Tippy content={<span className='text-[12px]'>Draw</span>} placement='right' offset={[2, 0]}>
                                <div onClick={() => { setElements('draw'); setState('draw'); }} className="w-full h-[80px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                                    <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                                        <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#0ba84a] ${state === 'draw' && "text-[#0ba84a]"}`}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M20.07 3.456a3.135 3.135 0 0 0-4.434 0L10.25 8.843a3.38 3.38 0 0 0-.884 1.55l-.845 3.292c-.205.8.522 1.527 1.322 1.323l3.278-.837a3.384 3.384 0 0 0 1.555-.886L20.07 7.89a3.135 3.135 0 0 0 0-4.434Zm-2.117 4.43 1.057-1.057a1.635 1.635 0 0 0-2.313-2.313l-1.056 1.057 2.312 2.312Zm-1.166 1.166-3.172 3.172c-.24.24-.539.41-.866.493l-2.602.665.67-2.616a1.88 1.88 0 0 1 .492-.862l3.165-3.164 2.313 2.312Z" fill="currentColor"></path><path d="M5.144 15.022a.641.641 0 1 0 0 1.282h13.751a2.109 2.109 0 0 1 0 4.218H9.194a.75.75 0 0 1 0-1.5h9.701a.609.609 0 1 0 0-1.218H5.144a2.141 2.141 0 0 1 0-4.282h1.862v1.5H5.144Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </span>
                                    <span className='text-xs font-medium'>Draw</span>
                                </div>
                            </Tippy>

                            <Tippy content={<span className='text-[12px]'>Upload</span>} placement='right' offset={[2, 0]}>
                                <div onClick={() => { setElements('uploadImage'); setState('image') }} className="w-full h-[80px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                                    <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                                        <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#ff6105] ${state === 'image' && "text-[#ff6105]"}`}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.24 2.985c-2.735 0-5.04 2.075-5.316 4.788a.199.199 0 0 1-.123.162 5.729 5.729 0 0 0 1.994 11.097 5.727 5.727 0 0 0 5.727-5.727v-.486l1.782 1.782a.75.75 0 0 0 1.06-1.06l-3.062-3.063a.75.75 0 0 0-1.06 0L9.179 13.54a.75.75 0 0 0 1.06 1.06l1.783-1.781v.486A4.227 4.227 0 1 1 6.324 9.34a1.698 1.698 0 0 0 1.092-1.416c.198-1.943 1.855-3.44 3.825-3.44a3.848 3.848 0 0 1 3.785 3.174c.135.764.78 1.366 1.563 1.43 2.146.178 3.855 2.016 3.855 4.216a4.226 4.226 0 0 1-4.227 4.227h-1.914a.75.75 0 0 0 0 1.5h1.914a5.727 5.727 0 0 0 5.727-5.727c0-2.978-2.305-5.468-5.231-5.71a.25.25 0 0 1-.21-.196 5.348 5.348 0 0 0-5.262-4.414Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </span>
                                    <span className='text-xs font-medium'>Upload</span>
                                </div>
                            </Tippy>

                            <Tippy content={<span className='text-[12px]'>Text</span>} placement='right' offset={[2, 0]}>
                                <div onClick={() => { setElements('text'); setState('text') }} className="w-full h-[80px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                                    <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                                        <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#992bff] ${state === 'text' && "text-[#992bff]"}`}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.266 5.792a1.5 1.5 0 0 1 1.5-1.5h12.468a1.5 1.5 0 0 1 1.5 1.5v1.85a.75.75 0 0 1-1.5 0v-1.35a.5.5 0 0 0-.5-.5H12.75v11.939a.5.5 0 0 0 .5.5h1.875a.75.75 0 0 1 0 1.5h-6.25a.75.75 0 1 1 0-1.5h1.875a.5.5 0 0 0 .5-.5V5.792H6.266a.5.5 0 0 0-.5.5V7.67a.75.75 0 1 1-1.5 0V5.792Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </span>
                                    <span className='text-xs font-medium'>Text</span>
                                </div>
                            </Tippy>

                            <Tippy content={<span className='text-[12px]'>Projects</span>} placement='right' offset={[2, 0]}>
                                <div onClick={() => { setElements('projects'); setState('projects') }} className="w-full h-[80px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                                    <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md">
                                        <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#333] ${state === 'projects' && "text-[#333]"}`}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.845 2.942H7.03a4 4 0 0 0-4 3.994l-.017 10a4 4 0 0 0 4 4.006h9.993a4 4 0 0 0 4-4v-8.23a3 3 0 0 0-3-3h-3.614a.5.5 0 0 1-.447-.277l-.417-.834a3 3 0 0 0-2.683-1.659Zm-3.815 1.5h3.815a1.5 1.5 0 0 1 1.341.83l.417.834a2 2 0 0 0 1.79 1.106h3.613a1.5 1.5 0 0 1 1.5 1.5v.735H4.526l.004-2.509a2.5 2.5 0 0 1 2.5-2.495Zm-2.507 6.505-.01 5.991a2.5 2.5 0 0 0 2.5 2.505h9.993a2.5 2.5 0 0 0 2.5-2.5v-5.996H4.523Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </span>
                                    <span className='text-xs font-medium'>Projects</span>
                                </div>
                            </Tippy>

                            <Tippy content={<span className='text-[12px]'>Images</span>} placement='right' offset={[2, 0]}>
                                <div onClick={() => { setElements('images', 'png'); setState('initImage') }} className="w-full h-[80px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                                    <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                                        <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#992bff] ${state === 'initImage' && "text-[#992bff]"}`}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12.81 4.5h-1.62c-1.765 0-2.965.002-3.878.104-.884.098-1.315.273-1.613.497a3 3 0 0 0-.598.598c-.224.298-.4.729-.497 1.613-.102.913-.104 2.113-.104 3.878v1.62c0 1.765.002 2.965.104 3.878.085.77.23 1.196.413 1.49l7.773-7.773.03-.03c.484-.484.89-.89 1.256-1.183.384-.307.79-.546 1.287-.616.245-.035.495-.035.74 0 .496.07.903.309 1.287.616.365.292.772.7 1.257 1.184l.03.03.823.823v-.039c0-1.765-.002-2.965-.104-3.878-.098-.884-.273-1.315-.497-1.613a3.003 3.003 0 0 0-.598-.598c-.298-.224-.729-.4-1.613-.497-.913-.102-2.113-.104-3.878-.104ZM7.312 19.396c-.515-.057-.877-.14-1.147-.244l7.685-7.686c.522-.522.87-.869 1.163-1.103.28-.224.439-.285.561-.302.106-.015.212-.015.318 0 .122.017.28.078.56.302.293.234.642.581 1.164 1.103L19.5 13.35c-.002 1.475-.013 2.521-.104 3.338-.098.884-.273 1.315-.497 1.613-.17.227-.371.428-.598.598-.298.224-.729.4-1.613.497-.913.102-2.113.104-3.878.104h-1.62c-1.765 0-2.965-.002-3.878-.104ZM3.902 4.798C3 5.998 3 7.73 3 11.19v1.62c0 3.46 0 5.191.901 6.392.255.34.557.641.897.897C5.998 21 7.73 21 11.19 21h1.62c3.46 0 5.191 0 6.392-.901.34-.256.641-.557.897-.897.901-1.2.901-2.931.901-6.392v-1.62c0-3.46 0-5.191-.901-6.392a4.501 4.501 0 0 0-.897-.897C18.002 3 16.27 3 12.81 3h-1.62c-3.46 0-5.191 0-6.392.901a4.5 4.5 0 0 0-.897.897ZM8.5 10.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </span>
                                    <span className='text-xs font-medium'>Images</span>
                                </div>
                            </Tippy>

                            <Tippy content={<span className='text-[12px]'>Background</span>} placement='right' offset={[2, 0]}>
                                <div onClick={() => { setElements('background', 'background'); setState('background') }} className="w-full h-[80px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                                    <span className='text-2xl w-[35px] h-20px p-1 flex justify-center rounded-md group-hover:bg-slate-100'><TbBackground className={`text-[25px] flex justify-center items-center group-hover:text-[#333] ${state === 'background' && "text-[#333]"}`} /></span>
                                    <span className='text-xs font-medium'>Background</span>
                                </div>
                            </Tippy>
                        </div>

                        <div className='w-[calc(100%-0px)]'>
                            <div className={`${show.status ? 'p-0 -left-[50%]' : `left-[85px] py-5 ${state !== "draw" && "px-4"}`} bg-[#f6f7f8] shadow-lg h-full fixed ${state === 'draw' ? 'w-[120px] h-[300px] max-h-[300px] top-[160px]' : 'w-[350px]'} z-30`} style={{ marginTop: '60px' }}>
                                <div onClick={() => { setShow({ name: '', status: true }); setState(''); sessionStorage.removeItem('currentPreview'); setIsSectionActivated(!isSectionActivated); }} className='absolute -right-[35px] top-1 bg-white w-[30px] h-[30px] rounded-full flex justify-center items-center cursor-pointer' >
                                    < IoCloseSharp className='font-bold text-[20px] text-[rgba(0,0,0,0.6)]' />
                                </div>
                                {
                                    state === 'design' && <div>
                                        <div className='w-full h-auto overflow-hidden'>
                                            {<TemplateDesign showPdfUrl={showPdfUrl} />}
                                        </div>
                                    </div>
                                }
                                {
                                    state === 'image' && <div className='h-[80vh] overflow-x-auto flex justify-start items-start 
                                        scrollbar-hide'>
                                        <MyImages setAddElement={setAddElement} handleAlert={handleAlert} />
                                    </div>
                                }
                                {
                                    state === 'text' && <React.Fragment><div>
                                        <div className='grid grid-cols-1 gap-2'>
                                            <button onClick={() => setAddElement('text', 'p')} className='bg-[#f6f7f8] hover:bg-[rgb(0,0,0,0.02)] cursor-pointer font-[400] p-3 text-[14px] text-[#333] text-xl rounded-md border-[1px] solid border-[rgba(0,0,0,0.2)] flex items-center justify-center max-h-[45px]'>
                                                Add a text box
                                            </button>
                                        </div>
                                    </div>
                                        <div className="text-[#333] my-[21px] text-[17px] font-bold">
                                            Default text styles
                                        </div>
                                        <div className='grid grid-cols-1 gap-2'>
                                            <p onClick={() => setAddElement('text', 'h3')} className='bg-[#f6f7f8] hover:bg-[rgb(0,0,0,0.02)] cursor-pointer font-[400] p-3 text-[14px] text-[#333] text-xl rounded-md border-[1px] solid border-[rgba(0,0,0,0.2)] flex items-center justify-center max-h-[45px]'>
                                                Add a heading
                                            </p>
                                        </div>
                                        <div className='grid grid-cols-1 gap-2'>
                                            <p onClick={() => setAddElement('text', 'h5')} className='bg-[#f6f7f8] hover:bg-[rgb(0,0,0,0.02)] cursor-pointer font-[400] p-3 text-[14px] text-[#333] text-xl rounded-md border-[1px] solid border-[rgba(0,0,0,0.2)] flex items-center justify-center max-h-[45px]'>
                                                Add a sub heading
                                            </p>
                                        </div>
                                    </React.Fragment>
                                }
                                {
                                    state === 'projects' && (
                                        <div className='h-[80vh] overflow-x-auto flex justify-start items-start scrollbar-hide'>
                                            <Pdf showPdfUrl={showPdfUrl} />
                                        </div>
                                    )
                                }
                                {
                                    state === 'element' && <div className='h-[80vh] overflow-x-auto flex justify-start items-start 
                                        scrollbar-hide'>
                                        <Elements myImages={elementImages} setAddElement={setAddElement} />
                                    </div>
                                }
                                {
                                    state === 'initImage' && <div className='h-[80vh] overflow-x-auto flex justify-start items-start 
                                        scrollbar-hide w-full'>
                                        <Image myImages={pngImages} setAddElement={setAddElement} isLoaded={isLoaded} />
                                    </div>
                                }
                                {
                                    state === 'background' && <div className='h-[80vh] overflow-x-auto flex justify-start items-start 
                                        scrollbar-hide'>
                                        <Background myImages={backgroundImages} setAddElement={setAddElement} isLoaded={isLoaded} />
                                    </div>
                                }
                                {
                                    state === 'draw' && <div className='h-[300px] overflow-x-auto flex justify-start items-start scrollbar-hide -mt-[40px]'>
                                        <Draw storeDrawData={storeDrawData} />
                                    </div>
                                }
                            </div>
                            <div className='w-full flex'>

                                <div className="justify-center items-center h-full min-h-[100vh] w-full overflow-y-auto bg-white" style={{ margin: '0 0 0 81px' }}>
                                    <div id='pdfContainer' className='m-w-[650px] m-h-[480px] flex justify-center items-center overflow-hidden bg-white'>
                                        <div id='pdfContainer' className={`w-full h-full flex ${currentView === 'thumbnail' ? "justify-center" : "justify-start"}`}>
                                            <div id='pdfContainer' className={`w-auto h-auto overflow-hidden mt-[70px] ${currentView === 'thumbnail' ? '' : 'ml-[20px] flex gap-4 flex-wrap'}`}>
                                                {components.map((c, i) => (
                                                    <div key={i} ref={(el) => (pageRefs.current[i] = el)} data-pageid={i + 1}>
                                                        <CreateComponent
                                                            key={i}
                                                            info={c}
                                                            pdfPages={pdfPages}
                                                            pageIndex={i + 1}
                                                            setElementStyles={editOptions}
                                                            selectedElement={selectedElement}
                                                            drawState={drawState}
                                                            isSectionActivated={isSectionActivated}
                                                            reset={reset}
                                                            layout={layout}
                                                            updateDisableStatus={updateDisableStatus}
                                                            setIsElementUpdated={IsElementUpdated}
                                                            handlePdfPageController={handlePdfPageController}
                                                            handleMouseMove={handleMouseMove}
                                                            setIsDrawActivated={setIsDrawActivated}
                                                        />
                                                    </div>
                                                ))}
                                                <div className="add-page-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '19px', marginBottom: '20px' }}>
                                                    <Tippy content={<span className='text-[12px]'>Add Page</span>}>
                                                        <button className={`bg-[#f6f7f8] hover:bg-[rgb(0,0,0,0.02)] cursor-pointer p-3 text-[14px] text-[#000] font-[500] border-[1px] solid border-[rgba(0,0,0,0.2)] flex items-center justify-center ${currentView === 'gridview' ? 'w-[60px] h-[60px] rounded-full text-[25px]' : 'max-h-[45px] w-full rounded'}`}
                                                            onClick={newComponent}>
                                                            + {currentView === 'thumbnail' && "Add Page"}
                                                        </button>
                                                    </Tippy>
                                                </div>
                                                {/* <div className='min-h-[35px] w-full bg-[#f6f7f8] fixed bottom-1 right-0 z-20 flex justify-end items-center'>
                                                    <div className='absolute w-[45%] h-full flex items-center justify-end'>
                                                        <div className='flex gap-2 items-center justify-end px-3'>
                                                            <span className='text-[#000] font-[500] text-[15px] text-nowrap'>Pages {currentPage}/{components.length || 1}</span>
                                                            <p className='flex gap-2 ml-[12px] text-[#000]'>
                                                                <Tippy content={<span className='text-[12px]'>Thumbnail view</span>} interactive={true}>
                                                                    <button className={`${currentView === 'thumbnail' ? "bg-gradient-to-r from-[#00c4cc] to-[#7d2ae8] text-white" : "bg-none"} hover:bg-gray-200 rounded-md px-1`} onClick={() => setCurrentView('thumbnail')}>
                                                                        <span className="TcNIhA">
                                                                            <span aria-hidden="true" className="NA_Img dkWypw">
                                                                                <svg width="25" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v8.5a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4Zm1.5 8V4.5h15V12h-15ZM4 21a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H4Zm.5-4.5h4v3h-4v-3Zm11 3h4v-3h-4v3Zm-1.5-3v3h-4v-3h4Z" fill="currentColor"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </button>
                                                                </Tippy>
                                                                <Tippy content={<span className='text-[12px]'>Grid view</span>}>
                                                                    <button className={`${currentView === 'gridview' ? "bg-gradient-to-r from-[#00c4cc] to-[#7d2ae8] text-white" : "bg-none"} hover:bg-gray-200 rounded-md p-1`} onClick={() => setCurrentView('gridview')}>
                                                                        <span className="TcNIhA">
                                                                            <span aria-hidden="true" className="NA_Img dkWypw">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 24 24">
                                                                                    <path fill="currentColor" d="M5.5 4h4c.83 0 1.5.67 1.5 1.5v4c0 .83-.67 1.5-1.5 1.5h-4A1.5 1.5 0 0 1 4 9.5v-4C4 4.67 4.67 4 5.5 4zm9 0h4c.83 0 1.5.67 1.5 1.5v4c0 .83-.67 1.5-1.5 1.5h-4A1.5 1.5 0 0 1 13 9.5v-4c0-.83.67-1.5 1.5-1.5zm0 9h4c.83 0 1.5.67 1.5 1.5v4c0 .83-.67 1.5-1.5 1.5h-4a1.5 1.5 0 0 1-1.5-1.5v-4c0-.83.67-1.5 1.5-1.5zm-9 0h4c.83 0 1.5.67 1.5 1.5v4c0 .83-.67 1.5-1.5 1.5h-4A1.5 1.5 0 0 1 4 18.5v-4c0-.83.67-1.5 1.5-1.5zm0-7.5v4h4v-4h-4zm9 0v4h4v-4h-4zm0 9v4h4v-4h-4zm-9 0v4h4v-4h-4z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </button>
                                                                </Tippy>
                                                                <Tippy content={<span className='text-[12px]'>Layout</span>}>
                                                                    <div className='p-1 flex justify-center items-center bg-gray-300 rounded-md min-w-[28px] min-h-[28px]'>
                                                                        <button className={`w-[25px] h-[25px] mt-[2px] bg-[#992bff] rounded-sm text-[12px] text-white flex items-center justify-center font-bold shadow-lg border-[2px] border-solid border-[rgba(0,0,0,0.4)]`} onClick={() => { setLayout(prevState => ({ ...prevState, currentPreview: layout.currentPreview === 'yearbook' ? 'businessCard' : 'yearbook' })) }}><TbLayoutSidebarRightCollapse className='text-[20px]' /></button>
                                                                    </div>
                                                                </Tippy>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    currentComponent &&
                                    <div
                                        ref={containerRef}
                                        className="fixed w-[23%] h-[430px] bg-[#f6f7f8] p-3 z-10 mt-0 border-[1px] border-solid rounded-md shadow-lg overflow-y-scroll scrollbar-hide"
                                        // style={{ top: `${position.top}px`, right: `${position.right}px`, userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}
                                        style={{ top: `130px`, right: `80px`, userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}
                                        onMouseDown={handleMouseDown}
                                        onMouseMove={handleContainerMouseMove}
                                        onMouseUp={handleMouseUp}
                                    >

                                        <div className="grid grid-cols-2 gap-x-2 pdfToolContainer">
                                            <div className='mb-4 col-span-2'>
                                                <label className="flex items-center justify-between text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                                    <span>Design Controls</span> <span className='cursor-pointer border-[1px] border-solid p-1 rounded-full shadow-md hover:bg-[#7d2ae8] hover:text-white transition-all duration-150 ease-in-out' onClick={() => handlePDFElementDelete()}><MdDelete className='text-[20px] transition-all duration-150 ease-in-out' /></span>
                                                </label>
                                            </div>

                                            <div className="-mt-5">
                                                <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                                    <svg className="w-[15px] h-5 mr-2 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5h18M3 12h18m-7 7h7" />
                                                    </svg>
                                                    Fontsize
                                                </label>
                                                <input
                                                    type="range"
                                                    min={0}
                                                    max={60}
                                                    value={editOptions.fontSize}
                                                    className="w-full"
                                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, fontSize: e.target.value }))}
                                                />
                                            </div>

                                            <div className={`-mt-5`}>
                                                <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                                    <svg className="w-[15px] h-5 mr-2 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2v10m0 0l-4-4m4 4l4-4" />
                                                    </svg>
                                                    Rotation (°)
                                                </label>
                                                <input
                                                    type="range"
                                                    min={0}
                                                    max={180}
                                                    value={editOptions.rotate}
                                                    className="w-full"
                                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, rotate: e.target.value }))}
                                                />
                                            </div>

                                            <div className="mt-2">
                                                <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                                    <svg className="w-[15px] h-5 mr-2 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m0 0h-4m4 0h4" />
                                                    </svg>
                                                    Transparency
                                                </label>
                                                <input
                                                    type="range"
                                                    min={0}
                                                    max={1}
                                                    step="0.1"
                                                    value={editOptions.opacity}
                                                    className="w-full"
                                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, opacity: e.target.value }))}
                                                />
                                            </div>

                                            <div className="mt-2">
                                                <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                                    <svg className="w-[15px] h-5 mr-2 -mt-1 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 4h6a4 4 0 010 8H6V4zm0 12h6a4 4 0 010 8H6v-8z" />
                                                    </svg>
                                                    Bold
                                                </label>
                                                <input
                                                    type="range"
                                                    min={0}
                                                    max={1000}
                                                    value={editOptions.bold}
                                                    className="w-full"
                                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, bold: e.target.value }))}
                                                />
                                            </div>

                                            <div className="mt-2 col-span-2">
                                                <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                                    Font Family
                                                </label>
                                                <select
                                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, fontFamily: e.target.value }))}
                                                    className="w-full px-4 py-2 h-[44px] border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5b53e0] transition duration-200 ease-in-out"
                                                >
                                                    <option value="'Arial', sans-serif">Arial</option>
                                                    <option value="'Arial Black', sans-serif">Arial Black</option>
                                                    <option value="'Comic Sans MS', cursive">Comic Sans MS</option>
                                                    <option value="'Courier New', monospace">Courier New</option>
                                                    <option value="'Georgia', serif">Georgia</option>
                                                    <option value="'Times New Roman', serif">Times New Roman</option>
                                                    <option value="'Verdana', sans-serif">Verdana</option>
                                                    <option value="'Tahoma', sans-serif">Tahoma</option>
                                                    <option value="'Trebuchet MS', sans-serif">Trebuchet MS</option>
                                                    <option value="'Lucida Sans Unicode', sans-serif">Lucida Sans Unicode</option>
                                                    <option value="'Palatino Linotype', serif">Palatino Linotype</option>
                                                    <option value="'Impact', sans-serif">Impact</option>
                                                    <option value="'Segoe UI', sans-serif">Segoe UI</option>
                                                    <option value="'Helvetica Neue', sans-serif">Helvetica Neue</option>
                                                    <option value="'Merryweather', serif">Merryweather</option>
                                                    <option value="'Open Sans', sans-serif">Open Sans</option>
                                                    <option value="'Roboto', sans-serif">Roboto</option>
                                                    <option value="'Lato', sans-serif">Lato</option>
                                                    <option value="'Montserrat', sans-serif">Montserrat</option>
                                                    <option value="'Source Sans Pro', sans-serif">Source Sans Pro</option>
                                                    <option value="'Raleway', sans-serif">Raleway</option>
                                                    <option value="'Oswald', sans-serif">Oswald</option>
                                                    <option value="'PT Sans', sans-serif">PT Sans</option>
                                                    <option value="'Dancing Script', cursive">Dancing Script</option>
                                                    <option value="'Cursive', cursive">Cursive</option>
                                                </select>
                                            </div>

                                            <div className="mt-2 col-span-2">
                                                <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                                    <svg className="w-[15px] h-5 mr-2 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h18v18H3V3z" />
                                                    </svg>
                                                    Font Color (Hex)
                                                </label>
                                                <div className='flex rounded-md overflow-hidden border border-gray-300 shadow-sm'>
                                                    <input
                                                        type="color"
                                                        value={`${editOptions.color}`}
                                                        className='h-[44px] w-[60px] cursor-pointer p-1 border-r border-gray-300 bg-gradient-to-r from-custom-green/70 to-custom-purple/30'
                                                        onChange={(e) => setEditOptions(prevState => ({ ...prevState, color: e.target.value }))}
                                                    />
                                                    <input
                                                        type="text"
                                                        value={`${editOptions.color}`}
                                                        className="w-full px-4 py-2 border-none focus:outline-none transition duration-200 ease-in-out text-gray-700"
                                                        placeholder="#000"
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-2 col-span-2">
                                                <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                                    <svg className="w-[15px] h-5 mr-2 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h18v18H3V3z" />
                                                    </svg>
                                                    Background Color (Hex)
                                                </label>
                                                <div className='flex rounded-md overflow-hidden border border-gray-300 shadow-sm'>
                                                    <input
                                                        type="color"
                                                        className='h-[44px] w-[60px] cursor-pointer p-1 border-r border-gray-300 bg-gradient-to-r from-custom-green/70 to-custom-purple/30'
                                                        value={`${editOptions.backgroundColor}`}
                                                        onChange={(e) => setEditOptions(prevState => ({ ...prevState, backgroundColor: e.target.value }))}
                                                    />
                                                    <input
                                                        type="text"
                                                        value={`${editOptions.backgroundColor}`}
                                                        className="w-full px-4 py-2 border-none focus:outline-none transition duration-200 ease-in-out text-gray-700"
                                                        placeholder="#000"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-2 col-span-2">
                                                <label className="flex items-center text-gray-700 font-medium mb-2">
                                                    Text Styling & Alignment Controls
                                                </label>
                                                <div className="flex flex-wrap justify-between gap-2 px-1">
                                                    <button
                                                        className={`${editOptions?.italic && "bg-gradient-to-r from-custom-green/70 to-custom-purple/30 text-white"} text-[rgba(0,0,0,0.6)] font-medium py-2 px-4 rounded transition duration-200 border border-gray-500`}
                                                        onClick={() => setEditOptions(prevState => ({ ...prevState, italic: !prevState.italic }))}
                                                        title="Italic">
                                                        <FaItalic />
                                                    </button>
                                                    <button
                                                        className={`${editOptions?.underline && "bg-gradient-to-r from-custom-green/70 to-custom-purple/30 text-white"} text-[rgba(0,0,0,0.6)] font-medium py-2 px-4 rounded transition duration-200 border border-gray-500`}
                                                        onClick={() => setEditOptions(prevState => ({ ...prevState, underline: !prevState.underline }))}
                                                        title="Underline">
                                                        <FaUnderline />
                                                    </button>
                                                    <button
                                                        className={`${editOptions?.left && "bg-gradient-to-r from-custom-green/70 to-custom-purple/30 text-white"} text-[rgba(0,0,0,0.6)] font-medium py-2 px-4 rounded transition duration-200 border border-gray-500`}
                                                        onClick={() => setEditOptions(prevState => ({ ...prevState, left: !prevState.left, middle: false, right: false }))}
                                                        title="Align Left">
                                                        <FaAlignLeft />
                                                    </button>
                                                    <button
                                                        className={`${editOptions?.middle && "bg-gradient-to-r from-custom-green/70 to-custom-purple/30 text-white"} text-[rgba(0,0,0,0.6)] font-medium py-2 px-4 rounded transition duration-200 border border-gray-500`}
                                                        onClick={() => setEditOptions(prevState => ({ ...prevState, middle: !prevState.middle, left: false, right: false }))}
                                                        title="Align Center">
                                                        <FaAlignCenter />
                                                    </button>
                                                    <button
                                                        className={`${editOptions?.right && "bg-gradient-to-r from-custom-green/70 to-custom-purple/30 text-white"} text-[rgba(0,0,0,0.6)] font-medium py-2 px-4 rounded transition duration-200 border border-gray-500`}
                                                        onClick={() => setEditOptions(prevState => ({ ...prevState, right: !prevState.right, left: false, middle: false }))}
                                                        title="Align Right">
                                                        <FaAlignRight />
                                                    </button>
                                                    <button
                                                        className={`${editOptions?.top && "bg-gradient-to-r from-custom-green/70 to-custom-purple/30 text-white"} text-[rgba(0,0,0,0.6)] font-medium py-2 px-4 rounded transition duration-200 border border-gray-500`}
                                                        onClick={() => setEditOptions(prevState => ({ ...prevState, top: !prevState.top, middleVertical: false, bottom: false }))}
                                                        title="Align Top">
                                                        <MdVerticalAlignTop />
                                                    </button>
                                                    <button
                                                        className={`${editOptions?.middleVertical && "bg-gradient-to-r from-custom-green/70 to-custom-purple/30 text-white"} text-[rgba(0,0,0,0.6)] font-medium py-2 px-4 rounded transition duration-200 border border-gray-500`}
                                                        onClick={() => setEditOptions(prevState => ({ ...prevState, middleVertical: !prevState.middleVertical, top: false, bottom: false }))}
                                                        title="Align Middle">
                                                        <MdOutlineVerticalAlignCenter />
                                                    </button>
                                                    <button
                                                        className={`${editOptions?.bottom && "bg-gradient-to-r from-custom-green/70 to-custom-purple/30 text-white"} text-[rgba(0,0,0,0.6)] font-medium py-2 px-4 rounded transition duration-200 border border-gray-500`}
                                                        onClick={() => setEditOptions(prevState => ({ ...prevState, bottom: !prevState.bottom, top: false, middleVertical: false }))}
                                                        title="Align Bottom">
                                                        <MdVerticalAlignBottom />
                                                    </button>
                                                </div>
                                            </div>
                                            {(selectedElement.tagName === "IMG" || selectedElement.firstChild.tagName === "IMG") &&
                                                <React.Fragment>
                                                    <div className='mt-2 col-span-2'>
                                                        <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                                            Visual Filter Controls
                                                        </label>
                                                    </div>
                                                    <div className="mt-1">
                                                        <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                                            <RiBlurOffFill className='mr-2 text-[18px] text-[rgba(0,0,0,0.6)]' />
                                                            Blur (px)
                                                        </label>
                                                        <input
                                                            type="range"
                                                            min={0}
                                                            max={10}
                                                            value={editOptions.blur}
                                                            className="w-full"
                                                            onChange={(e) => setEditOptions(prevState => ({ ...prevState, blur: e.target.value }))}
                                                        />
                                                    </div>
                                                    <div className="mt-1">
                                                        <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                                            <MdBrightnessMedium className='mr-2 text-[18px] text-[rgba(0,0,0,0.6)]' />
                                                            Brightness (%)
                                                        </label>
                                                        <input
                                                            type="range"
                                                            min={50}
                                                            max={200}
                                                            value={editOptions.brightness}
                                                            className="w-full"
                                                            onChange={(e) => setEditOptions(prevState => ({ ...prevState, brightness: e.target.value }))}
                                                        />
                                                    </div>
                                                    <div className="mt-1">
                                                        <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                                            <MdContrast className='mr-2 text-[18px] text-[rgba(0,0,0,0.6)]' />
                                                            Contrast (%)
                                                        </label>
                                                        <input
                                                            type="range"
                                                            min={50}
                                                            max={200}
                                                            value={editOptions.contrast}
                                                            className="w-full"
                                                            onChange={(e) => setEditOptions(prevState => ({ ...prevState, contrast: e.target.value }))}
                                                        />
                                                    </div>
                                                    <div className="mt-1">
                                                        <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                                            <MdOutlineGradient className='mr-2 text-[18px] text-[rgba(0,0,0,0.6)]' />
                                                            Grayscale (%)
                                                        </label>
                                                        <input
                                                            type="range"
                                                            min={0}
                                                            max={200}
                                                            value={editOptions.grayscale}
                                                            className="w-full"
                                                            onChange={(e) => setEditOptions(prevState => ({ ...prevState, grayscale: e.target.value }))}
                                                        />
                                                    </div>
                                                    <div className="mt-1">
                                                        <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                                            <MdInvertColors className='mr-2 text-[18px] text-[rgba(0,0,0,0.6)]' />
                                                            Invert (%)
                                                        </label>
                                                        <input
                                                            type="range"
                                                            min={0}
                                                            max={100}
                                                            value={editOptions.invert}
                                                            className="w-full"
                                                            onChange={(e) => setEditOptions(prevState => ({ ...prevState, invert: e.target.value }))}
                                                        />
                                                    </div>
                                                    <div className="mt-1">
                                                        <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                                            <MdInvertColors className='mr-2 text-[18px] text-[rgba(0,0,0,0.6)]' />
                                                            Saturate (%)
                                                        </label>
                                                        <input
                                                            type="range"
                                                            min={0}
                                                            max={20}
                                                            value={editOptions.saturate}
                                                            className="w-full"
                                                            onChange={(e) => setEditOptions(prevState => ({ ...prevState, saturate: e.target.value }))}
                                                        />
                                                    </div>
                                                    <div className="mt-1">
                                                        <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                                            <MdPhotoFilter className='mr-2 text-[18px] text-[rgba(0,0,0,0.6)]' />
                                                            Sepia (%)
                                                        </label>
                                                        <input
                                                            type="range"
                                                            min={0}
                                                            max={20}
                                                            value={editOptions.sepia}
                                                            className="w-full"
                                                            onChange={(e) => setEditOptions(prevState => ({ ...prevState, sepia: e.target.value }))}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                className='fixed z-50'
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}
