import React, { useEffect, useState } from 'react'
import { SiCssdesignawards } from "react-icons/si";
import { FaTools } from 'react-icons/fa'
import { CgTemplate } from 'react-icons/cg'
import { RiAdvertisementLine } from 'react-icons/ri'
import Slide from '../components/Home/Slide'
import offer1 from '../assets/Offer/offer1.jpg'
import offer2 from '../assets/Offer/offer2.png'
import Template from '../components/Home/Template'
import MemorialSlide from '../components/Home/MemorialSlide'
import PopularSlide from '../components/Home/PopularSlide'
import Skeleton from './skeleton/Skeleton'

const Home = () => {
  const [loadingTime, setLoadingTime] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingTime(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [])

   return (
    <div className='mb-8 sm:mb-10 md:mb-14 lg:mb-16'>
      <Slide/>
      <div className='px-5'>
        <div className='mt-5 grid grid-cols-1 sm:hidden gap-5 w-full'>

          <div className='flex items-center border pl-5 py-3'>
            {loadingTime ? (
                <div className='flex gap-6 items-center'>
                  <Skeleton width='40px' height='45px'/>
                  <Skeleton width='140px' height='30px' marginBottom='8px'/>
                </div>
            ) : (
              <>
                <div className='w-12'>
                  <SiCssdesignawards className='text-3xl text-orange-300' />
                </div>
                <p className='text-lg font-bold text-gray-600'>Free Online Designing</p>
              </>
            )} 
          </div>

          <div className='flex items-center pl-5 border py-3'>
            {loadingTime ? (
                <div className='flex gap-6 items-center'>
                  <Skeleton width='40px' height='45px'/>
                  <Skeleton width='160px' height='30px' marginBottom='8px'/>
                </div>
            ) : (
              <>
                <div className='w-12'>
                  <CgTemplate className='text-3xl text-orange-300'/>
                </div>
                <p className='text-lg font-bold text-gray-600'>Free Designing Templates</p>
              </>
            )}
          </div>

          <div className='flex items-center border pl-5 py-3'>
            {loadingTime ? (
                <div className='flex gap-6 items-center'>
                  <Skeleton width='40px' height='45px'/>
                  <Skeleton width='180px' height='30px' marginBottom='8px'/>
                </div>
            ) : (
              <>
                <div className='w-12'>
                  <FaTools className='text-3xl text-orange-300'/>
                </div>
                <p className='text-lg font-bold text-gray-600'>Free Tools and Resources</p>
              </>
            )}
          </div>

          <div className='flex items-center pl-5 border py-3'>
            {loadingTime ? (
                <div className='flex gap-6 items-center'>
                  <Skeleton width='40px' height='45px'/>
                  <Skeleton width='130px' height='30px' marginBottom='8px'/>
                </div>
            ) : (
              <>
                <div className='w-12'>
                  <RiAdvertisementLine className='text-3xl text-orange-300'/>
                </div>
                <p className='text-lg font-bold text-gray-600'>Free Business Promo</p>
              </>
            )}
          </div>
        </div>
          
        <div className='mt-7 lg:mt-10 hidden sm:grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-6 w-full'>
          <div className='flex items-center justify-start md:justify-center border px-3 md:px-5 py-3 md:py-5'>
            {loadingTime ? (
                <div className='flex gap-6'>
                  <Skeleton width='50px' height='60px'/>
                  <div className='flex flex-col'>
                    <Skeleton width='130px' height='20px' marginBottom='8px'/>
                    <Skeleton width='50px' height='20px'/>
                  </div>
                </div>
            ) : (
              <>

                
                <SiCssdesignawards className='text-orange-300 text-4xl md:text-5xl lg:text-6xl mr-3' />
                <p className='text-lg md:text-xl font-bold text-gray-600'>Free Online Designing</p>
              </>
            )}
          </div>
          <div className='flex items-center justify-start md:justify-center border px-3 md:px-5 py-3 md:py-5'>
            {loadingTime ? (
                  <div className='flex gap-6'>
                    <Skeleton width='50px' height='60px'/>
                    <div className='flex flex-col'>
                      <Skeleton width='130px' height='20px' marginBottom='8px'/>
                      <Skeleton width='50px' height='20px'/>
                    </div>
                  </div>
              ) : (
                <>
                  <CgTemplate className='text-orange-300 text-4xl md:text-5xl lg:text-6xl mr-3'/>
                  <p className='text-lg md:text-xl font-bold text-gray-600'>Free Designing Templates</p>
                </>
              )}
          </div>
          <div className='flex items-center justify-start md:justify-center border px-3 md:px-5 py-3 md:py-5'>
            {loadingTime ? (
                <div className='flex gap-6'>
                  <Skeleton width='50px' height='60px'/>
                  <div className='flex flex-col'>
                    <Skeleton width='130px' height='20px' marginBottom='8px'/>
                    <Skeleton width='50px' height='20px'/>
                  </div>
                </div>
              ) : (
                <>
                  <FaTools className='text-orange-300 text-3xl md:text-4xl lg:text-5xl mr-5'/>
                  <p className='text-lg md:text-xl font-bold text-gray-600'>Free Tools and Resources</p>
                </>
              )}
          </div>
          <div className='flex items-center justify-start md:justify-center border px-3 md:px-5 py-3 md:py-5'>
            {loadingTime ? (
                <div className='flex gap-6'>
                  <Skeleton width='50px' height='60px'/>
                  <div className='flex flex-col'>
                    <Skeleton width='130px' height='20px' marginBottom='8px'/>
                    <Skeleton width='50px' height='20px'/>
                  </div>
                </div>
              ) : (
                <>
                  <RiAdvertisementLine className='text-orange-300 text-4xl md:text-5xl lg:text-6xl mr-4'/>
                  <p className='text-lg md:text-xl font-bold text-gray-600'>Free Business Promo</p>
                </>
              )}
          </div>
        </div>

        <div className='mt-6 sm:mt-7 lg:mt-10 w-full flex flex-col items-center'>
          <div className='flex flex-col sm:text-center w-full sm:w-11/12 lg:w-3/5'>
            {loadingTime ? (
              <div>
                <Skeleton width='100%' height='36px' marginBottom='16px'/>
                <Skeleton width='100%' height='18px' marginBottom='6px'/>
                <Skeleton width='100%' height='18px' marginBottom='6px'/>
                <Skeleton width='100%' height='18px' marginBottom='6px'/>
                <Skeleton width='100%' height='18px' marginBottom='6px'/>
                <Skeleton width='50%' height='18px' marginBottom='6px'/>
              </div>)
            : (
              <div>
                <h2 className='text-2xl md:text-[26px] lg:text-[28px] xl:text-3xl text-[#1C1C1C] leading-snug font-semibold mb-2 md:mb-3 lg:mb-4'>Digital printing company for all your Business <br className='hidden md:block lg:hidden'/>
                and marketing needs</h2>
                <p className='text-[#6F6F6F] text-lg leading-relaxed'>A dependable printing company that manages prints, business cards, brochures, brands, and custom items is invaluable in the fast-paced world of business. We are aware of how crucial it is to have premium print materials from online printing services, like brochures and business cards, to distinguish your brand and prints. A lasting impression on your audience may be made with high-quality printing on anything from business cards to banners, brochures, postcards, posters, and prints.</p>
              </div>
            )}
          </div>
        </div>

        <div className='mt-5 sm:mt-7 lg:mt-10 mb-3 sm:mb-4 lg:mb-6'>
          <div className='flex gap-3 items-center justify-center'>
            {loadingTime ? (
              <div className='w-[300px] sm:[400px] h-9'>
                <Skeleton width='100%' height='100%' marginBottom='8px'/>
              </div>
            ) : (
              <>
              <p className='w-6 sm:w-10 h-[1.5px] bg-black'></p>
              <h1 className='text-2xl md:text-[26px] lg:text-3xl xl:text-4xl font-semibold whitespace-nowrap'>Popular Products</h1>
              <p className='w-6 sm:w-10 h-[1.5px] bg-black'></p>
              </>
            )}
          </div>
        </div>
        <div className='md:px-5'>
          <MemorialSlide />
        </div>

        <div className='flex justify-center'>
          <div className='w-full min-[560px]:w-4/5 md:w-full mt-5 sm:mt-7 lg:mt-10 grid grid-cols-1 md:grid-cols-2 gap-8 sm:px-3 lg:px-5'>
            {loadingTime ? (
              <>
              <div className='flex max-[510px]:flex-row-reverse overflow-hidden items-center justify-between gap-3 bg-[#EDF1FF]'>
                <div className='h-40'>
                  <Skeleton width='160px' height='100%'/>
                </div>
                <div className='flex flex-col max-[510px]:items-start items-end justify-center py-5 sm:py-3 gap-1 sm:gap-2 pl-5 min-[560px]:pl-0 min-[510px]:pr-8'>
                  <Skeleton width='100px' height='16px' marginBottom='14px'/>
                  <Skeleton width='200px' height='32px' marginBottom='14px'/>
                  <Skeleton width='120px' height='40px'/>
                </div>
              </div>
    
              <div className='flex flex-row-reverse overflow-hidden items-center justify-between gap-3 bg-[#EDF1FF]'>
                <div className='h-40'>
                  <Skeleton width='160px' height='100%'/>
                </div>
                <div className='flex flex-col items-start justify-center py-5 sm:py-3 gap-1 sm:gap-2 pl-5 min-[510px]:pl-8'>
                  <Skeleton width='100px' height='16px' marginBottom='14px'/>
                  <Skeleton width='200px' height='32px' marginBottom='14px'/>
                  <Skeleton width='120px' height='40px'/>
                </div>
              </div>
              </>
            ) : (
              <>
              <div className='flex max-[510px]:flex-row-reverse items-center justify-between gap-3 bg-[#EDF1FF]'>
                <div className='h-40'>
                  <img src={offer1} alt="offer product 1" className='h-full w-full'/>
                </div>
                <div className='flex flex-col max-[510px]:items-start items-end justify-center py-5 sm:py-3 gap-1 sm:gap-2 pl-5 min-[560px]:pl-0 min-[510px]:pr-8'>
                  <h2 className='text-orange-400 font-medium text-lg'>10% OFF ON</h2>
                  <h1 className='text-[28px] lg:text-3xl font-semibold'>Memorial <br className='hidden min-[555px]:block min-[595px]:hidden md:block min-[1075px]:hidden'/>Products</h1>
                  <button className='border border-spacing-1 mt-2 px-4 py-2 text-orange-400 border-orange-400 hover:bg-orange-400 hover:text-black duration-200'>Shop Now</button>
                </div>
              </div>
    
              <div className='flex flex-row-reverse items-center justify-between gap-3 bg-[#EDF1FF]'>
                <div className='h-40'>
                  <img src={offer2} alt="offer product 2" className='h-full'/>
                </div>
                <div className='flex flex-col items-start justify-center py-5 sm:py-3 gap-1 sm:gap-2 pl-5 min-[510px]:pl-8'>
                  <h2 className='text-orange-400 font-medium text-lg'>10% OFF ON</h2>
                  <h1 className='text-[28px] lg:text-3xl font-semibold'>Business cards</h1>
                  <button className='border border-spacing-1 mt-2 px-4 py-2 text-orange-400 border-orange-400 hover:bg-orange-400 hover:text-black duration-200'>Shop Now</button>
                </div>
              </div>
              </>
            )}
          </div>
        </div>

        <div className='mt-6 sm:mt-7 lg:mt-10 mb-3 sm:mb-4 lg:mb-6'>
          <div className='flex gap-3 items-center justify-center'>
            {loadingTime ? (
              <div className='w-[300px] sm:[400px] h-9'>
                <Skeleton width='100%' height='100%' marginBottom='8px'/>
              </div>
            ) : (
              <>
              <p className='w-6 sm:w-10 h-[1.5px] bg-black'></p>
              <h1 className='text-2xl md:text-[26px] lg:text-3xl xl:text-4xl font-semibold whitespace-nowrap'>Memorial Products</h1>
              <p className='w-6 sm:w-10 h-[1.5px] bg-black'></p>
              </>
            )}
          </div>
        </div>
        <div className='md:px-5'>
          <MemorialSlide />
        </div>

        <Template />
      </div>
    </div>
  )
}

export default Home