import React, { useEffect, useState } from 'react'
import SideBar from './SideBar'
import Header from './Header'
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import product from '../../assets/Product/product8.jpg'
import { MdFileDownload } from "react-icons/md";
import { getCurrentOrderDetailsAPI } from '../../serverApi/server';

const CurrentOrders = () => {
  const [popup, setPopup] = useState(false);
  const dispatchPopup = () => {
    setPopup(true);
  }

  useEffect(() => {
    if (popup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [popup]);

  const [ordersDetails, setOrdersDetails] = useState();

  const fetchOrdersDetails = async () => {
    const user_type= "admin";
    const user_id = null;
    try {
      const response = await getCurrentOrderDetailsAPI(user_type, user_id);
      setOrdersDetails(response.data.all_orders_details);
    } catch (error) {
      console.error('Error fetching Admin OrdersDetails:',error);
      
    }
  }

  useEffect(() => {
    fetchOrdersDetails();
  }, [])

  return (
    <div className="flex w-full h-screen">
      <div className="fixed h-full">
        <SideBar />
      </div>

      <div className="ml-64 flex-1 overflow-y-auto">
        <Header />
        <section className="bg-[#F5F7FD] px-7 py-6">
          <div className="text-blue-950 flex items-center gap-6">
            <h1 className="pr-6 text-2xl font-medium border-r">
              Admin Users
            </h1>
            <div className="flex text-sm items-center gap-3">
              <button>
                <SlHome />
              </button>
              <SlArrowRight />
              <button>
                Orders
              </button>
              <SlArrowRight />
              <button>
                Current Orders
              </button>
            </div>
          </div>

          <section className='bg-white mt-10 py-4 rounded-lg shadow-[0px_0px_15px_0px_rgba(0,0,0,0.1)]'>
            <div className='px-5 md:px-7 pt-4 flex items-center justify-between gap-2'>
              <div className='flex items-center gap-1 font-light'>
                <p className='text-neutral-700'>show</p>
                <select name="entries" className='border text-sm rounded-md focus:outline-none'>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <p className='text-neutral-700'>entries</p>
              </div>
              <form className='flex items-center gap-1 font-light'>
                <label htmlFor="entries-search" className='text-neutral-700'>Search:</label>
                <input type="text" className='border-2 w-auto sm:w-40 text-sm px-2 py-[2px] rounded focus:outline-none'/>
              </form>
            </div>

            <div className="overflow-x-auto sm:px-5 md:px-7">
              <table className="min-w-full bg-white mt-4" style={{ borderCollapse: 'separate', borderSpacing: '2px' }}>
                <thead>
                  <tr>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">FULL <br />NAME</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">PHONE NUMBER</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">EMAIL</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">ADDRESS</th>
                    
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">PRODUCT IMAGE & FIELDS</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">ORDER <br />DATE</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">ORDER <br />ID</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">AMOUNT</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">PAYMENT <br />STATUS</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">ORDER <br />STATUS</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">DOWNLOAD <br />PHOTOS</th>
                  </tr>
                </thead>
                <tbody className='text-neutral-800 text-sm'>
                  { ordersDetails && ordersDetails.map((order) => {
                    const date = order.order_date.split("T")[0];
                    const time = order.order_date.split("T")[1].split(".")[0];

                    return (
                      <tr>
                        <td className="px-4 py-6 font-normal border border-[#48ABF7]">{order.first_name} {order.last_name}</td>
                        <td className="px-4 py-6 font-normal border border-[#48ABF7]">{order.phone}</td>
                        <td className="px-4 py-6 font-normal border border-[#48ABF7]">{order.email}</td>
                        <td className="px-4 py-6 font-normal border border-[#48ABF7]">
                          {order.address},
                          {order.city},
                          {order.state},
                          {order.zip}
                        </td>

                        <td className="px-4 py-2 border border-[#48ABF7]">
                          { order.all_cart_option_details && order.all_cart_option_details.map((option) => (
                            <table className='border border-[#48ABF7]'>
                              <tr>
                                <td className='border border-[#48ABF7] p-2'>
                                  <div className='w-28 h-28'>
                                    <img src={option.category_menu_option_image} alt="ordered-product" className='w-full h-full'/>
                                  </div>
                                </td>

                                <td>
                                    <table className='border border-[#48ABF7] w-full' >
                                  { option.cart_option_serializer && option.cart_option_serializer.map((field) => (
                                      <tr>
                                        <td className='border border-[#48ABF7] px-1 py-1'>{field.field_name}</td>
                                        <td className='border border-[#48ABF7] px-1 py-1'>{field.option_name}</td>
                                      </tr>
                                  )) }
                                  </table> 
                                </td>

                              </tr>
                            </table>
                          )) }
                        </td>

                        <td className="px-4 py-2 border border-[#48ABF7]">{date}, {time}</td>
                        <td className="px-4 py-2 border border-[#48ABF7]">5278</td>
                        <td className="px-4 py-2 border border-[#48ABF7]">{order.amount}</td>
                        <td className="px-4 py-2 border border-[#48ABF7]"><button className='px-3 py-2 rounded bg-green-600 hover:bg-green-700 duration-200 text-white inline'>{order.payment_status}</button></td>
                        <td className="px-4 py-2 border border-[#48ABF7]"><button onClick={dispatchPopup} className='px-3 py-2 rounded bg-blue-600 hover:bg-blue-800 duration-200 text-white inline'>Action</button></td>
                        <td className="px-4 py-2 border border-[#48ABF7]">
                          <button className='p-1 flex justify-center w-full rounded-full hover:bg-neutral-100 duration-200'>
                            <MdFileDownload className='text-xl'/>
                          </button>
                        </td>
                      </tr>

                  )})}
                </tbody>
              </table>
            </div>

            <div className='px-5 md:px-7 pt-6 pb-3 text-neutral-700 text-sm font-light flex items-center justify-between gap-2'>
              <p>Showing 1 to 10 of 10 entries</p>
              <div className='flex items-center gap-2'>
                <button className='px-3 py-1 rounded-full bg-neutral-200'>Previous</button>
                <p className='px-3 py-1 rounded-full bg-blue-500 text-white font-normal'>1</p>
                <button className='px-3 py-1 rounded-full bg-neutral-200'>Next</button>
              </div>
            </div>

          </section>

        </section>
        <div>
        {popup && 
        <div 
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex flex-col justify-center items-center z-[150]"
        >
          <div 
            className="w-3/4 sm:w-3/5 md:w-1/2 lg:w-2/5 xl:w-1/3 bg-white rounded-lg animate-slide-down"
          >
            <div className="border-b">
              <div className="flex border-b-4 border-b-green-600 justify-center gap-1 sm:gap-2 mx-4 my-8 lg:my-12 py-6 sm:py-8 md:py-12 rounded-md shadow-[0px_0px_6px_2px_rgba(0,0,0,0.1)]">
                <p className="text-base sm:text-lg text-neutral-600 font-medium">Are you sure you want to dispatch this item?</p>
              </div>
            </div>
            <div className="flex gap-2 justify-end px-4 py-4">
              <button 
                className="bg-neutral-500 hover:bg-neutral-600 duration-200 px-3 py-[6px] rounded text-white"
                onClick={() => setPopup(false)}
              >
                Cancel
              </button>
              <button 
                className="bg-green-500 hover:bg-green-600 duration-200 px-3 py-[6px] rounded text-white"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
        }
      </div>
      </div>
    </div>
  )
}

export default CurrentOrders