import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { useLocation } from "react-router-dom";
import { getCategorySubMenusAPI } from "../../serverApi/server";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ProductDescription = () => {
  const location = useLocation();
  const headerMenus = location.state || [];
  const [selectedCategory, setSelectedCategory] = useState(
    headerMenus.find((menu) => menu.additional_name === "memorial_funeral")
      ?.id || ""
  );
  const [categoryList, setCategoryList] = useState([]);
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const [subMenuList, setSubMenuList] = useState("");
  const handleSubMenuListChange = (e) => {
    setSubMenuList(e.target.value);
  };

  const fetchCategorySubMenus = async () => {
    try {
      const getCategorySubMenusAPIResponse = await getCategorySubMenusAPI(
        selectedCategory
      );
      setCategoryList(
        getCategorySubMenusAPIResponse.data.categories_serializer
      );
      setSubMenuList(getCategorySubMenusAPIResponse.data.categories_serializer.find((menu) => menu.additional_name === 'bookmarks')?.id || '')
    } catch (error) {
      console.error("Error fetching sub-menus:", error);
    }
  };

  useEffect(() => {
    fetchCategorySubMenus(selectedCategory);
  }, [selectedCategory]);

    const [value, setValue] = useState("");

    const modules = {
      toolbar: [
        ["bold", "italic"], // Bold and italic buttons
        [{ list: "ordered" }, { list: "bullet" }], // Lists
        [{ size: ["small", false, "large", "huge"] }] // Font sizes
      ],
      /* keyboard: {
        bindings: {
          linebreak: {
            key: 13,
            shiftKey: true, 
            handler: function (range, context) {
              const editor = this.quill;
              editor.insertText(range.index, '\n');
              editor.setSelection(range.index + 1, 0);
            },
          },
        },
      }, */
    };
    
    const formats = [
      "bold", 
      "italic", 
      "list", 
      "bullet", 
      "size"
    ];

    const sendHTML = () => {
      console.log(value);
    }

  return (
    <div className="flex w-full h-screen">
      <div className="fixed h-full">
        <SideBar />
      </div>

      <div className="ml-64 flex-1 overflow-y-auto">
        <Header />
        <section className="bg-[#F5F7FD] px-7 py-6">
          <div className="text-blue-950 flex items-center gap-6">
            <h1 className="pr-6 text-2xl font-medium border-r">Admin Users</h1>
            <div className="flex text-sm items-center gap-3">
              <button>
                <SlHome />
              </button>
              <SlArrowRight />
              <button>Product Description</button>
            </div>
          </div>

          <section className="bg-white mt-10 py-4 rounded-lg shadow-[0px_0px_15px_0px_rgba(0,0,0,0.1)]">
            <div className="px-5 md:px-7 flex items-center gap-2">
              <div className="flex text-sm items-center gap-1 font-light">
                <p className="text-neutral-700">Filter by Category:</p>
                <select
                  className="focus:outline-none focus:border-blue-300 border rounded px-1 py-1"
                  onChange={handleCategoryChange}
                  value={selectedCategory}
                >
                  {headerMenus.map((menu) => (
                    <option key={menu.id} value={menu.id}>
                      {menu.menu}
                    </option>
                  ))}
                </select>
                <select
                  className="focus:outline-none focus:border-blue-300 border rounded px-1 py-1"
                  onChange={handleSubMenuListChange}
                  value={subMenuList}
                >
                  {categoryList &&
                    categoryList.map((menu) => (
                      <option key={menu.id} value={menu.id}>
                        {menu.category}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="mt-6 w-full max-w-[1500px] bg-white border border-black rounded-lg shadow-[0px_4px_10px_rgba(0,0,0,0.1)]  overflow-hidden">
              <div
                className="text-base text-[#333] border-none overflow-y-auto outline-none"
              >
                <ReactQuill
                  value={value}
                  onChange={setValue}
                  modules={modules}
                  formats={formats}
                  theme="snow"
                  className="custom-quill-editor h-[300px]"
                />
              </div>
            </div>

            <div className="mt-2 flex items-center justify-center">
              <button 
                className="px-3 py-2 text-white bg-blue-600 hover:bg-blue-700 duration-200 rounded"
                onClick={sendHTML}
              >
                Submit
              </button>
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

export default ProductDescription;