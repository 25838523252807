import React, { useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import product1 from '../../assets/Product/product1.jpg';
import product2 from '../../assets/Product/product2.jpg';

const PopularSlide = () => {
  const products = [
    { id: 1, img: product1, title: "Colorful Stylish Shirt", price: "$123.00", oldPrice: "$123.00" },
    { id: 2, img: product2, title: "Colorful Stylish Shirt", price: "$123.00", oldPrice: "$123.00" },
    { id: 3, img: product1, title: "Colorful Stylish Shirt", price: "$123.00", oldPrice: "$123.00" },
    { id: 4, img: product2, title: "Colorful Stylish Shirt", price: "$123.00", oldPrice: "$123.00" },
    { id: 5, img: product1, title: "Colorful Stylish Shirt", price: "$123.00", oldPrice: "$123.00" },
    { id: 6, img: product2, title: "Colorful Stylish Shirt", price: "$123.00", oldPrice: "$123.00" },
    { id: 7, img: product1, title: "Colorful Stylish Shirt", price: "$123.00", oldPrice: "$123.00" },
    { id: 8, img: product2, title: "Colorful Stylish Shirt", price: "$123.00", oldPrice: "$123.00" },
  ];

  const [isZoomed, setIsZoomed] = useState(false);

  const handleImageClick = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <div>
      <Swiper
        spaceBetween={10}
        centeredSlides={false}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        navigation={false}
        modules={[Autoplay]}
        className="mySwiper"
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 4,
          },
          1024: {
            slidesPerView: 6,
          },
        }}
      >
        {products.map((product) => (
          <SwiperSlide key={product.id}>
            <div className="relative flex flex-col items-center justify-center border p-3 cursor-pointer">
              <div
                className='relative overflow-hidden bg-transparent'
                onClick={handleImageClick}
              >
                <img
                  className={`w-full transition-transform duration-500 ease-in-out hover:scale-125 ${isZoomed ? 'scale-125' : ''}`}
                  src={product.img} alt={product.title}
                />
              </div>
              <div className="text-center p-0 pt-4 pb-3">
                <h6 className="text-truncate mb-3">{product.title}</h6>
                <div className="flex justify-center">
                  <h6>{product.price}</h6>
                  <h6 className="text-gray-500 ml-2 line-through">{product.oldPrice}</h6>
                </div>
              </div>
              <div className="flex py-3 justify-center items-center text-gray-700 gap-1 bg-gray-100 border">
                <FaEye />
                <button>
                  View Detail
                </button>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default PopularSlide;