import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { useLocation } from "react-router-dom";
import {
  getCategorySubMenusAPI,
  updateDescription,
} from "../../serverApi/server";
import { HiMiniPencilSquare } from "react-icons/hi2";

const HeaderDescription = () => {
  const location = useLocation();
  const headerMenus = location.state || [];
  const [selectedCategory, setSelectedCategory] = useState(
    headerMenus.find((menu) => menu.additional_name === "memorial_funeral")
      ?.id || ""
  );
  const [categoryList, setCategoryList] = useState([]);
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const [subMenuList, setSubMenuList] = useState("");
  const handleSubMenuListChange = (e) => {
    setSubMenuList(e.target.value);
  };

  const fetchCategorySubMenus = async () => {
    try {
      const getCategorySubMenusAPIResponse = await getCategorySubMenusAPI(
        selectedCategory
      );
      setCategoryList(
        getCategorySubMenusAPIResponse.data.categories_serializer
      );
      setSubMenuList(
        getCategorySubMenusAPIResponse.data.categories_serializer.find(
          (menu) => menu.additional_name === "bookmarks"
        )?.id || ""
      );
    } catch (error) {
      console.error("Error fetching sub-menus:", error);
    }
  };

  useEffect(() => {
    fetchCategorySubMenus(selectedCategory);
  }, [selectedCategory]);

  const [formOpen, setFormOpen] = useState(false);
  const toggleForm = () => setFormOpen(!formOpen);
  
  const [editOpen, setEditOpen] = useState(false);
  const toggleEdit = () => setEditOpen(!editOpen);

  const formheaderMenus = location.state || [];
  const [selectedFormHeader, setSelectedFormHeader] = useState(
    formheaderMenus.find((menu) => menu.additional_name === "memorial_funeral")
      ?.id || ""
  );
  const handleFormHeaderChange = (e) => {
    setSelectedFormHeader(e.target.value);
  };

  const [formCategoryList, setFormCategoryList] = useState([]);

  const [formSubMenuList, setFormSubMenuList] = useState("");
  const handleFormSubMenuListChange = (e) => {
    setFormSubMenuList(e.target.value);
  };

  useEffect(() => {
    const fetchCategorySubMenus = async () => {
      try {
        const getCategorySubMenusAPIResponse = await getCategorySubMenusAPI(
          selectedFormHeader
        );
        setFormCategoryList(
          getCategorySubMenusAPIResponse.data.categories_serializer
        );
        setFormSubMenuList(
          getCategorySubMenusAPIResponse.data.categories_serializer.find(
            (menu) => menu.additional_name === "bookmarks"
          )?.id || ""
        );
      } catch (error) {
        console.error("Error fetching FORM-sub-menus:", error);
      }
    };
    if (selectedFormHeader) {
      fetchCategorySubMenus();
    }
  }, [selectedFormHeader]);

  const [textAreaData, setTextAreaData] = useState("");

  const handleChange = (e) => {
    const value = e.target.value || "";
    setTextAreaData(value.replace(/\s{2,}/g, " "));
  };

  const [descriptionTable, setDescriptionTable] = useState({});

  const fetchResponse = async (description_type, category_id, description) => {
    try {
      const descriptionAPIResponse = await updateDescription(
        description_type,
        category_id,
        description
      );
      if (descriptionAPIResponse.data.client_status_code === 200) {
        if (descriptionAPIResponse.data.categories_serializer_data) {
          setDescriptionTable(descriptionAPIResponse.data.categories_serializer_data);
        }
      }
    } catch (error) {
      console.error("Error Creating fieldOptions:", error);
    }
  };

  const saveDescription = () => {
    const descriptionUpdate = "update_description";
    fetchResponse(descriptionUpdate, formSubMenuList, textAreaData);
    setTextAreaData("");
    toggleForm();
  };

  const fetchDescriptionData =  () => {
    const descriptionFilter = "filter_description";
    const descriptionN = null;
    fetchResponse(descriptionFilter, subMenuList, descriptionN);
  }

  useEffect(() => {
    fetchDescriptionData();
  }, [subMenuList]);

  useEffect(() => {
    const descriptionFilter = "filter_description";
    const descriptionN = null;
    fetchResponse(descriptionFilter, subMenuList, descriptionN);
  }, [subMenuList]);

  const [shouldFetchDescription, setShouldFetchDescription] = useState(false);

  const editDescription = () => {
    const descriptionUpdate = "update_description";
    fetchResponse(descriptionUpdate, subMenuList, descriptionTable.description);
    toggleEdit();  
    setShouldFetchDescription(true);
  };

  useEffect(() => {
    if (shouldFetchDescription) {
      fetchDescriptionData();
  
      setShouldFetchDescription(false);
    }
  }, [shouldFetchDescription]);

  return (
    <div className="flex w-full h-screen">
      <div className="fixed h-full">
        <SideBar />
      </div>

      <div className="ml-64 flex-1 overflow-y-auto">
        <Header />
        <section className="bg-[#F5F7FD] px-7 py-6">
          <div className="flex items-center justify-between">
            <div className="text-blue-950 flex items-center gap-6">
              <h1 className="pr-6 text-2xl font-medium border-r">
                Admin Users
              </h1>
              <div className="flex text-sm items-center gap-3">
                <button>
                  <SlHome />
                </button>
                <SlArrowRight />
                <button>Header Description</button>
              </div>
            </div>

            <button
              className="bg-blue-500 hover:bg-blue-400 duration-200 px-4 py-2 rounded text-white mr-4 md:mr-8"
              onClick={toggleForm}
            >
              Add New Description
            </button>
          </div>
          {formOpen && (
            <div
              className="overflow-y-auto fixed inset-0 h-full z-50 flex items-center justify-center bg-black bg-opacity-50"
              aria-hidden="true"
            >
              <div className="bg-white rounded-lg w-11/12 max-w-md max-h-[90vh] overflow-y-auto animate-slide-down">
                <div className="flex justify-between items-center p-4 border-b">
                  <h5 className="text-lg font-medium">
                    Add New Description
                  </h5>
                  <button
                    type="button"
                    className="text-gray-600 text-3xl hover:text-gray-900"
                    onClick={toggleForm}
                  >
                    &times;
                  </button>
                </div>
                <div className="p-4">
                  <form>
                    <div className="mb-4">
                      <label
                        htmlFor="categoryType"
                        className="block text-sm text-gray-700"
                      >
                        Header Name
                      </label>
                      <select
                        className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                        onChange={handleFormHeaderChange}
                        value={selectedFormHeader}
                      >
                        {formheaderMenus.map((menu) => (
                          <option key={menu.id} value={menu.id}>
                            {menu.menu}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="subCategory"
                        className="block text-sm text-gray-700"
                      >
                        Category
                      </label>
                      <select
                        id="subCategory"
                        name="subCategory"
                        className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                        onChange={handleFormSubMenuListChange}
                        value={formSubMenuList}
                      >
                        <option value="0" disabled>
                          Choose an option
                        </option>
                        {formCategoryList &&
                          formCategoryList.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.category}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="subCategory"
                        className="block text-sm text-gray-700"
                      >
                        Description
                      </label>
                      <textarea
                        name="textArea"
                        value={textAreaData}
                        onChange={handleChange}
                        className="h-[150px] mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm resize-none"
                      />
                    </div>
                  </form>
                </div>
                <div className="flex justify-end items-center p-4 border-t">
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-400 duration-200 px-3 py-2 mr-3 text-white rounded"
                    onClick={toggleForm}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="bg-blue-500 hover:bg-blue-400 duration-200 px-3 py-2 text-white rounded"
                    onClick={saveDescription}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}

          {editOpen && (
            <div
              className="overflow-y-auto fixed inset-0 h-full z-50 flex items-center justify-center bg-black bg-opacity-50"
              aria-hidden="true"
            >
              <div className="bg-white rounded-lg w-11/12 max-w-md max-h-[90vh] overflow-y-auto animate-slide-down">
                <div className="flex justify-between items-center p-4 border-b">
                  <h5 className="text-lg font-medium">
                    Edit Product Description
                  </h5>
                  <button
                    type="button"
                    className="text-gray-600 text-3xl hover:text-gray-900"
                    onClick={toggleEdit}
                  >
                    &times;
                  </button>
                </div>
                <div className="p-4">
                  <form>
                    <div className="mb-4">
                      <label
                        htmlFor="subCategory"
                        className="block text-sm text-gray-700"
                      >
                        Edit the previous text Description
                      </label>
                      <textarea
                        name="textArea"
                        value={descriptionTable.description}
                        onChange={(e) => setDescriptionTable((prevState) => ({
                          ...prevState,
                          description: e.target.value
                        }))}
                        placeholder="Enter text here"
                        className="h-[150px] mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm resize-none"
                      />
                    </div>
                  </form>
                </div>
                <div className="flex justify-end items-center p-4 border-t">
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-400 duration-200 px-3 py-2 mr-3 text-white rounded"
                    onClick={toggleEdit}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="bg-blue-500 hover:bg-blue-400 duration-200 px-3 py-2 text-white rounded"
                    onClick={editDescription}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          )}


          <section className="px-3 md:px-4 bg-white mt-10 py-4 rounded-lg shadow-[0px_0px_15px_0px_rgba(0,0,0,0.1)]">
            <div className="flex items-center gap-2">
              <div className="flex text-sm items-center gap-1 font-light">
                <p className="text-neutral-700">Filter by Category:</p>
                <select
                  className="focus:outline-none focus:border-blue-300 border rounded px-1 py-1"
                  onChange={handleCategoryChange}
                  value={selectedCategory}
                >
                  {headerMenus.map((menu) => (
                    <option key={menu.id} value={menu.id}>
                      {menu.menu}
                    </option>
                  ))}
                </select>
                <select
                  className="focus:outline-none focus:border-blue-300 border rounded px-1 py-1"
                  onChange={handleSubMenuListChange}
                  value={subMenuList}
                >
                  {categoryList &&
                    categoryList.map((menu) => (
                      <option key={menu.id} value={menu.id}>
                        {menu.category}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="overflow-x-auto">
              <table
                className="min-w-full bg-white mt-8"
                style={{ borderCollapse: "separate", borderSpacing: "2px" }}
              >
                <thead>
                  <tr>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">
                      DESCRIPTION
                    </th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">
                      ACTION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {descriptionTable && (<tr key={descriptionTable.id}>
                    <td className="px-5 py-4 border border-[#48ABF7]">
                      {descriptionTable.description}
                    </td>
                    <td className="px-4 py-2 border border-[#48ABF7]">
                      <button className="flex items-center" onClick={() => toggleEdit(descriptionTable.id)}>
                        <HiMiniPencilSquare className="text-[#48ABF7]" /> Edit
                      </button>
                    </td>
                  </tr>)}
                </tbody>
              </table>
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

export default HeaderDescription;
