import React, { useEffect, useState } from 'react'
import SideBar from './SideBar'
import Header from './Header'
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { TiPlus } from "react-icons/ti";
import { HiMiniPencilSquare } from "react-icons/hi2";
import { adminDataDetails } from '../../serverApi/server';

const AdminData = () => {
  const [adminDataArray, setAdminDataArray] = useState([]);

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const getAdminData = await adminDataDetails('admin');
        setAdminDataArray(getAdminData.data.user_details_serializer);
      } catch (error) {
        console.error(error);
      }
    }

    fetchAdminData();
  }, [])

  return (
    <div className='flex'>
      <SideBar />

      <div className='w-full'>
        <Header />
        <section className="px-5 pt-5 bg-[#F5F7FD] h-screen overflow-y-auto">
          <div className="text-blue-950 flex items-center gap-6">
            <h1 className="pr-6 text-2xl font-medium border-r">
              Admin Users
            </h1>
            <div className="flex text-sm items-center gap-3">
              <button>
                <SlHome />
              </button>
              <SlArrowRight />
              <button>
                Users
              </button>
              <SlArrowRight />
              <button>
                Admin Data
              </button>
            </div>
          </div>

          <section className='bg-white mt-10 py-4 rounded-lg shadow-[0px_0px_15px_0px_rgba(0,0,0,0.1)]'>
            <div className='w-full flex items-center justify-end border-b px-3 pb-3'>
              <button className='flex items-center gap-[2px] bg-blue-500 px-4 py-[7px] rounded-full text-white'>
                <TiPlus /> 
                <h4>Add Users</h4>
              </button>
            </div>
            <div className='px-5 md:px-7 pt-4 flex items-center justify-between gap-2'>
              <div className='flex items-center gap-1 font-light'>
                <p className='text-neutral-700'>show</p>
                <select name="entries" className='border text-sm rounded-md focus:outline-none'>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <p className='text-neutral-700'>entries</p>
              </div>
              <form className='flex items-center gap-1 font-light'>
                <label htmlFor="entries-search" className='text-neutral-700'>Search:</label>
                <input type="text" className='border-2 w-auto sm:w-40 text-sm px-2 py-[2px] rounded focus:outline-none'/>
              </form>
            </div>

            <div className="overflow-x-auto sm:px-5 md:px-7">
              <table className="min-w-full bg-white mt-4" style={{ borderCollapse: 'separate', borderSpacing: '2px' }}>
                <thead>
                  <tr>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">FIRST NAME</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">LAST NAME</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">ROLE</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">EMAIL</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">PHONE NUMBER</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">FULL <br/>ADDRESS</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-sm font-medium tracking-wider bg-blue-400">ACTION</th>
                  </tr>
                </thead>
                <tbody className='text-neutral-800 font-light'>
                  { adminDataArray  && adminDataArray.map((admin) => (
                    <tr>
                      <td className="px-4 py-6 font-normal border border-[#48ABF7]">{admin.first_name}</td>
                      <td className="px-4 py-2 border border-[#48ABF7]">{admin.last_name}</td>
                      <td className="px-4 py-2 border border-[#48ABF7]">{admin.role}</td>
                      <td className="px-4 py-2 border border-[#48ABF7]">{admin.email}</td>
                      <td className="px-4 py-2 border border-[#48ABF7]">{admin.phone_number}</td>
                      <td className="px-4 py-2 border border-[#48ABF7]">{admin.building_number}, {admin.street}, {admin.city}, {admin.state}, {admin.zip_code}</td>
                      <td className="px-4 py-2 border border-[#48ABF7]">
                        <div className='text-xl flex items-center justify-around'>
                          <button>
                            <HiMiniPencilSquare className='text-blue-600'/>
                          </button>
                          <button>
                            <TiPlus className='rotate-45 text-red-500'/>
                          </button>
                        </div>
                      </td>
                    </tr>
                  )) }
                </tbody>
              </table>
            </div>

            <div className='px-5 md:px-7 pt-6 pb-3 text-neutral-700 text-sm font-light flex items-center justify-between gap-2'>
              <p>Showing 1 to 10 of 10 entries</p>
              <div className='flex items-center gap-2'>
                <button className='px-3 py-1 rounded-full bg-neutral-200'>Previous</button>
                <p className='px-3 py-1 rounded-full bg-blue-500 text-white font-normal'>1</p>
                <button className='px-3 py-1 rounded-full bg-neutral-200'>Next</button>
              </div>
            </div>

          </section>

        </section>
      </div>
    </div>
  )
}

export default AdminData