import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import MobileMenu from './MobileMenu';
import { getHeadersAndCategory } from '../../../serverApi/server';
import Skeleton from '../../../pages/skeleton/Skeleton';

const NewMenu = () => {
  const navigate = useNavigate();
  const [horizLine, setHorizLine] = useState(null);

  const subMenuAnimate =  {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.3,
      },
      display: 'block',
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      transition: {
        duration: 0.3,
      },
      display: 'none',
    }
  }

  const [headers, setHeaders] = useState([]);

  const fetchHeader = async () => {
    try {
      const getHeaderCategory = await getHeadersAndCategory();
      if (getHeaderCategory.client_status_code === 200) {
      setHeaders(getHeaderCategory.all_header_menus_details);
      setLoadingTime(false);
      }
    } catch (error) {
      console.error("Error Fetching Header:", error);
    }
  };

  useEffect(() => {
    fetchHeader();
  }, [])

  const [hoverID, setHoverID] = useState(null);

  const toggleHoverMenu = (id) => {
    setHoverID(id);
  }

  const dropdownRef = useRef(null);
  const [clickedID, setClickedID] = useState(null);

  const handleToggle = (id) => {
    const newID = clickedID === id ? null : id;
    setClickedID(newID);
    toggleHoverMenu(newID);
  };

  const [loadingTime, setLoadingTime] = useState(true);

  return (
    <div className='w-full px-5 sm:px-[3vw] border-b-[1px]'>
      <nav className='hidden custom-991:flex justify-between'>
        <ul className='flex justify-center w-full'>
          {loadingTime ? (
            <div className='flex gap-8 h-14 items-center'>
              <Skeleton width='60px' height='22px' marginBottom='0px'/>
              <Skeleton width='100px' height='22px' marginBottom='0px'/>
              <Skeleton width='60px' height='22px' marginBottom='0px'/>
              <Skeleton width='100px' height='22px' marginBottom='0px'/>
              <Skeleton width='60px' height='22px' marginBottom='0px'/>
              <Skeleton width='100px' height='22px' marginBottom='0px'/>
              <Skeleton width='60px' height='22px' marginBottom='0px'/>
              <Skeleton width='100px' height='22px' marginBottom='0px'/>
              <Skeleton width='60px' height='22px' marginBottom='0px'/>
            </div>) 
          : 
            <>
              <li onClick={() => setHorizLine('home')} className='cursor-pointer'>
                <div onClick={() => navigate('/')} className='SelectedOne group h-full flex items-center px-2 lg:px-3 duration-200 py-2 lg:py-4 hover:bg-orange-200'>
                  <div className='flex flex-col justify-center items-center text-center gap-1'>
                    Home
                    <hr className={`${horizLine === 'home' ? 'opacity-100' : 'opacity-0'} w-2/4 activeLine border-none h-[1px] bg-gray-600`}/>
                  </div>
                </div>
              </li>
              {headers && headers.map((header) => (
              <motion.li
                onHoverStart={() => toggleHoverMenu(header.header_menu_id)}
                onHoverEnd={() => toggleHoverMenu(null)}
                onClick={() => handleToggle(header.header_menu_id)}
                ref={dropdownRef}
                key={header.header_menu_id}
                className='group/link'
              >
                <div  className='SelectedOne cursor-pointer group h-full flex items-center px-2 lg:px-3 duration-200 py-2 lg:py-4 hover:bg-orange-200'>
                  <div className='flex flex-col justify-center items-center text-center gap-1'>
                    {header.header_menu}
                    <hr className={`${horizLine === header.header_menu_id ? 'opacity-100' : 'opacity-0'} w-2/4 activeLine border-none h-[1px] bg-gray-600`}/>
                  </div>
                </div> 
                
                <motion.div 
                  className='sub-menu'
                  initial='exit'
                  animate={hoverID === header.header_menu_id ? 'enter' : 'exit'}
                  variants={subMenuAnimate}
                >
                  <div 
                    className={`grid gap-x-6 gap-y-2 ${header.header_menu === 'Celebrations' ? 'grid-cols-2' : 'grid-cols-3'}`}
                  >
                    {header.categories && header.categories.map((categ) => ( 
                      <div key={categ.id} className='flex flex-col justify-center cursor-pointer' onClick={() => setHorizLine(header.header_menu_id)}>
                        <div onClick={() => {
                          navigate(categ.additional_name);
                          toggleHoverMenu(null);
                        }} className='px-2 py-1 rounded hover:text-orange-700 hover:bg-neutral-100 duration-00'>
                          {categ.category}
                        </div>
                      </div>
                    ))}
                  </div>
                </motion.div>
              </motion.li>
              ))}
              <li onClick={() => setHorizLine('about')} className='cursor-pointer'>
                <div onClick={() => navigate('/about')} className='SelectedOne group h-full flex items-center px-2 lg:px-3 duration-200 lg:py-4 hover:bg-orange-200'>
                  <div className='flex flex-col justify-center items-center text-center gap-1'>
                    About Us
                    <hr className={`${horizLine === 'about' ? 'opacity-100' : 'opacity-0'} w-2/4 activeLine border-none h-[1px] bg-gray-600`}/>
                  </div>
                </div>
              </li>
              <li onClick={() => setHorizLine('contact')} className='cursor-pointer'>
                <div onClick={() => navigate('/contact')} className='SelectedOne group h-full flex items-center px-2 lg:px-3 duration-200 py-2 lg:py-4 hover:bg-orange-200'>
                  <div className='flex flex-col justify-center items-center text-center gap-1'>
                    Contact Us
                    {/* ${horizLine === key ? 'opacity-100' : 'opacity-0'} */}
                    <hr className={`${horizLine === 'contact' ? 'opacity-100' : 'opacity-0'} w-2/4 activeLine border-none h-[1px] bg-gray-600`}/>
                  </div>
                </div>
              </li> 
            </>
          }
        </ul>
      </nav>
      <div className='custom-991:hidden'> 
        <MobileMenu />
      </div>
    </div>
  )
}

export default NewMenu;