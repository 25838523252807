import React, { useEffect, useState } from 'react';
import _ from "lodash";
import { getLatestEditedPdf } from '../serverApi/server';

export default function PdfSidebar({ showPdfUrl }) {

    const [pdfList, setPdfList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPdfs = async () => {
            try {
                const response = await getLatestEditedPdf();
                const data = response.edited_pdfs || [];
                if (data?.length) {
                    setPdfList(data);
                }
            } catch (error) {
                setError('Failed to load PDFs. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchPdfs();
    }, []);

    const handlePdfClick = (item) => {
        localStorage.setItem('editId', item.edit_id);
        showPdfUrl({
            id: item.pdf_id,
            title_name: item.file_name,
            pdfType: item.pdf_type,
            pages: decodeURIComponent(item.embedded_pages),
            edit_id: item.edit_id
        });
    };


    return (
        <React.Fragment>
            {loading ? (
                <div className="loader">Loading PDFs...</div>
            ) : error ? (
                <div className="error">{error}</div>
            ) : pdfList.length > 0 ? (
                <div className="p-1 bg-blue-50 text-gray-700 grid grid-cols-2 gap-1">
                    {pdfList.map((item, i) => {
                        return (
                            <div className="bg-white p-2 shadow rounded cursor-pointer" key={i}>
                                <img
                                    className="w-32 h-32 object-contain rounded"
                                    src={item.imageUrl}
                                    onClick={() => handlePdfClick(item)}
                                    alt={item.file_name}
                                    loading='lazy'
                                />
                            </div>
                        );
                    })}
                </div>
            ) : (
                <p className="no-pdfs-message text-center">No PDFs found.</p>
            )}
        </React.Fragment>
    );
}
