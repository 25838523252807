import React, { useEffect, useState } from 'react'
import { IoIosArrowDown, IoIosMenu } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../assets/Stalbanslogo.png'
import { motion } from 'framer-motion';
import { getHeadersAndCategory } from '../../../serverApi/server';
import Skeleton from '../../../pages/skeleton/Skeleton';

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [clicked, setClicked] = useState(null);
  const [selectedCateg, setSelectedCatg] = useState(null);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  }
  const toggleStatic = () => {
    setIsOpen(!isOpen);
    setClicked(null);
  }
  const navigate = useNavigate();

  const subMenuDrawer = {
    enter: {
      height: 'auto',
      overflow: 'hidden',
    },
    exit: {
      height: 0,
      overflow: 'hidden',
    },
  }

  const [headers, setHeaders] = useState([]);

  const fetchHeader = async () => {
    try {
      const getHeaderCategory = await getHeadersAndCategory();
      setHeaders(getHeaderCategory.all_header_menus_details);
    } catch (error) {
      console.error("Error Fetching Header:", error);
    }
  };

  useEffect(() => {
    fetchHeader();
  }, [])

  const [loadingTime, setLoadingTime] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingTime(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='flex justify-between py-4'>
      <div className='flex items-center'>
        {loadingTime ? (
          <Skeleton width='60px' height='35px'/>
        ) : (
        <div onClick={() => navigate('/')} className='custom-991:hidden'>
          <img
            src={logo}
            alt="brand-logo"
            className="w-16 md:w-20 cursor-pointer"
          />
        </div>
        )}
      </div>
      <button onClick={toggleDrawer} className={`text-3xl z-[100] ${isOpen ? 'fixed top-7 right-6' : 'relative'}`}>
        {loadingTime ? (
          <Skeleton width='40px' height='28px'/>
        ) : 
          isOpen ? <IoClose /> : <IoIosMenu />
        }
        
      </button>

      <motion.div 
      initial={{ x : '-100%' }}
      animate={{ x : isOpen ? '-0%' : '-100%' }}
      className='fixed left-0 right-0 top-0 overflow-y-auto h-full bg-white backdrop-blur text-[#18181A] pt-12 px-6 pb-6  z-50'>
        <ul>
          <li className='w-11/12' >
            <div 
              onClick={() => {
                navigate('/')
                setSelectedCatg('home');
                toggleStatic();
              }}
              className={`${selectedCateg === 'home' ? 'bg-black/10' : 'bg-white'} flex duration-200 font-semibold items-center justify-between p-2 sm:p-4 hover:bg-black/5 rounded-md relative`}
            >
              Home
            </div>
          </li>
          { headers && headers.map(( header ) => {
              const isClicked = clicked === header.header_menu_id;

              return(
                <li key={header.header_menu_id} className='w-11/12'>
                  <div 
                    className='flex font-semibold items-center justify-between p-2 sm:p-4 hover:bg-black/5 rounded-md relative'
                    onClick={() => {
                      setClicked(isClicked ? null : header.header_menu_id)
                    }}
                  >
                    {header.header_menu}
                      <IoIosArrowDown
                        className={` ml-auto ${isClicked && 'rotate-180'} duration-200 `}
                      />
                  </div>
                  {
                    <motion.ul 
                      initial='exit'
                      animate={isClicked ? 'enter' : 'exit'}
                      variants={subMenuDrawer}
                      className='ml-5'>
                      { header.categories && header.categories.map(( categ ) => (
                          <li
                            key={categ.id}
                            onClick={() => {
                              setSelectedCatg(categ.additional_name);
                              toggleDrawer();
                            }}
                          >
                            <div onClick={() => navigate(categ.additional_name)} className={`${selectedCateg === categ.additional_name ? 'bg-black/10' : 'bg-white'} p-2 flex items-center duration-200 hover:bg-black/5`}>{categ.category}</div>
                          </li>
                        ))
                      }
                    </motion.ul>
                  }
                </li>
              )
            })}
          <li className='w-11/12'>
            <div 
              onClick={() => {
                navigate('about');
                setSelectedCatg('about');
                toggleStatic();
              }}
              className={`${selectedCateg === 'about' ? 'bg-black/10' : 'bg-white'} flex duration-200 font-semibold items-center justify-between p-2 sm:p-4 hover:bg-black/5 rounded-md relative`}
            >
              About Us
            </div>
          </li>
          <li className='w-11/12'>
            <div 
              onClick={() => {
                navigate('contact');
                setSelectedCatg('contact');
                toggleStatic();
              }}
              className={`${selectedCateg === 'contact' ? 'bg-black/10' : 'bg-white'} flex duration-200 font-semibold items-center justify-between p-2 sm:p-4 hover:bg-black/5 rounded-md relative`}
            >
              Contact Us
            </div>
          </li>
        </ul>
        <div className='flex p-2 sm:p-4'>
            <div onClick={() => {navigate('faq'); toggleStatic();}} className='font-light text-gray-800'>FAQs</div>
            <span className='font-light px-2'>|</span>
            <div onClick={() => {navigate('help'); toggleStatic();}} className='font-light text-gray-800'>Help</div>
            <span className='font-light px-2'>|</span>
            <div onClick={() => {navigate('faq'); toggleStatic();}} className='font-light text-gray-800'>Support</div>
        </div>
      </motion.div>
    </div>
  )
}

export default MobileMenu